import { SxProps } from "@mui/system";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import Box from "@mui/material/Box";
import { AppTooltip } from "../communication/AppTooltip";

export interface AppButtonProps {
  variant: "contained" | "outlined" | "feedback" | "icon" | "link" | "circle";
  color?:
    | "primary"
    | "secondary"
    | "red"
    | "grey-red"
    | "grey-green"
    | "transparent";
  size?: "small" | "medium" | "large";
  label?: string | JSX.Element;
  disabled?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  icon?: JSX.Element;
  tooltip?: JSX.Element | string | number;
  sx?: SxProps;
  onClick?(): void;
}

interface CustomButtonProps extends ButtonProps {
  customvariant:
    | "contained"
    | "outlined"
    | "feedback"
    | "icon"
    | "link"
    | "circle";
  customcolor:
    | "primary"
    | "secondary"
    | "red"
    | "grey-red"
    | "grey-green"
    | "transparent";
}

const CustomButton = styled(Button)<CustomButtonProps>(
  ({ customvariant, customcolor }) => {
    let bgColor: string = "transparent";
    let bgColorHover: string = "#E4E4E9";
    let linkColor: string = "#232326";
    let linkColorHover: string = "inherit";
    switch (customcolor) {
      case "primary":
        bgColor = "#00E0B5";
        linkColor = "#00A887";
        linkColorHover = "#02786E";
        break;
      case "secondary":
        bgColor = "#232326";
        break;
      case "red":
        bgColor = "#D82216";
        bgColorHover = "#76220b";
        linkColor = "#D82216";
        linkColorHover = "#A1150C";
        break;
      case "grey-red":
        bgColor = "#232326";
        linkColorHover = "#B10F0F";
        break;

      case "grey-green":
        bgColor = "#232326";
        linkColorHover = "#02786E";
        break;
    }

    return {
      fontFamily: "BCGHendersonSans",
      fontSize: "1.4rem",
      fontWeight: 400,
      textTransform: "none",
      color: customcolor === "red" ? "#fff" : "#232326",
      lineHeight: "1.6rem",
      minWidth: 0,
      width: "fit-content",
      padding: "0",
      textDecoration: "none",
      letterSpacing: "inherit",
      textAlign: "left",
      "&.MuiButton-contained": {
        borderRadius: customvariant === "circle" ? "50%" : 4,
        backgroundColor: bgColor,
        "&:hover": {
          backgroundColor: bgColorHover,
        },
        "&:active": {
          backgroundColor: "#E4E4E9",
        },
        "&.Mui-disabled": {
          opacity: 1,
          color: "#A5A5A9",
          backgroundColor: "#E4E4E9",
        },
      },
      "&.MuiButton-containedPrimary": {
        backgroundColor: bgColor,
        "&:hover": {
          backgroundColor: "#00A887",
        },
        "&:active": {
          backgroundColor: "#02786E",
        },
        whiteSpace: "nowrap",
      },
      "&.MuiButton-containedSecondary": {
        backgroundColor: bgColor,
        color: "#fff",
        "&:hover": {
          backgroundColor: customvariant === "icon" ? "#67676B" : "#000000",
        },
        "&:active": {
          backgroundColor: customvariant === "icon" ? "#232326" : "#000000",
        },
        whiteSpace: "nowrap",
      },
      "&.MuiButton-outlined": {
        color: "#232326",
        padding: "13px 24px",
        borderRadius: 4,
        border: "1px solid #232326",
        "&:hover": {
          color: "#fff",
          backgroundColor: "#232326",
        },
        "&:active": {
          color: "#fff",
          backgroundColor: "#232326",
        },
        "&.Mui-disabled": {
          borderColor: "#E4E4E9",
        },
      },
      "&.MuiButton-text": {
        color: linkColor,
        "&.MuiButton-sizeMedium": {
          fontSize: "1.2rem",
        },
        "&:hover": {
          color: linkColorHover,
          textDecoration: "none",
          background: "none",
        },
        "&.Mui-disabled": {
          color: "#9A9A9A",
        },
      },
      "& .MuiButton-startIcon": {
        marginLeft: 0,
        cursor: "pointer",
        ">*:nth-of-type(1)": {
          fontSize: "1.2rem",
        },
      },
      "&.MuiButton-sizeLarge .MuiButton-startIcon": {
        marginRight: 6,
      },
      "&.MuiButton-sizeMedium .MuiButton-startIcon": {
        marginRight: 6,
      },
      "&.MuiButton-sizeSmall .MuiButton-startIcon": {
        marginRight: 6,
        ">*:nth-of-type(1)": {
          fontSize: "1.2rem",
        },
        "& .MuiSvgIcon-root": {
          width: "1em",
          height: "1em",
        },
      },
      "& .MuiButton-endIcon": {
        marginLeft: 0,
        cursor: "pointer",
        ">*:nth-of-type(1)": {
          fontSize: "0.9rem",
        },
      },
      "&.MuiButton-sizeLarge .MuiButton-endIcon": {
        marginLeft: 12,
      },
      "&.MuiButton-sizeMedium .MuiButton-endIcon": {
        marginLeft: 6,
      },
      "&.MuiButton-sizeSmall .MuiButton-endIcon": {
        marginLeft: 6,
        ">*:nth-of-type(1)": {
          fontSize: "1rem",
        },
      },
      "&.MuiButton-containedSizeLarge, &.MuiButton-outlinedSizeLarge": {
        fontSize: "1.4rem",
        padding: "13px 24px",
      },
      "&.MuiButton-containedSizeMedium, &.MuiButton-outlinedSizeMedium": {
        fontSize: "1.2rem",
        padding:
          customvariant === "icon"
            ? "8px 9px 8px 8px"
            : customvariant === "circle"
            ? "10px"
            : "10px 16px",
      },
      "&.MuiButton-containedSizeSmall, &.MuiButton-outlinedSizeSmall": {
        fontSize: "1.1rem",
        padding: customvariant === "icon" ? "9px 9px 8px 10px" : "6px 8px",
      },
    };
  }
);

export default function AppButton(props: AppButtonProps) {
  const {
    variant,
    color,
    size,
    label,
    disabled,
    startIcon,
    endIcon,
    icon,
    tooltip,
    sx,
    onClick,
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (onClick) onClick();
  };

  let baseVariant: "contained" | "outlined" | "text" | "icon" | "link" =
    "contained";
  switch (variant) {
    case "contained":
    case "feedback":
    case "icon":
    case "circle":
      baseVariant = "contained";
      break;
    case "outlined":
      baseVariant = "outlined";
      break;
    case "link":
      baseVariant = "text";
      break;
  }

  let baseColor: "primary" | "secondary" | "inherit" = "inherit";
  switch (color) {
    case "red":
    case "grey-red":
    case "grey-green":
    case "transparent":
      baseColor = "inherit";
      break;
    default:
      baseColor = color;
      break;
  }
  return (
    <Box>
      {tooltip && (
        <AppTooltip content={tooltip}>
          <CustomButton
            disableRipple
            disableElevation
            disabled={disabled}
            variant={baseVariant}
            size={size}
            startIcon={startIcon}
            endIcon={endIcon}
            customvariant={variant}
            customcolor={color}
            color={baseColor}
            sx={sx}
            onClick={handleClick}
          >
            {label || icon}
          </CustomButton>
        </AppTooltip>
      )}
      {!tooltip && (
        <CustomButton
          disableRipple
          disableElevation
          disabled={disabled}
          variant={baseVariant}
          size={size}
          startIcon={startIcon}
          endIcon={endIcon}
          customvariant={variant}
          customcolor={color}
          color={baseColor}
          sx={sx}
          onClick={handleClick}
        >
          {label || icon}
        </CustomButton>
      )}
    </Box>
  );
}
