import { useContext } from "react";
import { useApiChat } from "api/requests/chat";
import { ToasterContext, Toaster } from "contexts/ToasterContext";
import { Chat, SuggestedQuery } from "types/database";
import moment from "moment";

const formatMessages = (messages: any[]): Chat[] => {
  const formattedMessages: Chat[] = messages.map((message: any) => {
    return formatMessage(message);
  });
  return formattedMessages;
};

export const formatMessage = (message: any): Chat => {
  const formattedMessage: Chat = {
    ...message,
    createdAt: message.created_at ? moment(message.created_at) : null,
  };
  return formattedMessage;
};

const formatSuggestedQueries = (queries: any[]): SuggestedQuery[] => {
  const formattedQueries: SuggestedQuery[] = queries.map((query: any) => {
    return formatSuggestedQuery(query);
  });
  return formattedQueries;
};

export const formatSuggestedQuery = (query: any): SuggestedQuery => {
  const formattedQuery: SuggestedQuery = {
    ...query,
    createdAt: query.created_at ? moment(query.created_at) : null,
  };
  return formattedQuery;
};

export function useLoaderChat() {
  //Hooks
  const apiChat = useApiChat();
  const { showToaster } = useContext(ToasterContext);

  const abortMessages: number[] = [];

  const loadConversation = async (
    conversationId: string,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<Chat[]> => {
    return apiChat
      .getConversation(conversationId, orderBy, orderDir, limit, offset)
      .then((messages: any[]) => {
        return formatMessages(messages);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Conversation fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadSuggestedQueries = async (
    caseId: number,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<SuggestedQuery[]> => {
    return apiChat
      .getSuggestedQueries(caseId, orderBy, orderDir, limit, offset)
      .then((queries: any[]) => {
        return formatSuggestedQueries(queries);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Suggested Queries fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const addAbortMessage = (messageId: number) => {
    abortMessages.push(messageId);
  };

  const sendMessage = async (
    messageId: number,
    caseId: number,
    conversationId: string,
    text: string
  ): Promise<string> => {
    return apiChat
      .sendMessage(caseId, conversationId, text)
      .then((response: any) => {
        const messageIndex: number = abortMessages.indexOf(messageId);
        if (messageIndex > -1) {
          abortMessages.splice(messageIndex, 1);
          return null;
        }
        return response;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "Timeout: no response received from OpenAI",
        };
        showToaster(toaster);
        return null;
      });
  };

  const deleteConversation = async (
    conversationId: string
  ): Promise<boolean> => {
    return apiChat
      .deleteConversation(conversationId)
      .then((result: boolean) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Conversation has been successfully deleted ",
        };
        showToaster(toaster);
        return result;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Conversation deletion",
        };
        showToaster(toaster);
        return null;
      });
  };

  return {
    loadConversation: loadConversation,
    loadSuggestedQueries: loadSuggestedQueries,
    sendMessage: sendMessage,
    addAbortMessage: addAbortMessage,
    deleteConversation: deleteConversation,
  };
}
