import { useContext } from "react";
import { useApiChunks } from "api/requests/chunks";
import { ToasterContext, Toaster } from "contexts/ToasterContext";
import { Chunk } from "types/database";
import { AppApiError } from "api/utils/types";

const formatChunks = (chunks: any[]): Chunk[] => {
  const formattedChunks: Chunk[] = chunks.map((chunk: any) => {
    return formatChunk(chunk);
  });
  return formattedChunks;
};

const formatChunk = (chunk: any): Chunk => {
  const interviewId: any = chunk.interview_id;
  delete chunk.interview_id;
  const formattedChunk: Chunk = {
    ...chunk,
    interviewId: interviewId,
  };
  return formattedChunk;
};

export function useLoaderChunks() {
  //Hooks
  const apiChunks = useApiChunks();
  const { showToaster } = useContext(ToasterContext);

  const loadChunks = async (
    themeId: number,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<Chunk[] | AppApiError> => {
    return apiChunks
      .getChunks(themeId, orderBy, orderDir, limit, offset)
      .then((chunks: any[]) => {
        return formatChunks(chunks);
      })
      .catch((error: Error) => {
        let message: string = "An error occured during Chunks fetching";
        if (error.message?.indexOf("404") >= 0) {
          message =
            "Theme being regenerated due to processing of new interview. Please try again after interview has finished loading.";
        }
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: message,
        };
        showToaster(toaster);
        if (error.message?.indexOf("404") >= 0) {
          return AppApiError.ThemeNotFound;
        } else {
          return null;
        }
      });
  };

  const loadChunksCount = async (themeId: number): Promise<number> => {
    return apiChunks
      .getChunksCount(themeId)
      .then((count: number) => {
        return count;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Chunks Count fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  return {
    loadChunks: loadChunks,
    loadChunksCount: loadChunksCount,
  };
}
