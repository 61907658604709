import { useContext, useEffect, useState, useCallback, useRef } from "react";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { SynthesisContext } from "contexts/SynthesisContext";
import Box from "@mui/material/Box";
import AppButton from "components/materials/actions/AppButton";
import CrossIcon from "components/icons/CrossIcon";
import Summary from "./components/Summary";
import Metadata from "./components/Metadata";
import { Stack } from "@mui/material";
import { Interview, TaskStatus, Task } from "types/database";
import { RawCorpus } from "types/serializers";
import { useLoaderInterviews } from "api/loaders/interviews";
import { InterviewStatus } from "types/internal";
import { subscribe, unsubscribe, AppEventName } from "contexts/appEvents";
import { formatInterview, formatTask } from "api/loaders/interviews";

export default function InformationPanel() {
  //Hooks
  const {
    isInterviewInformationVisible,
    hideInterviewInformation,
    interviewInformation,
    updateInterviewInformation,
    highlightedCitation,
  } = useContext(SynthesisContext);
  const { loadRawCorpus } = useLoaderInterviews();
  //States
  const [rawCorpus, setRawCorpus] = useState<RawCorpus>(null);
  const [interviewId, setInterviewId] = useState<number>(null);
  const dataFetchedRef = useRef(false);

  //Call RawCorpus Request
  const callRawCorpus = useCallback(
    async (interviewId: number) => {
      const result: RawCorpus = await loadRawCorpus(interviewId);
      setRawCorpus(result);
    },
    [loadRawCorpus]
  );

  if (interviewInformation && interviewId !== interviewInformation.id) {
    setInterviewId(interviewInformation.id);
    dataFetchedRef.current = false;
  }

  //Fetch RawCorpus on Page Load
  useEffect(() => {
    if (dataFetchedRef.current || !interviewInformation) return;
    dataFetchedRef.current = true;
    callRawCorpus(interviewInformation.id);
  }, [callRawCorpus, interviewInformation]);

  //Listen for App Events
  useEffect(() => {
    const handleMetadataInterview = (event: any) => {
      console.log(
        "ON_INTERVIEW_METADATA - InformationPanel - ",
        event.detail.interview
      );
      if (
        event.detail.interview &&
        event.detail.interview.id === interviewInformation?.id
      ) {
        updateInterviewInformation(formatInterview(event.detail.interview));
      }
    };

    const handleTaskChanged = (event: any) => {
      console.log(
        "ON_TASK_CHANGED - InformationPanel - ",
        event.detail.task,
        interviewInformation
      );
      let task: Task = null;
      if (event.detail.task) {
        task = formatTask(event.detail.task);
      }
      if (
        task &&
        interviewInformation &&
        interviewInformation.loadTask?.id === task.id
      ) {
        if (task.status === TaskStatus.SUCCEEDED) {
          updateInterviewInformation({
            ...interviewInformation,
            loadTask: task,
          });
        }
      }
    };

    subscribe(AppEventName.ON_INTERVIEW_METADATA, handleMetadataInterview);
    subscribe(AppEventName.ON_TASK_CHANGED, handleTaskChanged);

    return () => {
      unsubscribe(AppEventName.ON_INTERVIEW_METADATA, handleMetadataInterview);
      unsubscribe(AppEventName.ON_TASK_CHANGED, handleTaskChanged);
    };
  }, [interviewInformation, updateInterviewInformation]);

  const getStatus = (itw: Interview): InterviewStatus => {
    if (!itw) {
      return InterviewStatus.Undefined;
    } else if (!itw.title) {
      return InterviewStatus.Uploading;
    } else if (itw.loadTask?.status === TaskStatus.SUCCEEDED) {
      return InterviewStatus.Writable;
    } else if (
      itw.loadTask?.status === TaskStatus.FAILED ||
      itw.loadTask?.status === TaskStatus.ABORT
    ) {
      return InterviewStatus.Failed;
    } else if (itw.loadTask?.status === TaskStatus.RUNNING) {
      return InterviewStatus.Readonly;
    }
    return InterviewStatus.Undefined;
  };

  return (
    <Modal
      open={isInterviewInformationVisible}
      onClose={hideInterviewInformation}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Fade
        in={isInterviewInformationVisible}
        timeout={{
          enter: 600,
          exit: 600,
        }}
      >
        <Box
          sx={{
            width: "75%",
            height: "100%",
            marginLeft: "25%",
          }}
        >
          <Slide
            direction="left"
            in={isInterviewInformationVisible}
            mountOnEnter
            unmountOnExit
            timeout={{
              enter: 600,
              exit: 600,
            }}
          >
            <Box
              sx={{
                background: "#fff",
                position: "relative",
                height: "100%",
              }}
            >
              <Box sx={{ position: "absolute", top: 16, left: 20 }}>
                <AppButton
                  variant="circle"
                  color="transparent"
                  size="medium"
                  icon={<CrossIcon />}
                  onClick={hideInterviewInformation}
                />
              </Box>
              <Stack
                direction="row"
                height="100%"
                justifyContent="space-between"
              >
                <Summary
                  interview={interviewInformation}
                  citation={highlightedCitation}
                  rawCorpus={rawCorpus}
                />
                <Metadata
                  isNew={false}
                  readonly={
                    getStatus(interviewInformation) === InterviewStatus.Readonly
                  }
                  interview={interviewInformation}
                  onChange={(interview: Interview) => {
                    updateInterviewInformation(interview);
                  }}
                  onCancel={hideInterviewInformation}
                />
              </Stack>
            </Box>
          </Slide>
        </Box>
      </Fade>
    </Modal>
  );
}
