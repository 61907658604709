import { createTheme } from "@mui/material/styles";
import { themeCommonSettings } from "./themeCommonSettings";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    h8: React.CSSProperties;
    h10: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    h8?: React.CSSProperties;
    h10?: React.CSSProperties;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    h8: true;
    h10: true;
  }
}

export const themeDark = createTheme(
  {
    palette: {
      mode: "dark",
    },
  },
  themeCommonSettings
);
