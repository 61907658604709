import { Box, Stack } from "@mui/material";

import AppButton from "components/materials/actions/AppButton";
import PlusIcon from "components/icons/PlusIcon";
import DoubleQuotesIcon from "components/icons/DoubleQuotesIcon";
import MenuIcon from "components/icons/MenuIcon";
import CrossIcon from "components/icons/CrossIcon";
import ArrowRightIcon from "components/icons/ArrowRightIcon";

export default function DsButtons() {
  return (
    <>
      <Box
        sx={{
          fontFamily: "BCGHendersonSans",
          fontSize: "1.4rem",
          fontWeight: 400,
        }}
      >
        DESIGN SYSTEM - Button
      </Box>
      <Stack spacing={20}>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="contained"
            color="primary"
            size="large"
            label="Button"
          />
          <AppButton
            variant="contained"
            color="primary"
            size="large"
            label="Button"
            disabled
          />
          <AppButton
            variant="contained"
            color="primary"
            size="medium"
            label="Button"
          />
          <AppButton
            variant="contained"
            color="primary"
            size="medium"
            label="Button"
            disabled
          />
          <AppButton
            variant="contained"
            color="primary"
            label="Button"
            size="small"
          />
          <AppButton
            variant="contained"
            color="primary"
            label="Button"
            size="small"
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="contained"
            color="secondary"
            size="large"
            label="Button"
          />
          <AppButton
            variant="contained"
            color="secondary"
            size="large"
            label="Button"
            disabled
          />
          <AppButton
            variant="contained"
            color="secondary"
            size="medium"
            label="Button"
          />
          <AppButton
            variant="contained"
            color="secondary"
            size="medium"
            label="Button"
            disabled
          />
          <AppButton
            variant="contained"
            color="secondary"
            size="small"
            label="Button"
          />
          <AppButton
            variant="contained"
            color="secondary"
            size="small"
            label="Button"
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="contained"
            color="red"
            size="large"
            label="Button Red"
          />
          <AppButton
            variant="contained"
            color="red"
            size="large"
            label="Button"
            disabled
          />
          <AppButton
            variant="contained"
            color="red"
            size="medium"
            label="Button"
          />
          <AppButton
            variant="contained"
            color="red"
            size="medium"
            label="Button"
            disabled
          />
          <AppButton
            variant="contained"
            color="red"
            size="small"
            label="Button"
          />
          <AppButton
            variant="contained"
            color="red"
            size="small"
            label="Button"
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="outlined"
            color="secondary"
            size="large"
            label="Button"
          />
          <AppButton
            variant="outlined"
            color="secondary"
            size="large"
            label="Button"
            disabled
          />
          <AppButton
            variant="outlined"
            color="secondary"
            size="medium"
            label="Button"
          />
          <AppButton
            variant="outlined"
            color="secondary"
            size="medium"
            label="Button"
            disabled
          />
          <AppButton
            variant="outlined"
            color="secondary"
            size="small"
            label="Button"
          />
          <AppButton
            variant="outlined"
            color="secondary"
            size="small"
            label="Button"
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="contained"
            size="large"
            startIcon={<PlusIcon />}
            label="Button"
          />
          <AppButton
            variant="contained"
            size="large"
            label="Button"
            startIcon={<PlusIcon />}
            disabled
          />
          <AppButton
            variant="contained"
            size="medium"
            label="Button"
            startIcon={<PlusIcon />}
          />
          <AppButton
            variant="contained"
            size="medium"
            label="Button"
            startIcon={<PlusIcon />}
            disabled
          />
          <AppButton
            variant="contained"
            size="small"
            label="Button"
            startIcon={<PlusIcon />}
          />
          <AppButton
            variant="contained"
            size="small"
            label="Button"
            startIcon={<PlusIcon />}
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<PlusIcon />}
            label="Button"
          />
          <AppButton
            variant="contained"
            color="secondary"
            size="large"
            label="Button"
            startIcon={<PlusIcon />}
            disabled
          />
          <AppButton
            variant="contained"
            color="secondary"
            size="medium"
            label="Button"
            startIcon={<PlusIcon />}
          />
          <AppButton
            variant="contained"
            color="secondary"
            size="medium"
            label="Button"
            startIcon={<PlusIcon />}
            disabled
          />
          <AppButton
            variant="contained"
            color="secondary"
            size="small"
            label="Button"
            startIcon={<PlusIcon />}
          />
          <AppButton
            variant="contained"
            color="secondary"
            size="small"
            label="Button"
            startIcon={<PlusIcon />}
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="outlined"
            color="secondary"
            size="large"
            startIcon={<PlusIcon />}
            label="Button"
          />
          <AppButton
            variant="outlined"
            color="secondary"
            size="large"
            label="Button"
            startIcon={<PlusIcon />}
            disabled
          />
          <AppButton
            variant="outlined"
            color="secondary"
            size="medium"
            label="Button"
            startIcon={<PlusIcon />}
          />
          <AppButton
            variant="outlined"
            color="secondary"
            size="medium"
            label="Button"
            startIcon={<PlusIcon />}
            disabled
          />
          <AppButton
            variant="outlined"
            color="secondary"
            size="small"
            label="Button"
            startIcon={<PlusIcon />}
          />
          <AppButton
            variant="outlined"
            color="secondary"
            size="small"
            label="Button"
            startIcon={<PlusIcon />}
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="icon"
            color="primary"
            icon={<DoubleQuotesIcon />}
          />
          <AppButton
            variant="icon"
            color="primary"
            icon={<DoubleQuotesIcon />}
            disabled
          />
          <AppButton variant="icon" color="primary" icon={<MenuIcon />} />
          <AppButton
            variant="icon"
            color="primary"
            icon={<MenuIcon />}
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="icon"
            color="secondary"
            icon={<DoubleQuotesIcon />}
          />
          <AppButton
            variant="icon"
            color="secondary"
            icon={<DoubleQuotesIcon />}
            disabled
          />
          <AppButton variant="icon" color="secondary" icon={<MenuIcon />} />
          <AppButton
            variant="icon"
            color="secondary"
            icon={<MenuIcon />}
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="circle"
            color="transparent"
            size="medium"
            icon={<CrossIcon />}
          />
          <AppButton
            variant="circle"
            color="transparent"
            size="medium"
            icon={<CrossIcon />}
            disabled
          />
          <AppButton
            variant="icon"
            color="transparent"
            size="small"
            icon={<ArrowRightIcon />}
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="link"
            color="grey-red"
            size="medium"
            label="Link"
          />
          <AppButton
            variant="link"
            color="grey-red"
            size="medium"
            label="Link"
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="link"
            color="grey-green"
            size="medium"
            label="Link"
          />
          <AppButton
            variant="link"
            color="grey-green"
            size="medium"
            label="Link"
            disabled
          />
          <AppButton
            variant="link"
            color="grey-green"
            size="large"
            label="Link"
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="link"
            color="primary"
            size="medium"
            label="Link"
          />
          <AppButton
            variant="link"
            color="primary"
            size="medium"
            label="Link"
            disabled
          />
        </Stack>
        <Stack direction="row" spacing={20}>
          <AppButton variant="link" color="primary" size="large" label="Link" />
          <AppButton
            variant="link"
            color="primary"
            size="large"
            label="Link"
            disabled
          />
        </Stack>
      </Stack>
    </>
  );
}
