import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import AppButton from "components/materials/actions/AppButton";
import ExcludeIcon from "components/icons/ExcludeIcon";
import ArrowRightIcon from "components/icons/ArrowRightIcon";
import TrashIcon from "components/icons/TrashIcon";
import { Interview, InterviewQuality } from "types/database";
import PlusIcon from "components/icons/PlusIcon";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import StarIcon from "components/icons/StarIcon";
import { TaskStatus } from "types/database";
import { InterviewStatus } from "types/internal";

export interface InterviewBoxProps {
  interview: Interview;
  deleted?: boolean;
  complete: boolean;
  onExclude?(): void;
  onInclude?(): void;
  onDelete?(): void;
  onView?(): void;
}

interface CustomTypoProps extends TypographyProps {
  excluded: number;
  hover: number;
}

const TitleTypography = styled(Typography)<CustomTypoProps>(
  ({ excluded, hover }) => ({
    fontWeight: 700,
    color: hover || !excluded ? "#232326" : "#A5A5A9",
    "&:hover": {
      color: "#232326",
    },
    transition: "color 0.3s",
  })
);

const InfoTypography = styled(Typography)<CustomTypoProps>(
  ({ excluded, hover }) => ({
    color: hover || !excluded ? "#67676B" : "#A5A5A9",
    transition: "color 0.3s",
  })
);

interface DeleteBoxProps extends BoxProps {
  hover: number;
}
const DeleteBox = styled(Box)<DeleteBoxProps>(({ hover }) => ({
  opacity: hover ? 1 : 0,
  transition: "opacity 0.2s",
}));

const ExcludedBox = styled(Box)<BoxProps>(() => ({
  color: "#fff",
  fontWeight: 700,
  fontSize: "0.8rem",
  textTransform: "uppercase",
  letterSpacing: "0.1em",
  background: "#A5A5A9",
  borderRadius: "4px",
  padding: "3px 4px 2px",
}));

export default function InterviewBox(props: InterviewBoxProps) {
  //States
  const [isHover, setIsHover] = useState<boolean>(false);
  //Props
  const {
    interview,
    deleted,
    complete,
    onExclude,
    onInclude,
    onDelete,
    onView,
  } = props;

  const getStatus = (): InterviewStatus => {
    if (!interview.summaryText) {
      return InterviewStatus.Uploading;
    } else if (interview.loadTask?.status === TaskStatus.SUCCEEDED) {
      return InterviewStatus.Writable;
    } else if (
      interview.loadTask?.status === TaskStatus.FAILED ||
      interview.loadTask?.status === TaskStatus.ABORT
    ) {
      return InterviewStatus.Failed;
    } else if (interview.loadTask?.status === TaskStatus.RUNNING) {
      return InterviewStatus.Readonly;
    }
    return InterviewStatus.Undefined;
  };

  const renderExcluded = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 7,
          opacity: interview.exclude ? 1 : 0,
          transition: "opacity 0.5s",
        }}
      >
        <ExcludedBox component="span">excluded</ExcludedBox>
      </Box>
    );
  };

  const renderMetadata = () => {
    return (
      <Box>
        <TitleTypography
          variant="body1"
          excluded={+interview.exclude}
          hover={+isHover}
        >
          {interview.title || "Untitled Interview"}
        </TitleTypography>
        {getStatus() !== InterviewStatus.Uploading && (
          <InfoTypography
            variant="body1"
            excluded={+interview.exclude}
            hover={+isHover}
          >
            <strong>Interviewed on: </strong>
            {interview.interviewDate &&
              interview.interviewDate.format("MMMM D, YYYY")}
          </InfoTypography>
        )}
        <InfoTypography
          variant="body1"
          excluded={+interview.exclude}
          hover={+isHover}
        >
          <strong>Uploaded: </strong>
          {interview.createdAt && interview.createdAt.format("MMMM D, YYYY")}
        </InfoTypography>
        {getStatus() !== InterviewStatus.Uploading && (
          <Stack direction="row" alignItems="center" spacing={12}>
            <InfoTypography
              variant="body1"
              excluded={+interview.exclude}
              hover={+isHover}
            >
              <strong>Source: </strong>
              {interview.source?.name || "Unknown"}
            </InfoTypography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              paddingBottom={2}
            >
              <StarIcon
                sx={{
                  opacity: interview.quality ? 1 : 0.4,
                }}
              />
              <StarIcon
                sx={{
                  opacity:
                    interview.quality &&
                    interview.quality !== InterviewQuality.Low
                      ? 1
                      : 0.4,
                }}
              />
              <StarIcon
                sx={{
                  opacity:
                    interview.quality === InterviewQuality.High ? 1 : 0.4,
                }}
              />
            </Stack>
          </Stack>
        )}
      </Box>
    );
  };

  const renderActions = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          opacity: isHover ? 1 : 0.7,
          transition: "opacity 0.3s",
        }}
      >
        <Stack direction="row" spacing={17}>
          {interview.exclude && (
            <AppButton
              variant="link"
              color="grey-green"
              size="medium"
              label="Include"
              startIcon={<PlusIcon />}
              onClick={onInclude}
              tooltip={
                <Typography variant="body1">
                  Including/excluding interviews modifies the corpus used for
                  querying. Please note that themes are not affected.
                </Typography>
              }
              disabled={getStatus() !== InterviewStatus.Writable}
            />
          )}
          {!interview.exclude && (
            <AppButton
              variant="link"
              color="grey-red"
              size="medium"
              label="Exclude"
              startIcon={<ExcludeIcon />}
              onClick={onExclude}
              tooltip={
                <Typography variant="body1">
                  Including/excluding interviews modifies the corpus used for
                  querying. Please note that takeaways are not affected.
                </Typography>
              }
              disabled={getStatus() !== InterviewStatus.Writable}
            />
          )}
          <DeleteBox hover={+isHover}>
            <AppButton
              variant="link"
              color="red"
              size="medium"
              label=""
              startIcon={<TrashIcon />}
              onClick={onDelete}
              disabled={getStatus() !== InterviewStatus.Writable}
            />
          </DeleteBox>
        </Stack>
        <AppButton
          variant="link"
          color="grey-green"
          size="medium"
          label={
            getStatus() !== InterviewStatus.Writable ? "View" : "View/Edit"
          }
          endIcon={<ArrowRightIcon />}
          onClick={onView}
        />
      </Stack>
    );
  };

  const renderFailed = (message: string) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          opacity: isHover ? 1 : 0.7,
          transition: "opacity 0.3s",
        }}
      >
        <Stack direction="row" spacing={17}>
          <Typography>
            <i>{message}</i>
          </Typography>
          <DeleteBox hover={+isHover}>
            <AppButton
              variant="link"
              color="red"
              size="medium"
              label=""
              startIcon={<TrashIcon />}
              onClick={onDelete}
            />
          </DeleteBox>
        </Stack>
      </Stack>
    );
  };

  return (
    <Collapse in={!deleted} timeout={500}>
      <Fade in={!deleted} timeout={500}>
        <Stack
          spacing={5}
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseOut={() => {
            setIsHover(false);
          }}
          sx={{
            padding: "5px 16px 12px",
            backgroundColor: isHover ? "#fff" : "transparent",
            transition: "background-color 0.2s",
            position: "relative",
          }}
        >
          {renderExcluded()}
          <Stack
            sx={{
              paddingTop: interview.exclude ? 16 : 0,
              transition: "padding-top 0.5s",
              position: "relative",
            }}
            spacing={3}
          >
            {!complete && (
              <Box
                sx={{
                  position: "absolute",
                  left: -11,
                  top: interview.exclude ? 25 : 9,
                  width: 6,
                  height: 6,
                  borderRadius: "50%",
                  backgroundColor: "#D82216",
                }}
              />
            )}
            {renderMetadata()}
            {getStatus() !== InterviewStatus.Uploading && renderActions()}
            {getStatus() === InterviewStatus.Failed &&
              renderFailed("Interview Upload Failed")}
          </Stack>
        </Stack>
      </Fade>
    </Collapse>
  );
}
