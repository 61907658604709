import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "translations/en.json";
import CssBaseline from "@mui/material/CssBaseline";
import AppGlobalStyles from "styles/AppGlobalStyles";
import { Routes as RoutesApp } from "utils/Routes";
import { AppContext, AppProvider } from "contexts/AppContext";
import { SynthesisProvider } from "contexts/SynthesisContext";
import { ToasterProvider } from "contexts/ToasterContext";
import { NotificationsProvider } from "contexts/NotificationsContext";
import { SortingFilteringProvider } from "contexts/SortingFilteringContext";
import { ConversationProvider } from "contexts/ConversationContext";
import { ThemeProvider } from "@mui/material/styles";
import { themeDark } from "styles/themeDark";
import { themeLight } from "styles/themeLight";
import "styles/app.scss";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
  },
  lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: ["en"],
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  return (
    <AppProvider>
      <AppContext.Consumer>
        {({ themeMode }) => (
          <ThemeProvider theme={themeMode === "light" ? themeLight : themeDark}>
            <CssBaseline />
            <AppGlobalStyles />
            <NotificationsProvider>
              <ToasterProvider>
                <SortingFilteringProvider>
                  <SynthesisProvider>
                    <ConversationProvider>
                      <RoutesApp />
                    </ConversationProvider>
                  </SynthesisProvider>
                </SortingFilteringProvider>
              </ToasterProvider>
            </NotificationsProvider>
          </ThemeProvider>
        )}
      </AppContext.Consumer>
    </AppProvider>
  );
}

export default App;
