import GlobalStyles from "@mui/material/GlobalStyles";

export default function AppGlobalStyles() {
  return (
    <GlobalStyles
      styles={{
        html: { fontSize: "62.56%", height: "100%", width: "100%" },
        body: {
          height: "100%",
          width: "100%",
          padding: 0,
          margin: 0,
          fontFamily: "BCGHendersonSans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "14px",
          letterSpacing: "0px",
          textAlign: "left",
          color: "#232326",
        },
      }}
    />
  );
}
