import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Stack, Box, BoxProps } from "@mui/material";
import InfoIcon from "components/icons/InfoIcon";
import AppButton from "../actions/AppButton";
import RefreshIcon from "components/icons/RefreshIcon";
import { AppTooltip } from "../communication/AppTooltip";
import { SxProps } from "@mui/system";
import { NumericFormat, NumericFormatProps } from "react-number-format";

export interface AppInputNumberProps {
  placeholder: string;
  maxLength: number;
  label?: string;
  tooltip?: JSX.Element | string | number;
  disabled?: boolean;
  fullWidth?: boolean;
  sx?: SxProps;
  value?: "" | number;
  resetEnabled?: boolean;
  resetValue?: "" | number;
  prefix?: string;
  suffix?: string;
  endIcon?: JSX.Element;
  error?: boolean;
  textError?: string;
  onChange(value: "" | number): void;
}

interface CustomNumericFormatProps extends NumericFormatProps {
  endicon?: JSX.Element;
  iserror?: number;
}
const StyledNumericFormat = styled(NumericFormat)<CustomNumericFormatProps>(
  ({ disabled, iserror }) => ({
    fontSize: "1.4rem",
    lineHeight: "1.5rem",
    border: iserror ? "1px solid #F14D19" : "1px solid #C8C8C8",
    padding: "11px 10px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    opacity: disabled ? 0.3 : 1,
  })
);

const StyledLabelBox = styled(Box)<BoxProps>(() => ({ lineHeight: "1.4rem" }));

const StyledBoxError = styled(Box)<BoxProps>(() => ({
  color: "#F14D19",
  fontSize: "1.1rem",
  lineHeight: "110%",
}));

export default function AppInputNumber(props: AppInputNumberProps) {
  const {
    placeholder,
    maxLength,
    label,
    tooltip,
    disabled,
    fullWidth,
    sx,
    value,
    resetEnabled,
    resetValue,
    prefix,
    suffix,
    error,
    textError,
    onChange,
  } = props;

  const [inputResetValue, setInputResetValue] = useState<"" | number>(
    undefined
  );

  let additionalStyles: any = { ...sx };
  let width: string = "24rem";
  if (additionalStyles.hasOwnProperty("width")) {
    width = additionalStyles["width"];
  }
  if (!fullWidth) {
    additionalStyles = { width: width, ...additionalStyles };
  } else {
    width = "100%";
  }

  const handleChange = (values: any, sourceInfo: any) => {
    onChange(values.floatValue);
  };

  const handleReset = () => {
    onChange(inputResetValue);
  };

  if (
    label &&
    resetEnabled &&
    (resetValue || value !== undefined) &&
    inputResetValue === undefined
  ) {
    setInputResetValue(resetValue || value);
  }

  return (
    <Stack
      direction="column"
      alignItems="left"
      spacing={10}
      style={{ width: width }}
    >
      {label && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={6}
        >
          <Stack direction="row" alignItems="center" spacing={6}>
            <StyledLabelBox>{label}</StyledLabelBox>
            {tooltip && (
              <AppTooltip content={tooltip}>
                <InfoIcon sx={{ color: "#1D4659" }} />
              </AppTooltip>
            )}
          </Stack>
          {resetEnabled && (
            <AppButton
              variant="icon"
              disabled={disabled}
              onClick={handleReset}
              icon={<RefreshIcon />}
            />
          )}
        </Stack>
      )}
      <Stack spacing={4}>
        <StyledNumericFormat
          value={value}
          allowLeadingZeros
          thousandSeparator=","
          prefix={prefix}
          suffix={suffix}
          onValueChange={handleChange}
          disabled={disabled}
          placeholder={placeholder}
          iserror={error ? 1 : 0}
          maxLength={maxLength}
        />
        {textError && <StyledBoxError>{textError}</StyledBoxError>}
      </Stack>
    </Stack>
  );
}
