import { Moment } from "moment";

export interface Case {
  id: number;
  name: string;
  createdAt: Moment;
}

export interface Source {
  id: number;
  name: string;
}

export interface Tag {
  id: number;
  name: string;
}

export enum InterviewQuality {
  Low = "LOW",
  Medium = "MEDIUM",
  High = "HIGH",
}

export interface FileType {
  name: string;
  type: string;
  extensions: string;
  isChoiceRequired: boolean;
  image: string;
}
export enum FileTypeEnum {
  VTT = "VTT",
  TEGUS = "TEGUS",
}
export const fileTypeDict: {
  [key in FileTypeEnum]: FileType;
} = {
  [FileTypeEnum.VTT]: {
    name: "Vtt",
    type: "text/vtt",
    extensions: ".vtt",
    isChoiceRequired: false,
    image: "file-html-icon.png",
  },
  [FileTypeEnum.TEGUS]: {
    name: "Tegus",
    type: "text/html",
    extensions: ".html,.htm",
    isChoiceRequired: true,
    image: "file-vtt-icon.png",
  },
};

export interface Interview {
  id: number;
  case: Case;
  createdAt: Moment;
  title: string;
  duration: number;
  interviewee: string;
  organization: string;
  source: Source;
  interviewDate: Moment;
  quality: InterviewQuality;
  summaryText: string;
  summaryBulleted: string[];
  exclude: boolean;
  sourceFile: string;
  sourceFileType: string;
  rawCorpus: string;
  loadTask: Task;
  tags: Tag[];
}

export interface Theme {
  id: number;
  caseId: number;
  name: string;
  weight: number;
  summaryText: string;
  summaryBulleted: string[];
  exclude: boolean;
  generated: boolean;
  interviews_count?: number;
}

export interface Chunk {
  id: number;
  index: number;
  text: string;
  interviewId: number;
}

export interface Quote {
  id: number;
  quotes: string[];
}

export interface SuggestedQuery {
  id: number;
  case: Case;
  query: string;
  order: number;
}
export enum TaskStatus {
  ABORT = "abort",
  FAILED = "failed",
  PENDING = "pending",
  RUNNING = "running",
  SUCCEEDED = "succeeded",
}

export interface Task {
  id: number;
  celeryId: string;
  createdAt: Moment;
  updatedAt: Moment;
  endedAt: Moment;
  status: string;
}

export interface Chat {
  id: number;
  createdAt: Moment;
  text: string;
  generated: boolean;
}

export interface SuggestedQuery {
  id: number;
  query: string;
  createdAt: Moment;
}
