export enum AppEventName {
  ON_INTERVIEW_UPLOADED = "ON_INTERVIEW_UPLOADED",
  ON_INTERVIEW_UPDATED = "ON_INTERVIEW_UPDATED",
  ON_INTERVIEW_METADATA = "ON_INTERVIEW_METADATA",
  ON_INTERVIEW_READY = "ON_INTERVIEW_READY",
  ON_INTERVIEW_DELETED = "ON_INTERVIEW_DELETED",
  ON_THEME_DELETED = "ON_THEME_DELETED",
  ON_THEMES_CHANGED = "ON_THEMES_CHANGED",
  ON_TASK_CHANGED = "ON_TASK_CHANGED",
  ON_TASK_FAILED = "ON_TASK_FAILED",
  ON_WEBSOCKET_DISCONNECT = "ON_WEBSOCKET_DISCONNECT",
}

function subscribe(eventName: AppEventName, listener: any) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName: AppEventName, listener: any) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName: AppEventName, data: any) {
  console.log("PUBLISH EVENT >> ", eventName, data);
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe };
