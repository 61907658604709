import { useState, useCallback, useEffect, useRef, useReducer } from "react";
import Box from "@mui/material/Box";
import Stack, { StackProps } from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Interview, InterviewQuality, Tag, Source } from "types/database";
import { InterviewPayload } from "types/payloads";
import AppTextareaAutosize from "components/materials/forms/AppTextareaAutosize";
import AppSelect, { SelectItem } from "components/materials/forms/AppSelect";
import AppButton from "components/materials/actions/AppButton";
import AppInputDatePicker from "components/materials/forms/AppInputDatePicker";
import StarIcon from "components/icons/StarIcon";
import { useLoaderSources } from "api/loaders/sources";
import { useLoaderTags } from "api/loaders/tags";
import { useLoaderInterviews } from "api/loaders/interviews";
import moment, { Moment } from "moment";
import Dialog from "@mui/material/Dialog";
import SourcesMgmt from "./SourcesMgmt";
import TagsMgmt from "./TagsMgmt";
import AppChip from "components/materials/content/AppChip";
import { Grid } from "@mui/material";

export interface MetadataProps {
  interview: Interview;
  isNew: boolean;
  readonly?: boolean;
  onCancel?(): void;
  onChange?(interview: Interview): void;
}

const SubtitleTypography = styled(Typography)<TypographyProps>(() => ({
  color: "#232326",
  fontSize: "1.1rem",
  fontWeight: 700,
  letterSpacing: 1.1,
  textTransform: "uppercase",
}));

interface QualityButtonStackProps extends StackProps {
  selected: number;
  disabled: number;
}
const QualityButtonStack = styled(Stack)<QualityButtonStackProps>(
  ({ selected, disabled }) => ({
    width: "fit-content",
    padding: "6px 8px 7px",
    borderRadius: "4px",
    cursor: disabled ? "default" : "pointer",
    background: selected ? "#232326" : "transparent",
    "& svg, & p": {
      color: selected ? "#fff" : "#00A887",
      opacity: disabled ? 0.5 : 1,
    },
    "&:hover": {
      background: disabled ? "inherit" : "#e1e1e6",
      "& svg, & p": {
        color: disabled ? "#00A887" : "#3b6660",
      },
    },
    transition: "background 0.3s",
  })
);

export default function Metadata(props: MetadataProps) {
  //Props
  const { interview, isNew, readonly, onCancel, onChange } = props;
  //Hooks
  const { loadSources } = useLoaderSources();
  const { loadTags } = useLoaderTags();
  const { updateInterview, updateTags } = useLoaderInterviews();

  //States
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [title, setTitle] = useState<string>("");
  const [interviewee, setInterviewee] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [interviewDate, setInterviewDate] = useState<Moment>(null);
  const [quality, setQuality] = useState<InterviewQuality>(null);

  const [sourceItems, setSourceItems] = useState<SelectItem[]>(null);
  const [sourceId, setSourceId] = useState<number>(null);
  const [tagItems, setTagItems] = useState<SelectItem[]>(null);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const dataFetchedRef = useRef(false);

  const [isSourcesMgmtOpen, setIsSourcesMgmtOpen] = useState<boolean>(false);
  const [isTagsMgmtOpen, setIsTagsMgmtOpen] = useState<boolean>(false);

  //Force Reducer
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  //Call Sources Request
  const callSources = useCallback(async () => {
    const results: Source[] = await loadSources();
    const items: SelectItem[] = results.map((source: Source) => {
      return { value: source.id.toString(), label: source.name };
    });
    setSourceItems(items);
  }, [loadSources]);

  //Call Tags Request
  const callTags = useCallback(async () => {
    const results: Tag[] = await loadTags();
    const interviewTagIds: number[] = interview.tags.map((tag: Tag) => {
      return tag.id;
    });
    const items: SelectItem[] = results.map((tag: Tag) => {
      return {
        value: tag.id.toString(),
        label: tag.name,
        disabled: interviewTagIds.indexOf(tag.id) > -1,
      };
    });
    setTagItems(items);
    setAllTags(results);
  }, [loadTags, interview?.tags]);

  //Call Update Request
  const callUpdate = async () => {
    setIsLoading(true);
    const payload: InterviewPayload = {
      title: title,
      interviewee: interviewee,
      organization: organization,
      source_id: sourceId,
      interview_date: interviewDate,
      quality: quality,
    };
    const updatedInterview: Interview = await updateInterview(
      interview.id,
      payload
    );
    const tagIds: number[] = selectedTags.map((tag: Tag) => {
      return tag.id;
    });
    await updateTags(interview.id, tagIds);
    setIsLoading(false);
    onChange && onChange(updatedInterview);
  };

  const handleAddTagId = (tagId: number) => {
    //update items in dropdownlist
    const tagItemIndex: number = tagItems.findIndex(
      (item: SelectItem) => tagId === parseInt(item.value.toString())
    );
    if (tagItemIndex > -1) {
      tagItems[tagItemIndex].disabled = true;
    }
    //add to chips list
    const tag: Tag = allTags.find((t: Tag) => t.id === tagId);
    if (tag) {
      selectedTags.push(tag);
    }
    forceUpdate();
  };

  const handleRemoveTag = (tag: Tag) => {
    //update items in dropdownlist
    const tagItemIndex: number = tagItems.findIndex(
      (item: SelectItem) => tag.id === parseInt(item.value.toString())
    );
    if (tagItemIndex > -1) {
      tagItems[tagItemIndex].disabled = false;
    }
    //remove from chips list
    const tagIndex: number = selectedTags.findIndex(
      (t: Tag) => t.id === tag.id
    );
    if (tagIndex > -1) {
      selectedTags.splice(tagIndex, 1);
    }
    forceUpdate();
  };

  //Fetch Interview Metadata
  const fetchMetadata = useCallback(async () => {
    setIsLoading(true);
    setTitle(interview.title);
    setInterviewee(interview.interviewee);
    setOrganization(interview.organization);
    setInterviewDate(interview.interviewDate);
    setSourceId(interview.source?.id);
    setQuality(interview.quality);
    setSelectedTags(interview.tags);
    setIsLoading(false);
  }, [
    setTitle,
    setInterviewee,
    setOrganization,
    setInterviewDate,
    setSourceId,
    setQuality,
    interview,
  ]);

  //Fetch Sources & Tags on Page Load
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    callSources();
    callTags();
    fetchMetadata();
  }, [callSources, callTags, fetchMetadata]);

  const renderQualityButton = (
    quality: InterviewQuality,
    selected: boolean,
    disabled: boolean
  ) => {
    let label: string = "";
    let numStars: number[] = [];
    switch (quality) {
      case InterviewQuality.Low:
        label = "Low";
        numStars = [1];
        break;
      case InterviewQuality.Medium:
        label = "Medium";
        numStars = [1, 2];
        break;
      case InterviewQuality.High:
        label = "High";
        numStars = [1, 2, 3];
        break;
    }
    return (
      <QualityButtonStack
        direction="row"
        spacing={2}
        selected={+selected}
        onClick={() => {
          if (!disabled) {
            setQuality(quality);
          }
        }}
        disabled={+disabled}
      >
        {numStars.map((num: number) => {
          return <StarIcon key={num} width="1.2rem" height="1.2rem" />;
        })}
        <Typography variant="body1" paddingLeft={4} paddingTop={2}>
          {label}
        </Typography>
      </QualityButtonStack>
    );
  };
  const renderQuality = () => {
    return (
      <Stack direction="column" alignItems="left" spacing={10}>
        <Box
          sx={{
            fontFamily: "BCGHendersonMod",
            fontSize: "1.4rem",
            fontWeight: 700,
            lineHeight: "1.4rem",
            color: "#232326",
          }}
        >
          Quality Assessment
        </Box>
        <Stack direction="row" justifyContent="space-between">
          {renderQualityButton(
            InterviewQuality.High,
            quality === InterviewQuality.High,
            isLoading || readonly
          )}
          {renderQualityButton(
            InterviewQuality.Medium,
            quality === InterviewQuality.Medium,
            isLoading || readonly
          )}
          {renderQualityButton(
            InterviewQuality.Low,
            quality === InterviewQuality.Low,
            isLoading || readonly
          )}
        </Stack>
      </Stack>
    );
  };

  const renderHeader = () => {
    return (
      <Box>
        <SubtitleTypography sx={{ marginBottom: 16 }}>
          Text Information
        </SubtitleTypography>
        {!readonly && (
          <Typography variant="body2" color="#67676B" marginBottom={16}>
            Results generated from automated process. Please accept or modify as
            needed.
          </Typography>
        )}
        {readonly && (
          <Typography variant="body2" color="#67676B" marginBottom={16}>
            Editing metadata disabled while interview is processing. Please try
            again after processing is finished.
          </Typography>
        )}
      </Box>
    );
  };

  const renderAccept = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={20}
        marginBottom={16}
      >
        <Typography variant="body1" color="#67676B">
          You can change this later
        </Typography>
        <AppButton
          variant="contained"
          color="primary"
          size="large"
          label="Accept"
          onClick={callUpdate}
          disabled={isLoading || readonly}
        />
      </Stack>
    );
  };

  const renderForm = () => {
    return (
      <Stack spacing={20}>
        <AppTextareaAutosize
          placeholder=""
          maxLength={100}
          label="Interview Header"
          fullWidth
          value={title || ""}
          onChange={(newValue: string) => {
            setTitle(newValue);
          }}
          disabled={isLoading || readonly}
        />
        <AppTextareaAutosize
          placeholder=""
          maxLength={100}
          label="Interviewee Job Title / Function"
          fullWidth
          value={interviewee || ""}
          onChange={(newValue: string) => {
            setInterviewee(newValue);
          }}
          disabled={isLoading || readonly}
        />
        <AppTextareaAutosize
          placeholder=""
          maxLength={100}
          label="Organization"
          fullWidth
          value={organization || ""}
          onChange={(newValue: string) => {
            setOrganization(newValue);
          }}
          disabled={isLoading || readonly}
        />
        <AppInputDatePicker
          id="input-date-picker"
          label="Interview Date"
          placeholder="YYYY-MM-DD"
          minDate={new Date("2022-01-01T00:00:00")}
          maxDate={new Date("2030-12-31T00:00:00")}
          value={interviewDate ? interviewDate.toDate() : null}
          onChange={(date: Date) => {
            setInterviewDate(moment(date));
          }}
          disabled={isLoading || readonly}
        />
        <AppSelect
          label="Source"
          fullWidth
          items={sourceItems}
          value={sourceId && sourceItems ? sourceId.toString() : ""}
          placeholder="Select the Source"
          onChange={(newValue: string | number) => {
            setSourceId(parseInt(newValue.toString()));
          }}
          disabled={!sourceItems || isLoading || readonly}
          additionalComponent={
            !readonly && (
              <AppButton
                variant="link"
                color="primary"
                size="medium"
                label="Manage sources"
                onClick={() => {
                  setIsSourcesMgmtOpen(true);
                }}
              />
            )
          }
        />
        <Stack spacing={5}>
          <AppSelect
            label="Tags"
            fullWidth
            items={tagItems}
            value={""}
            placeholder="Add Tags"
            onChange={(newValue: string | number) => {
              handleAddTagId(parseInt(newValue.toString()));
            }}
            disabled={!tagItems || isLoading || readonly}
            additionalComponent={
              !readonly && (
                <AppButton
                  variant="link"
                  color="primary"
                  size="medium"
                  label="Manage Tags"
                  onClick={() => {
                    setIsTagsMgmtOpen(true);
                  }}
                />
              )
            }
          />
          {selectedTags && selectedTags.length > 0 && (
            <Grid container rowSpacing={5} columnSpacing={5}>
              {selectedTags.map((tag: Tag, index: number) => {
                return (
                  <Grid item key={index}>
                    <AppChip
                      label={tag.name}
                      status="dark"
                      onDelete={() => {
                        handleRemoveTag(tag);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Stack>
        {renderQuality()}
      </Stack>
    );
  };

  const renderActions = () => {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={40}
      >
        <AppButton
          variant="link"
          color="grey-green"
          size="large"
          label="Cancel"
          onClick={() => {
            fetchMetadata();
            onCancel();
          }}
        />
        <AppButton
          variant="contained"
          size="large"
          color="primary"
          label="Save Changes"
          onClick={callUpdate}
          disabled={!tagItems || isLoading || readonly}
        />
      </Stack>
    );
  };

  const renderSourcesMgmt = () => {
    return (
      <Dialog
        open={isSourcesMgmtOpen}
        onClose={() => {
          setIsSourcesMgmtOpen(false);
        }}
        aria-labelledby="modal-sources-mgmt"
        aria-describedby="modal-sources-mgmt"
        closeAfterTransition
      >
        <SourcesMgmt
          onChange={() => {
            callSources();
          }}
          onClose={() => {
            setIsSourcesMgmtOpen(false);
          }}
        />
      </Dialog>
    );
  };

  const renderTagsMgmt = () => {
    return (
      <Dialog
        open={isTagsMgmtOpen}
        onClose={() => {
          setIsTagsMgmtOpen(false);
        }}
        aria-labelledby="modal-tags-mgmt"
        aria-describedby="modal-tags-mgmt"
        closeAfterTransition
      >
        <TagsMgmt
          onChange={() => {
            callTags();
          }}
          onClose={() => {
            setIsTagsMgmtOpen(false);
          }}
        />
      </Dialog>
    );
  };

  return (
    <>
      {renderSourcesMgmt()}
      {renderTagsMgmt()}
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
          minWidth: 340,
          maxWidth: 340,
          background: "rgba(73, 40, 255, 0.05)",
          borderTop: "5px solid #4928FF",
          padding: 24,
        }}
      >
        <Stack direction="column" justifyContent="space-between" height="100%">
          <Box>
            {renderHeader()}
            {isNew && !readonly && renderAccept()}
            {renderForm()}
          </Box>
          <Box>{!isNew && !readonly && renderActions()}</Box>
        </Stack>
      </Box>
    </>
  );
}
