import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import Error404 from "modules/fallbacks/Error404";
import ErrorApi from "modules/fallbacks/ErrorApi";
import AccessCheck from "modules/fallbacks/AccessCheck";
import Synthesis from "modules/Synthesis";
import DesignSystem from "designSystem/DesignSystem";
import NotifsTest from "designSystem/NotifsTest";

export function Routes() {
  return (
    <ReactRoutes>
      <Route path="/" element={<Navigate replace to="synthesis/1" />} />
      <Route
        path="/synthesis/"
        element={<Navigate replace to="/synthesis/1/" />}
      />
      <Route path="synthesis/:caseid/*" element={<Synthesis />} />
      <Route path="/404" element={<Error404 />} />
      <Route path="/*" element={<Error404 />} />
      <Route path="design-system/*" element={<DesignSystem />} />
      <Route path="notifs-test/*" element={<NotifsTest />} />
      <Route path="unauthorized" element={<ErrorApi />} />
      <Route path="check" element={<AccessCheck />} />
    </ReactRoutes>
  );
}
export default Routes;
