import { Moment } from "moment";
import { createContext, useState, FC, PropsWithChildren as Props } from "react";

//////////////////////////////
//CONTEXT FOR SORTING
//////////////////////////////

export type SortOption = ThemesSortOption | InterviewsSortOption;
export type FilterOption = InterviewsFilterOption;

export enum ThemesSortOption {
  Alphabetical = "Alphabetical",
  Frequency = "Frequency",
}

export enum InterviewsSortOption {
  InterviewDate = "Interview Date",
  UploadDate = "Upload Date",
  Complete = "Complete",
}

export enum InterviewsFilterOption {
  InterviewHeader = "Interview Header",
  InterviewBodyText = "Interview Body Text",
  Organization = "Organization",
  InterviewDate = "Interview Date",
  Source = "Source",
  Tag = "Tag",
}

export enum ModeFilterOption {
  Contains = "Contains",
  IsEmpty = "Is Empty",
}

export type ContextState = {
  themesSorting: ThemesSortOption;
  changeThemesSorting: (option: ThemesSortOption) => void;
  interviewsSorting: InterviewsSortOption;
  changeInterviewsSorting: (option: InterviewsSortOption) => void;
  interviewsFiltering: InterviewsFilterOption;
  changeInterviewsFiltering: (option: InterviewsFilterOption) => void;
  interviewsModeFilter: ModeFilterOption;
  changeInterviewsModeFilter: (option: ModeFilterOption) => void;
  interviewsFilteringValue: string;
  changeInterviewsFilteringValue: (value: string) => void;
  interviewsFilteringDate: Moment;
  changeInterviewsFilteringDate: (value: Moment) => void;
};

const defaultValues: ContextState = {
  themesSorting: ThemesSortOption.Frequency,
  changeThemesSorting: () => {},
  interviewsSorting: InterviewsSortOption.UploadDate,
  changeInterviewsSorting: () => {},
  interviewsFiltering: InterviewsFilterOption.InterviewHeader,
  changeInterviewsFiltering: () => {},
  interviewsModeFilter: ModeFilterOption.Contains,
  changeInterviewsModeFilter: () => {},
  interviewsFilteringValue: "",
  changeInterviewsFilteringValue: () => {},
  interviewsFilteringDate: null,
  changeInterviewsFilteringDate: () => {},
};

export const SortingFilteringContext =
  createContext<ContextState>(defaultValues);

export const SortingFilteringProvider: FC<Props> = ({ children }) => {
  //States
  const [themesSorting, setThemesSorting] = useState<ThemesSortOption>(
    defaultValues.themesSorting
  );
  const [interviewsSorting, setInterviewsSorting] =
    useState<InterviewsSortOption>(defaultValues.interviewsSorting);
  const [interviewsFiltering, setInterviewsFiltering] =
    useState<InterviewsFilterOption>(defaultValues.interviewsFiltering);
  const [interviewsModeFilter, setInterviewsModeFilter] =
    useState<ModeFilterOption>(defaultValues.interviewsModeFilter);
  const [interviewsFilteringValue, setInterviewsFilteringValue] =
    useState<string>(defaultValues.interviewsFilteringValue);
  const [interviewsFilteringDate, setInterviewsFilteringDate] =
    useState<Moment>(defaultValues.interviewsFilteringDate);

  //Update Functions
  const changeThemesSorting = (option: ThemesSortOption) => {
    setThemesSorting(option);
  };
  const changeInterviewsSorting = (option: InterviewsSortOption) => {
    setInterviewsSorting(option);
  };
  const changeInterviewsFiltering = (option: InterviewsFilterOption) => {
    setInterviewsFiltering(option);
  };
  const changeInterviewsModeFilter = (option: ModeFilterOption) => {
    setInterviewsModeFilter(option);
  };
  const changeInterviewsFilteringValue = (value: string) => {
    setInterviewsFilteringValue(value);
  };
  const changeInterviewsFilteringDate = (value: Moment) => {
    setInterviewsFilteringDate(value);
  };

  return (
    <SortingFilteringContext.Provider
      value={{
        themesSorting,
        changeThemesSorting,
        interviewsSorting,
        changeInterviewsSorting,
        interviewsFiltering,
        changeInterviewsFiltering,
        interviewsModeFilter,
        changeInterviewsModeFilter,
        interviewsFilteringValue,
        changeInterviewsFilteringValue,
        interviewsFilteringDate,
        changeInterviewsFilteringDate,
      }}
    >
      {children}
    </SortingFilteringContext.Provider>
  );
};
