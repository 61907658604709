import {
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { SynthesisContext } from "contexts/SynthesisContext";
import { Box, BoxProps, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import AppButton from "components/materials/actions/AppButton";
import CrossIcon from "components/icons/CrossIcon";
import PlusIcon from "components/icons/PlusIcon";
import InterviewBox from "modules/interviews/components/InterviewBox";
import { Interview } from "types/database";
import { useLoaderInterviews } from "api/loaders/interviews";
import { useParams } from "react-router";
import { subscribe, unsubscribe } from "contexts/appEvents";
import { AppEventName } from "contexts/appEvents";
import AppSortPopover from "components/synthesis/AppSortPopover";
import AppFilterPopover from "components/synthesis/AppFilterPopover";
import { Tag } from "types/database";
import { Moment } from "moment";
import Dialog from "@mui/material/Dialog";
import { formatInterview } from "api/loaders/interviews";

import {
  InterviewsSortOption,
  SortingFilteringContext,
  SortOption,
  InterviewsFilterOption,
  FilterOption,
  ModeFilterOption,
} from "contexts/SortingFilteringContext";

interface InterviewItem extends Interview {
  deleted: boolean;
  complete: boolean;
}

interface WrapperBoxProps extends BoxProps {
  hide?: boolean;
}
const WrapperBox = styled(Box)<WrapperBoxProps>(({ hide }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "20%",
  height: "100%",
  background: "#F7F7F8",
  paddingLeft: 0,
  paddingTop: 23,
  opacity: hide ? 0 : 1,
  transition: "opacity 0.5s",
}));

interface CounterBoxProps extends BoxProps {
  count: string;
}
const CounterBox = styled(Box)<CounterBoxProps>(({ count }) => ({
  color: "#fff",
  background: "#232326",
  borderRadius: 4,
  padding: count.length > 1 ? "2px 4px 3px" : "2px 8px 3px",
}));

export default function InterviewsPanel() {
  //Hooks
  const {
    isInterviewsPanelOpen,
    toggleInterviewsPanel,
    showInterviewInformation,
    showUploadPanel,
    openThemesPanel,
  } = useContext(SynthesisContext);
  const {
    loadInterviews,
    excludeInterview,
    includeInterview,
    hardDeleteInterview,
  } = useLoaderInterviews();
  const { caseid } = useParams();
  const {
    interviewsSorting,
    changeInterviewsSorting,
    interviewsFiltering,
    changeInterviewsFiltering,
    interviewsModeFilter,
    changeInterviewsModeFilter,
    interviewsFilteringValue,
    changeInterviewsFilteringValue,
    interviewsFilteringDate,
    changeInterviewsFilteringDate,
  } = useContext(SortingFilteringContext);
  //States
  const [interviewItems, setInterviewItems] = useState<InterviewItem[]>(null);
  const [allInterviewItems, setAllInterviewItems] =
    useState<InterviewItem[]>(null);
  const dataFetchedRef = useRef(false);
  const wrapperEl = useRef(null);
  const listEl = useRef(null);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const [interviewDeletingId, setInterviewDeletingId] = useState<number>(null);

  //Force Reducer
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  //Change Sorting
  const sortInterviews = useCallback(
    (
      interviews: InterviewItem[],
      sorting: InterviewsSortOption
    ): InterviewItem[] => {
      let sortedInterviews: InterviewItem[] = interviews;
      switch (sorting) {
        case InterviewsSortOption.UploadDate:
          sortedInterviews = interviews.sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1;
            if (a.createdAt < b.createdAt) return 1;
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 1;
          });
          break;
        case InterviewsSortOption.InterviewDate:
          sortedInterviews = interviews.sort((a, b) => {
            if (a.interviewDate > b.interviewDate) return -1;
            if (a.interviewDate < b.interviewDate) return 1;
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 1;
          });
          break;
        case InterviewsSortOption.Complete:
          sortedInterviews = interviews.sort((a, b) => {
            if (a.complete < b.complete) return -1;
            if (a.complete > b.complete) return 1;
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 1;
          });
          break;
        default:
          break;
      }
      return sortedInterviews;
    },
    []
  );

  //Filter Interviews
  const filterInterview = () => {
    if (
      interviewsModeFilter === ModeFilterOption.Contains &&
      interviewsFilteringValue.trim() === "" &&
      !interviewsFilteringDate
    ) {
      setInterviewItems(sortInterviews(allInterviewItems, interviewsSorting));
      return;
    }
    let filteredInterviews: InterviewItem[] = allInterviewItems;
    console.log(allInterviewItems);
    let compare: string = "";
    if (interviewsModeFilter === ModeFilterOption.Contains) {
      compare = interviewsFilteringValue.toLowerCase();
    }
    switch (interviewsFiltering) {
      case InterviewsFilterOption.InterviewHeader:
        filteredInterviews = allInterviewItems.filter(
          (interviewItem: InterviewItem) => {
            if (interviewsModeFilter === ModeFilterOption.IsEmpty) {
              return interviewItem.title.trim() === "";
            } else {
              return interviewItem.title.toLowerCase().indexOf(compare) > -1;
            }
          }
        );
        break;
      case InterviewsFilterOption.Organization:
        filteredInterviews = allInterviewItems.filter(
          (interviewItem: InterviewItem) => {
            if (interviewsModeFilter === ModeFilterOption.IsEmpty) {
              return interviewItem.organization.trim() === "";
            } else {
              return (
                interviewItem.organization.toLowerCase().indexOf(compare) > -1
              );
            }
          }
        );
        break;
      case InterviewsFilterOption.Source:
        filteredInterviews = allInterviewItems.filter(
          (interviewItem: InterviewItem) => {
            if (interviewsModeFilter === ModeFilterOption.IsEmpty) {
              return !interviewItem.source;
            } else if (interviewItem.source?.name) {
              return (
                interviewItem.source.name.toLowerCase().indexOf(compare) > -1
              );
            } else {
              return false;
            }
          }
        );
        break;
      case InterviewsFilterOption.Tag:
        filteredInterviews = allInterviewItems.filter(
          (interviewItem: InterviewItem) => {
            if (interviewsModeFilter === ModeFilterOption.IsEmpty) {
              return !interviewItem.tags || interviewItem.tags.length === 0;
            } else if (interviewItem.tags && interviewItem.tags.length > 0) {
              return (
                interviewItem.tags.findIndex(
                  (tag: Tag) => tag.name.toLowerCase().indexOf(compare) > -1
                ) > -1
              );
            } else {
              return false;
            }
          }
        );
        break;
      case InterviewsFilterOption.InterviewDate:
        filteredInterviews = allInterviewItems.filter(
          (interviewItem: InterviewItem) => {
            if (interviewsModeFilter === ModeFilterOption.IsEmpty) {
              return !interviewItem.interviewDate;
            } else {
              console.log(
                interviewItem.interviewDate.year(),
                interviewsFilteringDate.year()
              );
              return (
                interviewItem.interviewDate.year() ===
                  interviewsFilteringDate.year() &&
                interviewItem.interviewDate.month() ===
                  interviewsFilteringDate.month() &&
                interviewItem.interviewDate.day() ===
                  interviewsFilteringDate.day()
              );
            }
          }
        );
        break;
      case InterviewsFilterOption.InterviewBodyText:
        filteredInterviews = allInterviewItems.filter(
          (interviewItem: InterviewItem) => {
            if (interviewsModeFilter === ModeFilterOption.IsEmpty) {
              return interviewItem.summaryText.trim() === "";
            } else {
              return (
                interviewItem.summaryText.toLowerCase().indexOf(compare) > -1
              );
            }
          }
        );
        break;
      default:
        break;
    }
    setInterviewItems(filteredInterviews);
  };

  //Check wether an Interview is complete or no
  const checkComplete = (interview: Interview): boolean => {
    if (!interview.title) {
      return false;
    } else if (!interview.interviewee) {
      return false;
    } else if (!interview.organization) {
      return false;
    }
    return true;
  };

  //Call Interviews Request
  const callInterviews = useCallback(
    async (caseId: number) => {
      const results: Interview[] = await loadInterviews(caseId, false);
      if (results) {
        const items: InterviewItem[] = results.map((interview: Interview) => {
          return {
            ...interview,
            deleted: false,
            complete: checkComplete(interview),
          };
        });
        setInterviewItems(sortInterviews(items, interviewsSorting));
        setAllInterviewItems(items);
        if (items.length > 0) {
          openThemesPanel();
        }
      }
    },
    [loadInterviews, openThemesPanel, sortInterviews, interviewsSorting]
  );

  //Handle Exclude / Include Interview
  const handleExcludeInterview = useCallback(
    async (interviewId: number) => {
      const updatedInterview: Interview = await excludeInterview(interviewId);
      const index: number = interviewItems.findIndex(
        (interview: Interview) => interview.id === interviewId
      );
      interviewItems[index] = {
        ...interviewItems[index],
        ...updatedInterview,
      };
      forceUpdate();
    },
    [interviewItems, excludeInterview]
  );
  const handleIncludeInterview = useCallback(
    async (interviewId: number) => {
      const updatedInterview: Interview = await includeInterview(interviewId);
      const index: number = interviewItems.findIndex(
        (interview: Interview) => interview.id === interviewId
      );
      interviewItems[index] = {
        ...interviewItems[index],
        ...updatedInterview,
      };
      forceUpdate();
    },
    [interviewItems, includeInterview]
  );
  const handleDeleteInterview = useCallback(
    async (interviewId: number) => {
      const deleted: boolean = await hardDeleteInterview(interviewId);
      if (deleted) {
        const index: number = interviewItems.findIndex(
          (interview: Interview) => interview.id === interviewId
        );
        interviewItems[index] = {
          ...interviewItems[index],
          deleted: true,
        };
        forceUpdate();
      }
      setIsModalDeleteOpen(false);
    },
    [interviewItems, hardDeleteInterview]
  );

  //counters
  const countUsed: string = allInterviewItems
    ? allInterviewItems
        .filter(
          (interview: InterviewItem) => !interview.exclude && !interview.deleted
        )
        .length.toString()
    : "-";
  const countTotal: string = allInterviewItems
    ? allInterviewItems
        .filter((interview: InterviewItem) => !interview.deleted)
        .length.toString()
    : "-";

  //Fetch Interviews on Page Load
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    callInterviews(parseInt(caseid));
  }, [callInterviews, caseid]);

  //Recalculate ref offset on window resize
  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
    return () => {
      window.removeEventListener("resize", forceUpdate);
    };
  });

  //Listen for App Events
  useEffect(() => {
    const handleInterviewUploaded = (event: any) => {
      if (event.detail.interview && interviewItems) {
        const itw: Interview = formatInterview(event.detail.interview);
        if (itw.case.id === parseInt(caseid)) {
          interviewItems.unshift({ ...event.detail.interview });
          forceUpdate();
        }
      }
    };

    const handleInterviewUpdated = (event: any) => {
      if (event.detail.interview && interviewItems) {
        const itw: Interview = formatInterview(event.detail.interview);
        if (itw.case.id === parseInt(caseid)) {
          const index: number = interviewItems.findIndex(
            (item: InterviewItem) => item.id === event.detail.interview.id
          );
          if (index > -1) {
            interviewItems[index] = {
              ...event.detail.interview,
              complete: checkComplete(event.detail.interview),
            };
            forceUpdate();
          }
        }
      }
    };

    const handleInterviewDeleted = (event: any) => {
      console.log(
        "ON_INTERVIEW_DELETED - InterviewsPanel - ",
        event.detail,
        event.detail.interview_id,
        interviewItems
      );
      if (event.detail.interview_id && interviewItems) {
        const index: number = interviewItems.findIndex(
          (item: InterviewItem) => item.id === event.detail.interview_id
        );
        if (index > -1) {
          interviewItems.splice(index, 1);
          forceUpdate();
        }
      }
    };

    const handleTaskChanged = (event: any) => {
      console.log("ON_TASK_CHANGED - InterviewsPanel - ", event.detail.task);
      if (event.detail.task && interviewItems) {
        const index: number = interviewItems.findIndex(
          (item: InterviewItem) => item.loadTask.id === event.detail.task.id
        );
        if (index > -1) {
          interviewItems[index] = {
            ...interviewItems[index],
            loadTask: event.detail.task,
          };
          forceUpdate();
        }
      }
    };

    subscribe(AppEventName.ON_INTERVIEW_UPLOADED, handleInterviewUploaded);
    subscribe(AppEventName.ON_INTERVIEW_UPDATED, handleInterviewUpdated);
    subscribe(AppEventName.ON_INTERVIEW_METADATA, handleInterviewUpdated);
    subscribe(AppEventName.ON_INTERVIEW_READY, handleInterviewUpdated);
    subscribe(AppEventName.ON_INTERVIEW_DELETED, handleInterviewDeleted);
    subscribe(AppEventName.ON_TASK_CHANGED, handleTaskChanged);

    return () => {
      unsubscribe(AppEventName.ON_INTERVIEW_UPLOADED, handleInterviewUploaded);
      unsubscribe(AppEventName.ON_INTERVIEW_UPDATED, handleInterviewUpdated);
      unsubscribe(AppEventName.ON_INTERVIEW_METADATA, handleInterviewUpdated);
      unsubscribe(AppEventName.ON_INTERVIEW_READY, handleInterviewUpdated);
      unsubscribe(AppEventName.ON_INTERVIEW_DELETED, handleInterviewDeleted);
      unsubscribe(AppEventName.ON_TASK_CHANGED, handleTaskChanged);
    };
  }, [interviewItems, caseid]);

  //render no interviews
  const renderNoInterviews = () => {
    if (allInterviewItems.length === 0) {
      return (
        <Box sx={{ textAlign: "center", color: "#67676B" }} marginTop={85}>
          Please add interviews to get started
        </Box>
      );
    } else {
      return (
        <Box sx={{ textAlign: "center", color: "#67676B" }} marginTop={85}>
          No Interviews found
        </Box>
      );
    }
  };

  const renderActions = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingRight={16}
      >
        <AppButton
          variant="outlined"
          color="secondary"
          size="medium"
          label="Interviews"
          startIcon={<PlusIcon />}
          onClick={showUploadPanel}
        />
        <Stack direction="row" spacing={24}>
          <AppSortPopover
            id="sort-interviews-popover"
            selected={interviewsSorting}
            options={Object.values(InterviewsSortOption)}
            onSelect={(option: SortOption) => {
              changeInterviewsSorting(option as InterviewsSortOption);
              setInterviewItems(
                sortInterviews(interviewItems, option as InterviewsSortOption)
              );
            }}
          />
          <AppFilterPopover
            id="filter-interviews-popover"
            selectedFilter={interviewsFiltering}
            filterOptions={Object.values(InterviewsFilterOption)}
            onSelectFilter={(option: FilterOption) => {
              changeInterviewsFiltering(option as InterviewsFilterOption);
            }}
            selectedMode={interviewsModeFilter}
            onSelectMode={(option: ModeFilterOption) => {
              changeInterviewsModeFilter(option);
            }}
            inputValue={interviewsFilteringValue}
            onChangeInputValue={(value: string) => {
              changeInterviewsFilteringValue(value);
            }}
            dateValue={interviewsFilteringDate}
            onChangeDateValue={(value: Moment) => {
              changeInterviewsFilteringDate(value);
            }}
            onClose={() => {
              filterInterview();
            }}
          />
        </Stack>
      </Stack>
    );
  };

  const renderDeleteInterviewDialog = () => {
    return (
      <Dialog
        open={isModalDeleteOpen}
        onClose={() => {
          setInterviewDeletingId(null);
          setIsModalDeleteOpen(false);
        }}
        aria-labelledby="modal-delete-interview"
        aria-describedby="modal-delete-interview"
        closeAfterTransition
      >
        <Stack
          sx={{
            padding: "24px 40px",
          }}
          spacing={24}
        >
          <Typography variant="h3" fontSize="2rem">
            Delete Interview?
          </Typography>
          <Typography variant="body1" fontSize="1.6rem">
            Are you sure you want to delete this interview?
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={40}
          >
            <AppButton
              variant="link"
              color="primary"
              size="medium"
              label="No, cancel"
              onClick={() => {
                setInterviewDeletingId(null);
                setIsModalDeleteOpen(false);
              }}
            />
            <AppButton
              variant="contained"
              color="primary"
              size="medium"
              label="Yes, Delete Interview"
              onClick={() => {
                handleDeleteInterview(interviewDeletingId);
              }}
            />
          </Stack>
        </Stack>
      </Dialog>
    );
  };

  return (
    <>
      {renderDeleteInterviewDialog()}
      <WrapperBox hide={!isInterviewsPanelOpen} ref={wrapperEl}>
        <Box sx={{ position: "absolute", top: 6, right: 7 }}>
          <AppButton
            variant="circle"
            color="transparent"
            size="medium"
            icon={<CrossIcon />}
            onClick={toggleInterviewsPanel}
          />
        </Box>
        <Stack sx={{ paddingLeft: 16, marginBottom: 8 }} spacing={16}>
          <Typography variant="h6">
            <CounterBox component="span" count={countUsed}>
              {countUsed}
            </CounterBox>{" "}
            of {countTotal} Interviews Used
          </Typography>
          {renderActions()}
        </Stack>
        {interviewItems && interviewItems.length === 0 && renderNoInterviews()}
        <Box
          ref={listEl}
          sx={{
            maxHeight: `${
              listEl.current && wrapperEl.current
                ? wrapperEl.current.offsetHeight - listEl.current.offsetTop
                : 0
            }px`,
            overflowY: "auto",
          }}
        >
          <Stack>
            {interviewItems &&
              interviewItems.map(
                (interviewItem: InterviewItem, index: number) => {
                  return (
                    <InterviewBox
                      key={index}
                      interview={interviewItem}
                      deleted={interviewItem.deleted}
                      complete={interviewItem.complete}
                      onExclude={() => {
                        handleExcludeInterview(interviewItem.id);
                      }}
                      onInclude={() => {
                        handleIncludeInterview(interviewItem.id);
                      }}
                      onDelete={() => {
                        setInterviewDeletingId(interviewItem.id);
                        setIsModalDeleteOpen(true);
                      }}
                      onView={() => {
                        showInterviewInformation(interviewItem);
                      }}
                    />
                  );
                }
              )}
          </Stack>
        </Box>
      </WrapperBox>
    </>
  );
}
