import { Stack } from "@mui/material";
import DsButtons from "./sections/DsButtons";
import DsTypography from "./sections/DsTypography";

export default function DesignSystem() {
  return (
    <Stack
      sx={{
        position: "relative",
        marginLeft: "5rem",
        padding: 20,
      }}
      spacing={30}
    >
      <DsTypography />
      <DsButtons />
    </Stack>
  );
}
