import { useRef } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppButton from "components/materials/actions/AppButton";
import InformationIcon from "components/icons/informationIcon";

export interface ActionsProps {
  uploadAvailable: boolean;
  fileTypesAccepted: string;
  onFileSelected(file: File): void;
}

export default function Actions(props: ActionsProps) {
  //Props
  const { uploadAvailable, fileTypesAccepted, onFileSelected } = props;

  //Refs
  const inputFileRef = useRef(null);

  const openFileBrowser = () => {
    inputFileRef.current.click();
  };

  return (
    <Box
      sx={{
        minWidth: 340,
        maxWidth: 340,
        background: "rgba(73, 40, 255, 0.05)",
        marginTop: 28,
        marginRight: 16,
        padding: 16,
        height: "fit-content",
        borderRadius: "4px",
      }}
    >
      <Stack spacing={5}>
        <Stack spacing={8} direction="row">
          <Typography variant="body1">
            <InformationIcon />
          </Typography>
          <Typography variant="body1">
            <strong>To add interviews:</strong>
          </Typography>
        </Stack>
        <Stack spacing={5} sx={{ paddingLeft: 10 }}>
          <Typography variant="body1">• Paste into text field</Typography>
          <Typography variant="body1">• Drag and drop file</Typography>
          <Stack spacing={4} direction="row">
            <Typography variant="body1">•</Typography>
            <AppButton
              variant="link"
              color="primary"
              size="medium"
              label="Browse files"
              onClick={openFileBrowser}
              disabled={!uploadAvailable}
            />
            <input
              type="file"
              accept={fileTypesAccepted}
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={(event) => {
                onFileSelected(event.target.files[0]);
                inputFileRef.current.value = null;
              }}
              data-testid="file-input"
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
