import { notifClient } from "../utils/axios";

export function useApiNotifications() {
  const broadcast = async (message: string): Promise<any> => {
    console.log("API useApiNotifications");
    let input: any = {
      content: message,
      type: "string",
    };
    try {
      let route: string = `/broadcast`;
      let response = await (await notifClient()).post(route, input);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.statusText);
    }
  };

  return {
    broadcast: broadcast,
  };
}
