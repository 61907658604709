export interface Status {
  state: State;
  error?: string;
}

export enum State {
  Waiting = "WAITING",
  Loading = "LOADING",
  Error = "ERROR",
  Loaded = "LOADED",
}
export interface ListParams {
  limit?: number;
  offset?: number;
  orderBy?: string;
  orderDir?: "asc" | "desc";
}
export interface ListData {
  count: number;
  next: string;
  previous: string;
}

export enum AppApiError {
  ThemeNotFound = "THEME_NOT_FOUND",
}
