import { apiClient } from "../utils/axios";
import { handleError } from "../utils/axios";
import { timeout } from "../utils/helpers";
//import { useOktaAuth } from "@okta/okta-react";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";

export function useApiChunks() {
  const { mockDataActivated } = useContext(AppContext);
  //const { oktaAuth } = useOktaAuth();
  //const accessToken: string = oktaAuth.getAccessToken();
  const accessToken: string = null;

  const getChunks = async (
    themeId: number,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<any[]> => {
    console.log(`API getChunks`, mockDataActivated ? "MOCK" : "API");
    try {
      let results: any[] = [];
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        results = require(`api/mockdata/chunks.json`);
      } else {
        let route: string = `/theme/${themeId}/chunks`;
        if (orderBy)
          route += `&ordering=${orderDir === "desc" ? "-" : ""}${orderBy}`;
        if (limit) route += `&limit=${limit}`;
        if (offset) route += `&offset=${offset}`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("results" in response.data) {
          results = response.data.results;
        } else {
          results = response.data;
        }
      }
      return results;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getChunksCount = async (themeId: number): Promise<number> => {
    console.log(`API getChunksCount`, mockDataActivated ? "MOCK" : "API");
    try {
      let count: number = 0;
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        const results: any[] = require(`api/mockdata/chunks.json`);
        count = results.length;
      } else {
        const route: string = `/theme/${themeId}/chunks?limit=1`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("count" in response.data) {
          count = response.data.count;
        } else {
          count = response.data.length;
        }
      }
      return count;
    } catch (error: any) {
      handleError(error);
    }
  };

  return {
    getChunks,
    getChunksCount,
  };
}
