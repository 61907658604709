import { useContext } from "react";
import { ToasterContext } from "contexts/ToasterContext";
import AppSnackBar from "components/materials/communication/AppSnackBar";
import Header from "./Header";
import Menu from "./Menu";

export default function Navigation() {
  //Hooks
  const { toasterData, toasterShown, hideToaster } = useContext(ToasterContext);

  return (
    <>
      <AppSnackBar
        variant={toasterData?.variant}
        title={toasterData?.title}
        message={toasterData?.message}
        open={toasterShown}
        onClose={hideToaster}
      />
      <div style={{ position: "fixed", top: 0, zIndex: 100 }}>
        <Header />
        <Menu />
      </div>
    </>
  );
}
