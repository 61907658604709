import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppButton from "components/materials/actions/AppButton";
import InterviewIcon from "components/icons/InterviewIcon";
import { Chunk, Interview } from "types/database";
import DoubleQuotesIcon from "components/icons/DoubleQuotesIcon";

export interface MentionBoxProps {
  interview: Interview;
  citation: Chunk;
  onClick?(): void;
}

export default function MentionBox(props: MentionBoxProps) {
  //Props
  const { interview, citation, onClick } = props;

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      spacing={10}
      sx={{ marginRight: 16, marginBottom: 20, cursor: "pointer" }}
      onClick={onClick}
    >
      <Box sx={{ marginTop: 3 }}>
        <DoubleQuotesIcon htmlColor="#4928FF" />
      </Box>
      <Stack>
        <Box
          sx={{
            maxHeight: 190,
            overflowY: "hidden",
            position: "relative",
            paddingRight: 20,
          }}
        >
          <Box
            sx={{
              background:
                "linear-gradient(to bottom, rgba(246,246,246,0) 0%,rgba(246,246,246,1) 90%)",
              position: "absolute",
              width: "100%",
              height: 60,
              top: 130,
            }}
          />
          <Typography variant="body2">{citation.text}</Typography>
        </Box>
        {interview && (
          <>
            <Typography variant="body1" color="#67676B">
              {interview.interviewee}
            </Typography>
            <AppButton
              variant="link"
              color="grey-green"
              size="medium"
              label={interview.title}
              startIcon={<InterviewIcon />}
              onClick={onClick}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
}
