import { AppLayout } from "components/materials/containers/AppLayout";
import { useTranslation } from "react-i18next";

export default function ErrorApi() {
  const { t } = useTranslation();
  return (
    <AppLayout>
      <h2>{t("fallbacks.lbl-unauthorized-access")}</h2>
      <p>
        {t("fallbacks.lbl-you-are-not-allowed-to-access-the-page")}
        <br />
        {t("fallbacks.lbl-please-verify-you-are-connected")}
      </p>
    </AppLayout>
  );
}
