import { useContext } from "react";
import { SynthesisContext } from "contexts/SynthesisContext";
import { Box, Stack } from "@mui/material";
import AppButton from "components/materials/actions/AppButton";
import DoubleQuotesIcon from "components/icons/DoubleQuotesIcon";
import MenuIcon from "components/icons/MenuIcon";

export default function Menu() {
  //Hooks
  const {
    toggleInterviewsPanel,
    toggleThemesPanel,
    isInterviewsPanelOpen,
    isThemesPanelOpen,
  } = useContext(SynthesisContext);

  return (
    <Box
      sx={{
        position: "fixed",
        width: "5rem",
        height: "calc(100vh - 5rem)",
        background: "#232326",
        textAlign: "center",
        paddingTop: 32,
      }}
    >
      <Stack spacing={16}>
        <AppButton
          variant="icon"
          color={isInterviewsPanelOpen ? "primary" : "secondary"}
          icon={<DoubleQuotesIcon />}
          onClick={toggleInterviewsPanel}
        />
        <AppButton
          variant="icon"
          color={isThemesPanelOpen ? "primary" : "secondary"}
          icon={<MenuIcon />}
          onClick={toggleThemesPanel}
        />
      </Stack>
    </Box>
  );
}
