import { createContext, useState, FC, PropsWithChildren as Props } from "react";

//////////////////////////////
//CONTEXT FOR QUERIES
//////////////////////////////

type ContextState = {
  activeConversationId: string;
  changeActiveConversation: (conversationId: string) => void;
};

const defaultValues: ContextState = {
  activeConversationId: Date.now().toString(),
  changeActiveConversation: () => {},
};

export const ConversationContext = createContext<ContextState>(defaultValues);

export const ConversationProvider: FC<Props> = ({ children }) => {
  //States
  const [activeConversationId, setActiveConversationId] = useState<string>(
    defaultValues.activeConversationId
  );

  //Update Functions
  const changeActiveConversation = (conversationId: string) => {
    setActiveConversationId(conversationId);
  };

  return (
    <ConversationContext.Provider
      value={{
        activeConversationId,
        changeActiveConversation,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};
