import { useState, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Box, { BoxProps } from "@mui/material/Box";
import AppButton from "../actions/AppButton";
import RefreshIcon from "components/icons/RefreshIcon";
import { SxProps } from "@mui/system";
import { AppTooltip } from "../communication/AppTooltip";
import InfoIcon from "components/icons/InfoIcon";
import { Required } from "./Required";

export interface AppSelectProps {
  items: SelectItem[];
  value: string;
  placeholder?: string;
  label?: string;
  tooltip?: JSX.Element | string | number;
  disabled?: boolean;
  fullWidth?: boolean;
  verticalPadding?: string;
  sx?: SxProps;
  resetEnabled?: boolean;
  resetValue?: string | number;
  error?: boolean;
  textError?: string;
  required?: boolean;
  additionalComponent?: JSX.Element;
  onChange(value: string | number): void;
}

export interface SelectItem {
  value: string | number;
  label: string | number;
  disabled?: boolean;
}

const StyledLabelBox = styled(Box)<BoxProps>(() => ({
  fontFamily: "BCGHendersonMod",
  fontSize: "1.4rem",
  fontWeight: 700,
  lineHeight: "1.4rem",
}));

interface StyledSelectProps extends SelectProps {
  isplaceholder?: number;
  iserror?: number;
  verticalpadding?: string;
}
const StyledSelect = styled(Select)<StyledSelectProps>(
  ({ isplaceholder, iserror, verticalpadding }) => ({
    fontSize: "1.4rem",
    border: iserror ? "1px solid #F14D19" : "1px solid #E4E4E9",
    backgroundColor: "#fff",
    "& .MuiSelect-icon": {
      width: "2rem",
      height: "2rem",
      top: "auto",
      color: "#232326",
    },
    "& .MuiSelect-outlined": {
      padding: verticalpadding ? `${verticalpadding} 14px` : "8.5px 14px",
    },
    "& .MuiOutlinedInput-input": {
      opacity: isplaceholder ? 0.6 : 1,
      "& .MuiBox-root": {
        whiteSpace: "nowrap",
        overflowX: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
    },
    "&.Mui-disabled": { borderColor: "#e8e8e8" },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E4E4E9",
        borderWidth: "0px",
      },
    },
    "> fieldset > legend": {
      width: 0,
    },
  })
);
interface StyledMenuItemProps extends MenuItemProps {
  isplaceholder?: number;
}
const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(
  ({ isplaceholder }) => ({
    opacity: isplaceholder ? 0.6 : 1,
    fontSize: "1.4rem",
    "&.Mui-selected": {
      backgroundColor: "#f0f3f9",
    },
    "&:hover": {
      backgroundColor: "#eceff5",
    },
    "& .MuiBox-root": {
      whiteSpace: "nowrap",
      overflowX: "hidden",
      textOverflow: "ellipsis",
    },
  })
);
const StyledBoxError = styled(Box)<BoxProps>(() => ({
  color: "#F14D19",
  fontSize: "1.1rem",
  lineHeight: "110%",
}));
export default function AppSelect(props: AppSelectProps) {
  const {
    items,
    value,
    placeholder,
    label,
    tooltip,
    disabled,
    fullWidth,
    verticalPadding,
    sx,
    error,
    textError,
    onChange,
    resetEnabled,
    resetValue,
    required,
    additionalComponent,
  } = props;

  const [inputResetValue, setInputResetValue] = useState<string | number>(
    undefined
  );

  let additionalStyles: any = { ...sx, opacity: disabled ? 0.5 : 1 };
  let width: string = "24rem";
  if (additionalStyles.hasOwnProperty("width")) {
    width = additionalStyles["width"];
  }
  if (!fullWidth) {
    additionalStyles = { width: width, ...additionalStyles };
  } else {
    width = "100%";
  }

  const handleChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    event.stopPropagation();
    const output = event.target.value;
    if (isNaN(Number(output))) {
      onChange(output.toString());
    } else {
      onChange(parseInt(output.toString()));
    }
  };

  const handleReset = () => {
    if (onChange) onChange(inputResetValue);
  };

  if (
    label &&
    resetEnabled &&
    (resetValue || value !== undefined) &&
    inputResetValue === undefined
  ) {
    setInputResetValue(resetValue || value);
  }

  return (
    <Stack
      direction="column"
      alignItems="left"
      spacing={12}
      style={{ width: width }}
    >
      {label && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={6}
        >
          <Stack direction="row" alignItems="center" spacing={6}>
            <StyledLabelBox>
              {label} {required && <Required />}
            </StyledLabelBox>
            {tooltip && (
              <AppTooltip content={tooltip}>
                <InfoIcon />
              </AppTooltip>
            )}
          </Stack>
          {resetEnabled && (
            <AppButton
              variant="icon"
              disabled={disabled}
              onClick={handleReset}
              icon={<RefreshIcon />}
            />
          )}
          {additionalComponent}
        </Stack>
      )}
      <Stack spacing={4}>
        <StyledSelect
          value={value}
          label={placeholder}
          onChange={handleChange}
          displayEmpty
          variant="outlined"
          sx={additionalStyles}
          disabled={disabled}
          isplaceholder={value ? 0 : 1}
          iserror={error ? 1 : 0}
          verticalpadding={verticalPadding}
        >
          {placeholder && (
            <StyledMenuItem value={""} isplaceholder={1} disableRipple>
              <Box
                sx={{ maxWidth: `calc(${width} - 40px)`, textAlign: "left" }}
              >
                {placeholder}
              </Box>
            </StyledMenuItem>
          )}
          {items &&
            items.map((item: SelectItem, index: number) => {
              return (
                <StyledMenuItem
                  value={item.value}
                  key={index}
                  isplaceholder={0}
                  disableRipple
                  disabled={item.disabled}
                >
                  <Box sx={{ maxWidth: `calc(${width} - 0px)` }}>
                    {item.label}
                  </Box>
                </StyledMenuItem>
              );
            })}
        </StyledSelect>
        {textError && <StyledBoxError>{textError}</StyledBoxError>}
      </Stack>
    </Stack>
  );
}
