import { useState } from "react";
import Popover from "@mui/material/Popover";
import Button, { ButtonProps } from "@mui/material/Button";
import { SxProps } from "@mui/system";
import { styled } from "@mui/material/styles";

export interface AppPopoverProps {
  id: string;
  label: JSX.Element | string | number;
  anchorOrigin: Origin;
  transformOrigin: Origin;
  buttonSx?: SxProps; //custom style apply to the container
  disabled?: boolean;
  closeOnClick?: boolean;
  variant?: "text" | "outlined" | "icon";
  onClose?(): void;
  onExit?(): void;
  onValidate?(): void;
  children: JSX.Element | string | number;
}
export interface Origin {
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "center" | "right";
}

const CustomButton = styled(Button)<ButtonProps>(({ theme, sx }) => ({
  minWidth: 0,
  width: "fit-content",
  color: "#000",
  fontSize: "1.4rem",
  textTransform: "none",
  fontWeight: 400,
  letterSpacing: 0,
  lineHeight: "100%",
  padding: "0",
  "&:hover": {
    background: "transparent",
  },
  "&.MuiButton-root": {
    ...sx,
    "&.MuiButton-outlined": {
      border: "1px solid #D9D9D9",
      padding: "7px 9px",
      backgroundColor: "#fff",
      ...sx,
    },
  },
}));

export default function AppPopover(props: AppPopoverProps) {
  const {
    id,
    label,
    anchorOrigin,
    transformOrigin,
    buttonSx,
    disabled,
    closeOnClick,
    onClose,
    onExit,
    onValidate,
    children,
    variant,
  } = props;
  let baseVariant: "text" | "contained" | "outlined" = "outlined";
  switch (variant) {
    case "icon":
      baseVariant = "text";
      break;
    default:
      baseVariant = variant;
      break;
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    setAnchorEl(null);
    if (event.nativeEvent instanceof PointerEvent && onClose) {
      onClose();
    } else if (
      (event.nativeEvent as KeyboardEvent).key === "Escape" &&
      onExit
    ) {
      onExit();
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setAnchorEl(null);
      if (onValidate) {
        onValidate();
      }
    }
  };

  return (
    <>
      <CustomButton
        disableRipple
        disableElevation
        disableFocusRipple
        variant={baseVariant}
        onClick={handleClick}
        sx={buttonSx}
        disabled={disabled}
      >
        {label}
      </CustomButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onKeyPress={handleKeyPress}
        onClick={() => {
          if (closeOnClick) {
            setAnchorEl(null);
          }
        }}
      >
        {children}
      </Popover>
    </>
  );
}
