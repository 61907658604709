import { SxProps } from "@mui/system";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";

export interface AppAvatarProps {
  variant: "light" | "dark";
  name: string;
  sx?: SxProps;
}

interface CustomAvatarProps extends AvatarProps {
  customvariant: "light" | "dark";
}

const StyledAvatar = styled(Avatar)<CustomAvatarProps>(({ customvariant }) => ({
  backgroundColor: customvariant === "light" ? "#FFFFFF" : "#FF8306",
  color: customvariant === "light" ? "#FF8306" : "#FFFFFF",
  width: "3.2rem",
  height: "3.2rem",
  fontSize: "1.4rem",
}));

export default function AppAvatar(props: AppAvatarProps) {
  const { variant, name, sx } = props;

  const letter: string = "JD";
  return (
    <StyledAvatar alt={name} sx={sx} customvariant={variant}>
      {letter}
    </StyledAvatar>
  );
}
