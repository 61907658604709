import {
  useContext,
  useState,
  useEffect,
  useReducer,
  useCallback,
  useRef,
} from "react";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { SynthesisContext } from "contexts/SynthesisContext";
import Box from "@mui/material/Box";
import AppButton from "components/materials/actions/AppButton";
import CrossIcon from "components/icons/CrossIcon";
import Paste from "./components/Paste";
import Actions from "./components/Actions";
import { Stack } from "@mui/material";
import { useLoaderInterviews } from "api/loaders/interviews";
import { Interview, TaskStatus } from "types/database";
import { RawCorpus } from "types/serializers";
import { subscribe, unsubscribe, AppEventName } from "contexts/appEvents";
import Metadata from "modules/information/components/Metadata";
import { useParams } from "react-router";
import { InterviewStatus } from "types/internal";
import { formatInterview, formatTask } from "api/loaders/interviews";
import { ToasterContext, Toaster } from "contexts/ToasterContext";
import Queue from "./components/Queue";
import Dialog from "@mui/material/Dialog";
import RadioButtonCheckIcon from "components/icons/RadioButtonCheckIcon";
import { Typography } from "@mui/material";
import { FileTypeEnum, fileTypeDict, FileType } from "types/database";

export default function UploadPanel() {
  //Hooks
  const { isUploadPanelVisible, hideUploadPanel } =
    useContext(SynthesisContext);
  const { loadInterviews, uploadInterview, loadRawCorpus } =
    useLoaderInterviews();
  const { caseid } = useParams();
  const { showToaster } = useContext(ToasterContext);
  //States
  const [uploadAvailable, setUploadAvailable] = useState<boolean>(false);
  const [status, setStatus] = useState<InterviewStatus>(null);
  const [message, setMessage] = useState<string>("");
  const [interviewId, setInterviewId] = useState<number>(null);
  const [interview, setInterview] = useState<Interview>(null);
  const [pendingInterviews, setPendingInterviews] = useState<Interview[]>(null);
  const [runningInterviews, setRunningInterviews] = useState<Interview[]>(null);
  const [rawCorpus, setRawCorpus] = useState<RawCorpus>(null);
  const [fileTypesAccepted, setFileTypesAccepted] = useState<string>("");
  const [isDialogFileTypeOpen, setIsDialogFileTypeOpen] =
    useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>(null);
  const fileTypesFetchedRef = useRef(false);

  //Force Reducer
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  //Init View when Panel is open
  const init = () => {
    setUploadAvailable(false);
    setStatus(InterviewStatus.Undefined);
    setMessage("");
    setRawCorpus(null);
    setInterview(null);
    setInterviewId(null);
    setPendingInterviews(null);
    setRunningInterviews(null);
    loadQueueList();
  };

  //Load Queue List
  const loadQueueList = useCallback(async () => {
    setUploadAvailable(false);
    const pending: Interview[] = await loadInterviews(
      parseInt(caseid),
      false,
      null,
      TaskStatus.PENDING
    );
    setPendingInterviews(pending);
    const running: Interview[] = await loadInterviews(
      parseInt(caseid),
      false,
      null,
      TaskStatus.RUNNING
    );
    setRunningInterviews(running);
    if (running.length === 0 || pending.length < 5) {
      setUploadAvailable(true);
    } else {
      setMessage(
        "You have reached the limit of interviews in queue (5)... Please retry later."
      );
    }
    if (running.length > 0) {
      setInterviewId(running[0].id);
    } else if (pending.length + running.length === 0) {
      setStatus(InterviewStatus.Undefined);
      setMessage("");
      setRawCorpus(null);
      setInterview(null);
      setInterviewId(null);
    }
  }, [loadInterviews, caseid]);

  //Call Upload Request
  const callUpload = async (file: File, fileType: string) => {
    setStatus(InterviewStatus.Uploading);
    const createdInterview: Interview = await uploadInterview(
      parseInt(caseid),
      file,
      fileType
    );
    setInterviewId(createdInterview.id);
    loadQueueList();
  };

  //Call RawCorpus Request
  const callRawCorpus = useCallback(
    async (interviewId: number) => {
      const result: RawCorpus = await loadRawCorpus(interviewId);
      setRawCorpus(result);
    },
    [loadRawCorpus]
  );

  const handleFileSelection = (file: File) => {
    /*onUpload={(file: File, fileType: string) => {
      callUpload(file, fileType);
    }}*/

    let fileType: FileType;
    const fileTypes = Object.values(FileTypeEnum);
    fileTypes.forEach((ft) => {
      if (fileTypeDict[ft].type === file.type) {
        fileType = fileTypeDict[ft];
      }
    });
    if (fileType && fileType.isChoiceRequired) {
      setSelectedFile(file);
      setIsDialogFileTypeOpen(true);
    } else if (fileType) {
      callUpload(file, fileType.name);
    } else {
      const toaster: Toaster = {
        variant: "failure",
        title: "Error",
        message: `Sorry, this type of file is not accepted (${fileTypesAccepted} only).`,
      };
      showToaster(toaster);
    }
  };

  //Set File Types Accepted
  useEffect(() => {
    if (fileTypesFetchedRef.current) return;
    fileTypesFetchedRef.current = true;
    let accepted: string = "";
    const fileTypes = Object.values(FileTypeEnum);
    fileTypes.forEach((ft) => {
      accepted += fileTypeDict[ft].extensions + ",";
    });
    setFileTypesAccepted(accepted);
  }, []);

  //Listen for App Events
  useEffect(() => {
    const handleMetadataInterview = (event: any) => {
      console.log(
        "ON_INTERVIEW_METADATA - UploadPanel - ",
        event.detail.interview,
        "interviewId:",
        interviewId
      );
      if (event.detail.interview && event.detail.interview.id === interviewId) {
        setInterview(formatInterview(event.detail.interview));
        setStatus(InterviewStatus.Readonly);
        setMessage("");
        callRawCorpus(event.detail.interview.id);
        loadQueueList();
        forceUpdate();
      }
    };

    const handleTaskChanged = (event: any) => {
      console.log(
        "ON_TASK_CHANGED - UploadPanel - ",
        event.detail.task,
        "interviewId:",
        interviewId
      );
      loadQueueList();
      if (
        event.detail.task &&
        interview &&
        interview.loadTask?.id === event.detail.task.id
      ) {
        if (event.detail.task.status === TaskStatus.FAILED) {
          hideUploadPanel();
        } else if (event.detail.task.status === TaskStatus.SUCCEEDED) {
          setStatus(InterviewStatus.Writable);
          setInterview({
            ...interview,
            loadTask: formatTask(event.detail.task),
          });
          forceUpdate();
        }
      }
    };

    const handleInterviewUploaded = (event: any) => {
      console.log(
        "ON_INTERVIEW_UPLOADED - UploadPanel - ",
        event.detail.interview,
        "interviewId:",
        interviewId
      );
      if (
        event.detail.interview &&
        event.detail.interview.case?.id === parseInt(caseid)
      ) {
        loadQueueList();
        forceUpdate();
      }
    };

    const handleInterviewDeleted = (event: any) => {
      console.log(
        "ON_INTERVIEW_DELETED - UploadPanel - ",
        event.detail,
        event.detail.reason
      );
      if (event.detail.reason === "invalid") {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: `Sorry, this type of file is not accepted (${fileTypesAccepted} only).`,
        };
        showToaster(toaster);
      }
      loadQueueList();
    };

    subscribe(AppEventName.ON_INTERVIEW_METADATA, handleMetadataInterview);
    subscribe(AppEventName.ON_TASK_CHANGED, handleTaskChanged);
    subscribe(AppEventName.ON_INTERVIEW_DELETED, handleInterviewDeleted);
    subscribe(AppEventName.ON_INTERVIEW_UPLOADED, handleInterviewUploaded);

    return () => {
      unsubscribe(AppEventName.ON_INTERVIEW_METADATA, handleMetadataInterview);
      unsubscribe(AppEventName.ON_TASK_CHANGED, handleTaskChanged);
      unsubscribe(AppEventName.ON_INTERVIEW_DELETED, handleInterviewDeleted);
      unsubscribe(AppEventName.ON_INTERVIEW_UPLOADED, handleInterviewUploaded);
    };
  }, [
    interview,
    callRawCorpus,
    interviewId,
    hideUploadPanel,
    showToaster,
    caseid,
    loadQueueList,
    fileTypesAccepted,
  ]);

  const handleSelectTypeFile = (fileType: FileType) => {
    callUpload(selectedFile, fileType.name);
    setSelectedFile(null);
    setIsDialogFileTypeOpen(false);
  };

  const renderFileTypeDialog = () => {
    return (
      <Dialog
        open={isDialogFileTypeOpen}
        onClose={() => {
          setIsDialogFileTypeOpen(false);
        }}
        aria-labelledby="modal-file-type-upload"
        aria-describedby="modal-file-type-upload"
        closeAfterTransition
      >
        <Stack
          sx={{
            padding: "24px 40px",
          }}
          spacing={24}
        >
          <Typography variant="h3" fontSize="2rem">
            HTML File uploading
          </Typography>
          <Typography variant="body1" fontSize="1.6rem">
            What type of HTML file are you uploading?
          </Typography>
          <Stack direction="row" alignItems="top" spacing={10}>
            <Typography variant="body1">
              <RadioButtonCheckIcon />
            </Typography>
            <Typography variant="body1" fontSize="1.6rem" lineHeight="1.6rem">
              Tegus File
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={40}
          >
            <AppButton
              variant="link"
              color="primary"
              size="medium"
              label="Cancel"
              onClick={() => {
                setIsDialogFileTypeOpen(false);
              }}
            />
            <AppButton
              variant="contained"
              color="primary"
              size="medium"
              label="Confirm"
              onClick={() => {
                handleSelectTypeFile(fileTypeDict[FileTypeEnum.TEGUS]);
              }}
            />
          </Stack>
        </Stack>
      </Dialog>
    );
  };

  return (
    <>
      {renderFileTypeDialog()}
      <Modal
        open={isUploadPanelVisible}
        onClose={hideUploadPanel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        keepMounted={false}
      >
        <Fade
          in={isUploadPanelVisible}
          timeout={{
            enter: 600,
            exit: 600,
          }}
        >
          <Box
            sx={{
              width: "78%",
              height: "100%",
              marginLeft: "22%",
            }}
          >
            <Slide
              direction="left"
              in={isUploadPanelVisible}
              mountOnEnter
              unmountOnExit
              timeout={{
                enter: 600,
                exit: 600,
              }}
              addEndListener={() => {
                if (isUploadPanelVisible) {
                  init();
                }
              }}
            >
              <Box
                sx={{
                  background: "#fff",
                  position: "relative",
                  height: "100%",
                }}
              >
                <Box sx={{ position: "absolute", top: 16, left: 20 }}>
                  <AppButton
                    variant="circle"
                    color="transparent"
                    size="medium"
                    icon={<CrossIcon />}
                    onClick={() => {
                      setInterview(null);
                      hideUploadPanel();
                    }}
                  />
                </Box>
                <Stack
                  direction="row"
                  height="100%"
                  justifyContent="space-between"
                >
                  <Paste
                    loading={
                      status === InterviewStatus.Uploading || !uploadAvailable
                    }
                    interview={interview}
                    rawCorpus={rawCorpus}
                    message={message}
                    onFileSelected={(file: File) => {
                      handleFileSelection(file);
                    }}
                  />
                  {!interview && (
                    <Stack spacing={32}>
                      <Actions
                        uploadAvailable={uploadAvailable}
                        fileTypesAccepted={fileTypesAccepted}
                        onFileSelected={(file: File) => {
                          handleFileSelection(file);
                        }}
                      />
                      <Queue
                        pendingInterviews={pendingInterviews}
                        runningInterviews={runningInterviews}
                        onDeleteInterview={() => {
                          loadQueueList();
                        }}
                      />
                    </Stack>
                  )}
                  {uploadAvailable && interview && (
                    <Metadata
                      isNew
                      readonly={status === InterviewStatus.Readonly}
                      interview={interview}
                      onChange={() => {
                        setInterview(null);
                        setRawCorpus(null);
                        hideUploadPanel();
                      }}
                    />
                  )}
                </Stack>
              </Box>
            </Slide>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
