import { useState, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import QueueItem from "./QueueItem";
import { Interview } from "types/database";
import Dialog from "@mui/material/Dialog";
import AppButton from "components/materials/actions/AppButton";
import { useLoaderInterviews } from "api/loaders/interviews";

export interface QueueProps {
  pendingInterviews: Interview[];
  runningInterviews: Interview[];
  onDeleteInterview(): void;
}

export default function Queue(props: QueueProps) {
  //Props
  const { runningInterviews, pendingInterviews, onDeleteInterview } = props;
  //Hooks
  const { hardDeleteInterview } = useLoaderInterviews();
  //States
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const [interviewDeletingId, setInterviewDeletingId] = useState<number>(null);

  const handleDeleteInterview = useCallback(
    async (interviewId: number) => {
      const deleted: boolean = await hardDeleteInterview(interviewId);
      if (deleted) {
        onDeleteInterview();
      }
      setIsModalDeleteOpen(false);
    },
    [hardDeleteInterview, onDeleteInterview]
  );

  const renderDeleteInterviewDialog = () => {
    return (
      <Dialog
        open={isModalDeleteOpen}
        onClose={() => {
          setInterviewDeletingId(null);
          setIsModalDeleteOpen(false);
        }}
        aria-labelledby="modal-delete-interview"
        aria-describedby="modal-delete-interview"
        closeAfterTransition
      >
        <Stack
          sx={{
            padding: "24px 40px",
          }}
          spacing={24}
        >
          <Typography variant="h3" fontSize="2rem">
            Delete Interview?
          </Typography>
          <Typography variant="body1" fontSize="1.6rem">
            Are you sure you want to delete this interview?
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={40}
          >
            <AppButton
              variant="link"
              color="primary"
              size="medium"
              label="No, cancel"
              onClick={() => {
                setInterviewDeletingId(null);
                setIsModalDeleteOpen(false);
              }}
            />
            <AppButton
              variant="contained"
              color="primary"
              size="medium"
              label="Yes, Delete Interview"
              onClick={() => {
                handleDeleteInterview(interviewDeletingId);
              }}
            />
          </Stack>
        </Stack>
      </Dialog>
    );
  };

  if (runningInterviews?.length + pendingInterviews?.length === 0) {
    return null;
  }

  return (
    <>
      {renderDeleteInterviewDialog()}
      <Stack spacing={12} paddingRight={16}>
        <Typography variant="h10">
          pending uploads (5 maximum in queue)
        </Typography>
        {runningInterviews &&
          runningInterviews.map((interview: Interview, index: number) => {
            return (
              <QueueItem
                key={index}
                interview={interview}
                onDelete={() => {
                  setInterviewDeletingId(interview.id);
                  setIsModalDeleteOpen(true);
                }}
              />
            );
          })}
        {pendingInterviews &&
          pendingInterviews.map((interview: Interview, index: number) => {
            return (
              <QueueItem
                key={index}
                interview={interview}
                onDelete={() => {
                  setInterviewDeletingId(interview.id);
                  setIsModalDeleteOpen(true);
                }}
              />
            );
          })}
      </Stack>
    </>
  );
}
