import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Stack, Box, BoxProps } from "@mui/material";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import InfoIcon from "components/icons/InfoIcon";
import AppButton from "../actions/AppButton";
import RefreshIcon from "components/icons/RefreshIcon";
import { AppTooltip } from "../communication/AppTooltip";
import { SxProps } from "@mui/system";
import { InputAdornment } from "@mui/material";
import DateIcon from "components/icons/DateIcon";
import { Required } from "./Required";

interface AppInputTextProps {
  placeholder: string;
  maxLength: number;
  multiline?: boolean;
  maxRows?: number;
  label?: string;
  tooltip?: JSX.Element | string | number;
  disabled?: boolean;
  fullWidth?: boolean;
  sx?: SxProps;
  value?: string;
  resetEnabled?: boolean;
  resetValue?: string;
  prefix?: string;
  suffix?: string;
  endIcon?: JSX.Element;
  error?: boolean;
  textError?: string;
  required?: boolean;
  onChange(value: string): void;
  onKeyPress?(event: React.KeyboardEvent): void;
}

interface CustomOutlinedInputProps extends OutlinedInputProps {
  endicon?: JSX.Element;
  iserror?: number;
}
const StyledInput = styled(OutlinedInput)<CustomOutlinedInputProps>(
  ({ multiline, endicon, iserror }) => ({
    fontFamily: "BCGHendersonSans",
    fontSize: "1.6rem",
    border: "none",
    paddingRight: multiline || endicon ? "12px" : "0px",
    backgroundColor: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-disabled": { borderColor: "#e8e8e8" },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e8e8e8",
        border: "none",
      },
    },
  })
);

const StyledLabelBox = styled(Box)<BoxProps>(() => ({
  fontFamily: "BCGHendersonMod",
  fontSize: "1.4rem",
  fontWeight: 700,
  lineHeight: "1.4rem",
  color: "#232326",
}));

const StyledBoxError = styled(Box)<BoxProps>(() => ({
  color: "#F14D19",
  fontSize: "1.1rem",
  lineHeight: "110%",
}));

export default function AppTransparentInputText(props: AppInputTextProps) {
  const {
    placeholder,
    maxLength,
    multiline,
    maxRows,
    label,
    tooltip,
    disabled,
    fullWidth,
    sx,
    value,
    resetEnabled,
    resetValue,
    prefix,
    suffix,
    endIcon,
    error,
    textError,
    required,
    onChange,
    onKeyPress,
  } = props;

  const [inputResetValue, setInputResetValue] = useState<string>(undefined);

  let additionalStyles: any = { ...sx };
  let width: string = "24rem";
  if (additionalStyles.hasOwnProperty("width")) {
    width = additionalStyles["width"];
  }
  if (!fullWidth) {
    additionalStyles = { width: width, ...additionalStyles };
  } else {
    width = "100%";
  }

  const inputRef = React.useRef<HTMLInputElement>();

  const handleKeyPress = (event: React.KeyboardEvent) => {
    onKeyPress && onKeyPress(event);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.stopPropagation();
    let output = event.target.value;

    if (prefix && value && output.startsWith(prefix)) {
      output = output.substring(prefix.length);
    }

    if (suffix && value && output.endsWith(suffix)) {
      output = output.substring(0, output.length - suffix.length);
    }
    onChange(output);
  };

  const handleReset = () => {
    onChange(inputResetValue);
  };

  if (
    label &&
    resetEnabled &&
    (resetValue || value !== undefined) &&
    inputResetValue === undefined
  ) {
    setInputResetValue(resetValue || value);
  }

  let renderValue: string = value;
  if (prefix && value) {
    renderValue = prefix + value;
  }
  if (suffix && value) {
    renderValue = renderValue + suffix;
  }
  return (
    <Stack
      direction="column"
      alignItems="left"
      spacing={12}
      style={{ width: width }}
    >
      {label && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={6}
        >
          <Stack direction="row" alignItems="center" spacing={6}>
            <StyledLabelBox>
              {label} {required && <Required />}
            </StyledLabelBox>
            {tooltip && (
              <AppTooltip content={tooltip}>
                <InfoIcon sx={{ color: "#1D4659" }} />
              </AppTooltip>
            )}
          </Stack>
          {resetEnabled && (
            <AppButton
              variant="icon"
              disabled={disabled}
              onClick={handleReset}
              icon={<RefreshIcon />}
            />
          )}
        </Stack>
      )}
      <Stack spacing={4}>
        <StyledInput
          size="small"
          placeholder={placeholder}
          fullWidth={fullWidth}
          type="text"
          multiline={multiline}
          maxRows={maxRows || 5}
          minRows={30}
          disabled={disabled}
          sx={additionalStyles}
          iserror={error ? 1 : 0}
          onChange={handleChange}
          inputRef={inputRef}
          inputProps={{
            maxLength: maxLength,
            value: renderValue,
          }}
          endicon={endIcon}
          endAdornment={
            endIcon && (
              <InputAdornment position="end">
                <DateIcon />
              </InputAdornment>
            )
          }
          onKeyPress={handleKeyPress}
          onSelect={() => {
            if (prefix && inputRef.current.selectionStart < prefix.length) {
              inputRef.current.setSelectionRange(prefix.length, prefix.length);
            }
            if (suffix && inputRef.current.selectionStart >= value.length) {
              inputRef.current.setSelectionRange(value.length, value.length);
            }
          }}
        />
        {textError && <StyledBoxError>{textError}</StyledBoxError>}
      </Stack>
    </Stack>
  );
}
