import {
  createContext,
  useState,
  FC,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { PropsWithChildren as Props } from "react";
import io from "socket.io-client";
import { env } from "utils/envVariables";
import { useApiNotifications } from "api/requests/notifications";
import { ToasterContext, Toaster } from "contexts/ToasterContext";
import { NOTIFICATIONS_ENABLED } from "utils/appSettings";
import { publish, AppEventName } from "contexts/appEvents";
import { formatInterview } from "api/loaders/interviews";
import { TaskStatus } from "types/database";

enum NotificationEventName {
  INTERVIEW_UPDATE = "INTERVIEW_UPDATE",
  THEMES_CHANGED = "THEMES_CHANGED",
  TASK_CHANGED = "TASK_CHANGED",
  INTERVIEW_DELETED = "INTERVIEW_DELETED",
  THEME_DELETED = "THEME_DELETED",
}
enum NotificationEventState {
  UPLOADED = "uploaded",
  METADATA = "metadata",
  UPDATED = "updated",
  READY = "ready",
  DELETED = "deleted",
}

export const socket =
  NOTIFICATIONS_ENABLED &&
  io(env().NOTIF_URI, {
    transports: ["websocket"],
    path: "/ws/socket.io/",
  });

export type NotificationsContextState = {
  isConnected: boolean;
  broadcast: (message: string) => void;
};

const contextDefaultValues: NotificationsContextState = {
  isConnected: false,
  broadcast: () => {},
};

export const NotificationsContext =
  createContext<NotificationsContextState>(contextDefaultValues);

export const NotificationsProvider: FC<Props> = ({ children }) => {
  //States
  const [isConnected, setIsConnected] = useState<boolean>(socket.connected);
  //Hooks
  const apiNotif = useApiNotifications();
  const { showToaster } = useContext(ToasterContext);

  //Functions
  const broadcast = (message: string) => {
    apiNotif
      .broadcast(message)
      .then((info) => {
        console.log("message broadcasted");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleInterviewUpdate = useCallback(
    (message: any): void => {
      let toaster: Toaster = null;
      switch (message.state) {
        case NotificationEventState.UPLOADED:
          publish(AppEventName.ON_INTERVIEW_UPLOADED, {
            interview: formatInterview(message.interview),
          });
          break;
        case NotificationEventState.METADATA:
          publish(AppEventName.ON_INTERVIEW_METADATA, {
            interview: formatInterview(message.interview),
          });
          break;
        case NotificationEventState.READY:
          publish(AppEventName.ON_INTERVIEW_READY, {
            interview: formatInterview(message.interview),
          });
          break;
        case NotificationEventState.UPDATED:
          publish(AppEventName.ON_INTERVIEW_UPDATED, {
            interview: formatInterview(message.interview),
          });
          break;
        case NotificationEventState.DELETED:
          publish(AppEventName.ON_INTERVIEW_DELETED, {
            interview: formatInterview(message.interview),
          });
          break;
      }
      toaster && showToaster(toaster);
    },
    [showToaster]
  );

  const handleThemesChanged = useCallback((message: any): void => {
    console.log("NOTIFICATION publish THEMES_CHANGED", message);
    publish(AppEventName.ON_THEMES_CHANGED, message);
  }, []);

  const handleInterviewDeleted = useCallback((message: any): void => {
    console.log("NOTIFICATION publish ON_INTERVIEW_DELETED", message);
    publish(AppEventName.ON_INTERVIEW_DELETED, message);
  }, []);

  const handleThemeDeleted = useCallback((message: any): void => {
    console.log("NOTIFICATION publish ON_THEME_DELETED", message);
    publish(AppEventName.ON_THEME_DELETED, message);
  }, []);

  const handleTaskChanged = useCallback(
    (message: any): void => {
      console.log("NOTIFICATION handleTaskChanged", message);
      publish(AppEventName.ON_TASK_CHANGED, { task: message });
      if (message?.status === TaskStatus.FAILED) {
        let toaster: Toaster = {
          title: "Interview Upload Failed",
          message:
            "Interview Upload Failed with Error: " + message?.result?.error,

          variant: "failure",
        };
        showToaster(toaster);
      }
    },
    [showToaster]
  );

  const handleBroadcast = useCallback(
    (message: any): void => {
      console.log(
        "received message on NotificationsContext broadcast",
        message
      );

      let content: any = null;
      try {
        content = JSON.parse(message.content);
      } catch {
        content = message.content;
      }

      let toaster: Toaster = null;
      if (content)
        switch (content.state) {
          case "themes-deleted":
            handleThemeDeleted(content);
            break;
          case "themes-changed":
            publish(AppEventName.ON_THEMES_CHANGED, {
              case_id: content.case_id,
            });
            toaster = {
              title: "New Theme",
              message: "Themes Updated",
              variant: "info",
            };
            break;
          case "interview-updated":
            publish(AppEventName.ON_INTERVIEW_UPDATED, {
              interview: formatInterview(content.interview),
            });
            toaster = {
              title: "Interview Updated",
              message: "An Interview has been updated",
              variant: "success",
            };
            break;
        }
      else {
        toaster = {
          title: "Broadcased Message",
          message: content,
          variant: "info",
        };
      }

      showToaster(toaster);
    },
    [showToaster, handleThemeDeleted]
  );

  useEffect(() => {
    if (NOTIFICATIONS_ENABLED) {
      if (!isConnected) {
        socket.on("connect", () => {
          setIsConnected(true);
        });

        socket.on("disconnect", () => {
          setIsConnected(false);
          publish(AppEventName.ON_WEBSOCKET_DISCONNECT, {});
        });
      }

      socket.on(NotificationEventName.INTERVIEW_UPDATE, function (message) {
        console.log("NOTIFICATION received message INTERVIEW_UPDATE", message);
        handleInterviewUpdate(JSON.parse(message));
      });
      socket.on(NotificationEventName.TASK_CHANGED, function (message) {
        console.log("NOTIFICATION received message TASK_CHANGED", message);
        handleTaskChanged(JSON.parse(message.toString()));
      });
      socket.on(NotificationEventName.THEMES_CHANGED, function (message) {
        console.log("NOTIFICATION received message THEMES_CHANGED", message);
        handleThemesChanged(JSON.parse(message.toString()));
      });
      socket.on(NotificationEventName.INTERVIEW_DELETED, function (message) {
        console.log("NOTIFICATION received message INTERVIEW_DELETED", message);
        handleInterviewDeleted(message);
      });
      socket.on(NotificationEventName.THEME_DELETED, function (message) {
        console.log("NOTIFICATION received message THEME_DELETED", message);
        handleThemeDeleted(message);
      });
      socket.on("broadcast", function (message) {
        handleBroadcast(message);
      });

      return () => {
        socket.off("connect");
        socket.off("disconnect");
        socket.off("broadcast");
        socket.off(NotificationEventName.INTERVIEW_UPDATE);
        socket.off(NotificationEventName.TASK_CHANGED);
        socket.off(NotificationEventName.THEMES_CHANGED);
        socket.off(NotificationEventName.INTERVIEW_DELETED);
      };
    }
  }, [
    showToaster,
    isConnected,
    handleInterviewUpdate,
    handleThemesChanged,
    handleInterviewDeleted,
    handleBroadcast,
    handleTaskChanged,
    handleThemeDeleted,
  ]);

  return (
    <NotificationsContext.Provider
      value={{
        isConnected,
        broadcast,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
