import { useContext } from "react";
import { useApiTags } from "api/requests/tags";
import { ToasterContext, Toaster } from "contexts/ToasterContext";
import { Tag } from "types/database";

const formatTags = (tags: any[]): Tag[] => {
  const formattedTags: Tag[] = tags.map((tag: any) => {
    return formatTag(tag);
  });
  return formattedTags;
};

const formatTag = (tag: any): Tag => {
  const formattedTag: Tag = {
    ...tag,
  };
  return formattedTag;
};

export function useLoaderTags() {
  //Hooks
  const apiTags = useApiTags();
  const { showToaster } = useContext(ToasterContext);

  const loadTag = async (id: number): Promise<Tag> => {
    return apiTags
      .getTag(id)
      .then((tag: any) => {
        return formatTag(tag);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Tag fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadTags = async (
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<Tag[]> => {
    return apiTags
      .getTags(orderBy, orderDir, limit, offset)
      .then((tags: any[]) => {
        return formatTags(tags);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Tags fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadTagsCount = async (): Promise<number> => {
    return apiTags
      .getTagsCount()
      .then((count: number) => {
        return count;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Tags Count fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const createTag = async (name: string): Promise<Tag> => {
    return apiTags
      .createTag(name)
      .then((created: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Tag has been saved successfully",
        };
        showToaster(toaster);
        return formatTag(created);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Tag saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const updateTag = async (id: number, name: string): Promise<Tag> => {
    return apiTags
      .updateTag(id, name)
      .then((updated: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Tag has been saved successfully",
        };
        showToaster(toaster);
        return formatTag(updated);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Tag saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const deleteTag = async (id: number): Promise<boolean> => {
    return apiTags
      .deleteTag(id)
      .then((result: boolean) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Tag has been successfully deleted ",
        };
        showToaster(toaster);
        return result;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Tag deletion",
        };
        showToaster(toaster);
        return null;
      });
  };

  return {
    loadTag: loadTag,
    loadTags: loadTags,
    loadTagsCount: loadTagsCount,
    createTag: createTag,
    updateTag: updateTag,
    deleteTag: deleteTag,
  };
}
