import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import { Box, Stack, StackProps } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import AppButton from "components/materials/actions/AppButton";
import { styled } from "@mui/material/styles";

const ContentStack = styled(Stack)<StackProps>(() => ({
  li: {
    fontSize: "1.4rem",
    lineHeight: "1.9rem",
  },
}));

const TitleTypography = styled(Typography)<TypographyProps>(() => ({
  fontSize: "1.3rem",
  color: "#232326",
}));
const SubTitleTypography = styled(Typography)<TypographyProps>(() => ({
  fontSize: "1.4rem",
  fontWeight: 700,
  color: "#232326",
}));
const TextTypography = styled(Typography)<TypographyProps>(() => ({
  fontSize: "1.4rem",
}));

export default function Disclaimer() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  //OnLoad
  useEffect(() => {
    if (!localStorage.getItem("acknowledgement")) {
      setIsOpen(true);
    }
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        //setInterviewDeletingId(null);
        //setIsModalDeleteOpen(false);
      }}
      aria-labelledby="modal-delete-interview"
      aria-describedby="modal-delete-interview"
      closeAfterTransition
    >
      <Stack
        sx={{
          padding: "24px 40px",
        }}
        spacing={24}
      >
        <Box>
          <Typography variant="h3" fontSize="2rem">
            Terms and Conditions
          </Typography>
          <Typography fontSize="1.2rem" color="#67676B">
            Updated April 18th, 2023
          </Typography>
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "50vh",
            border: "1px solid #e0e0e5",
            borderRadius: "4px",
            padding: "10px 16px",
          }}
        >
          <ContentStack spacing={10}>
            <Typography variant="h3" fontSize="1.6rem">
              BCG Use of Generative AI Guidance
            </Typography>
            <Typography variant="body1" fontSize="1.4rem" color="#D82216">
              FOR BCG INTERNAL USE ONLY
            </Typography>
            <TitleTypography variant="h8">Description</TitleTypography>
            <TextTypography>
              The use of Generative AI systems to create content including
              documentation, code, images, etc. has become more prevalent in our
              everyday lives. In line with our Responsible AI policy, please see
              guidance below for the use of Generative AI in your professional
              interactions.
            </TextTypography>
            <TitleTypography variant="h8">Scope</TitleTypography>
            <TextTypography>
              Please follow the below guidance when using Generative AI tools
              for either internal or client supported work. Please note that
              this sets the baseline for use in any client deliverable which
              require vetting, attribution, and client consent depending on use
              case by the Responsible AI (RAI) team.
            </TextTypography>
            <TitleTypography variant="h8">Guidance</TitleTypography>
            <SubTitleTypography> Authoring Articles</SubTitleTypography>
            <ul>
              <li>
                <strong>Must do:</strong> You must use in the introduction or
                foreword something similar to: “The author generated this text
                in part with [Tool Name], [Vendors Name]’s large-scale
                language-generation model. Upon generating draft language, the
                author reviewed, edited, and revised the language to their own
                liking and takes ultimate responsibility for the content of this
                publication.”
              </li>
              <li>
                <strong>Can Do</strong>: You can use GenAI tools to assist with
                organizing content in public facing articles, but must get
                approval to use GenAI tools to draft text in an article due to
                reputational concerns and requires approval on a case by case
                basis. Approval would be based on a substantial drafting test or
                when the subject of the article is GenAI.
              </li>
            </ul>
            <SubTitleTypography>Automatic Decision Making</SubTitleTypography>
            <ul>
              <li>
                <strong>Cannot Do</strong>: You cannot use GenAI tools to
                perform automatic decision-making as utilizing such tools could
                expose BCG to cyber-risk
              </li>
            </ul>
            <SubTitleTypography>Chatbots</SubTitleTypography>
            <ul>
              <li>
                <strong>Must Do:</strong>
                <ul>
                  <li>
                    Conversational chatbots must disclose to users that they are
                    talking with an AI system.
                  </li>
                  <li>
                    All BCG contracts that rely upon GenAI tools must include
                    appropriate disclaimers in the client contract. Your BCG
                    contracts counsel can provide case specific language.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Can do</strong>: You can create chatbots for clients
                using GenAI provided that the contract between BCG and the
                client contains appropriate contractual language which you can
                obtain from BCG’s Legal Team.
              </li>
            </ul>
            <SubTitleTypography>Code Development</SubTitleTypography>
            <ul>
              <li>
                <strong>Must Do</strong>: All BCG outputs (internal and
                external) that utilize GenAI tools in any way must include
                appropriate attribution to the GenAI vendor.
              </li>
              <li>
                <strong>Can Do</strong>: We can use GenAI tools to structure
                code, review code (PRs), diagnose bugs, answer questions about
                cod, and use code created by GenAI tools for productivity gains
                in the tooling used to generate code (e.g., CLI completion). All
                code (including written by you in conjunction with GenAI) will
                need to be scanned for vulnerabilities using a BCG approved
                Static Application Security testing (SAST) tool that provides
                BCG visibility into the code.
              </li>
              <li>
                <strong>Cannot Do</strong>: You cannot use a GenAI tool to
                produce software code that is integrated into a client software
                deliverable.
              </li>
            </ul>
            <SubTitleTypography>Code Testing</SubTitleTypography>
            <ul>
              <li>
                <strong>Can Do</strong>: You can use GenAI tools to test code
                which can be used to validate the output of BCG created code
                separate from work for our clients. Even for test purposes you
                need to make sure the code does not give away anything sensitive
                or proprietary when using a GenAI tool to generate those tests.
              </li>
            </ul>
            <SubTitleTypography>Data Summary/Analysis</SubTitleTypography>
            <ul>
              <li>
                <strong>Must do</strong>: We must get permission from a client
                prior to sharing their data with a GenAI tool
                <ul>
                  <li>
                    Using GenAI tools to summarize data must include a
                    disclaimer to users informing them that AI is being used and
                    of its potential limitations in any final report similar to:
                    <ul>
                      <li>
                        “Data was analyzed in party with [Tool Name], [Vendors
                        Name]. Upon generating analysis, BCG reviewed, edited,
                        and revised analysis in accordance with industry
                        standards.”
                      </li>
                    </ul>
                  </li>
                  <li>
                    All BCG contracts that rely upon GenAI tools must include
                    appropriate disclaimers in the client contract. Your BCG
                    contracts counsel can provide case specific language.
                  </li>
                  <li>
                    You must get clearance from research@bcg.com prior to using
                    / analyzing a dataset that we license from a third parties
                    in a GenAI tool. Some third party data sets prohibit usage
                    of the data set with any machine learning, neural network,
                    deep learning, predictive analytics or other artificial
                    intelligence computer or software program.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Can do</strong>: You can use GenAI tools for client data
                analysis, to generate materials which will be used internally by
                BCG case teams or BST (such as to summarize notes or create an
                initial outline) subject to the below steps being followed:
                <ul>
                  <li>
                    We must disclose to customers that we used GenAI to
                    summarize data
                  </li>
                  <li>
                    All data analysis must be checked by a BCG staff member to
                    corroborate its accuracy prior to inclusion in any report or
                    deliverable
                  </li>
                  <li>
                    Run a GenAI tool analysis multiple times on data to ensure
                    that responses/recommendations remain stable
                  </li>
                  <li>
                    Conduct a quality check by comparing data analysis
                    recommendations from one GenAI tools against others (carried
                    out by humans) to better validate recommendations
                  </li>
                </ul>
              </li>
              <li>
                <strong>Cannot Do</strong>:
                <ul>
                  <li>
                    At this time, BCG should not make specific client
                    recommendations that depend solely on the accuracy or
                    validity of GenAI generated output.
                  </li>
                  <li>
                    {" "}
                    You cannot ask a GenAI tool to develop documents/analysis in
                    the voice of a BCG competitor
                  </li>
                </ul>
              </li>
            </ul>
            <SubTitleTypography>Deep Fakes</SubTitleTypography>
            <ul>
              <li>
                <strong>Cannot Do</strong>: You cannot generate images or videos
                of a real person or their voice without their express written
                permission.
              </li>
            </ul>
            <SubTitleTypography>Demonstrations</SubTitleTypography>
            <ul>
              <li>
                <strong>Must Do</strong>: You must indicate that the content is
                AI generated in a way no user could reasonably misunderstand
                similar to:
                <ul>
                  <li>
                    “This demonstration was in part with [Tool Name], [Vendors
                    Name]’s [tool type].Image Generation: You must disclose that
                    images were generated using GenAI tools similar to: “This
                    image was generated with [Tool Name], [Vendors Name] using
                    prompt [Prompt Text].”
                  </li>
                </ul>
              </li>
              <li>
                <strong>Can Do</strong>: You can use GenAI tools for model
                demonstration purposes provided we are clear in the
                demonstration that the content is being generated by AI and that
                we cannot guarantee accuracy in responses.
              </li>
            </ul>
            <SubTitleTypography>Image Generation</SubTitleTypography>
            <ul>
              <li>
                <strong>Must Do</strong>: All BCG outputs (internal and
                external) that utilize GenAI tools in any way must include
                appropriate attribution to the GenAI vendor.
              </li>
              <li>
                <strong>Can Do</strong>: You can use images generated by GenAI
                tools for internal presentations or to showcase the potential of
                GenAI where the images are copyright-safe. Use of image
                generation by GenAI tools is only permitted on tools that
                provide copyright-safe images. All commercial work that relies
                upon digital images must either
                <ul>
                  <li>Obtain a proper license to the images or;</li>
                  <li>Use a pre-vetted GenAI tool for image generation</li>
                </ul>
              </li>
            </ul>
            <SubTitleTypography>Integration into Products</SubTitleTypography>
            <ul>
              <li>
                <strong>Cannot Do</strong>: At this time, our licenses do not
                allow clients to use our licensed versions of ChatGPT or DALL-E
                2. This means that buttons cannot be added to products that
                allow client’s to use our licensed version of OpenAI’s tools. In
                most client situations, a client will need to obtain their own
                license to a GenAI tool.
              </li>
            </ul>
            <SubTitleTypography>Proposal Development</SubTitleTypography>
            <ul>
              <li>
                <strong>Must Do</strong>:
                <ul>
                  <li>
                    All BCG outputs (internal and external) that utilize GenAI
                    tools in any way must include appropriate attribution to the
                    GenAI vendor.
                  </li>
                  <li>
                    All BCG contracts that rely upon GenAI tools must include
                    appropriate disclaimers in the client contract. Your BCG
                    contracts counsel can provide case specific language.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Can Do</strong>: You can use GenAI tools to assist with
                writing responses to RFPs provided that:
                <ul>
                  <li>All answers are verified by a BCG staff member</li>
                  <li>
                    Due to the nature of the information provided by the client
                    (e.g., sensitive data), the proposal does not prohibit usage
                    of third-party tools to support drafting or analysis
                  </li>
                  <li>
                    Usage of a GenAI tool to assist with writing a proposal
                    response will not violate a Client NDA
                  </li>
                </ul>
              </li>
            </ul>
            <SubTitleTypography>Product Replication</SubTitleTypography>
            <ul>
              <li>
                <strong>Cannot Do</strong>: You cannot use a GenAI tool to
                create a derivative of a third-party product
              </li>
            </ul>
            <SubTitleTypography>Share Access</SubTitleTypography>
            <ul>
              <li>
                <strong>Cannot Do</strong>: While we can use GenAI tools like
                OpenAI to support clients, we cannot share our access/licenses
                with clients
              </li>
            </ul>
            <SubTitleTypography>Use in Certain Countries</SubTitleTypography>
            <ul>
              <li>
                <strong>Must Do</strong>: Use of ChatGPT and other AI engines
                have been temporarily prohibited or are tightly regulated in
                certain countries, including Italy and China. We expect more
                countries to issue regulations in the future and will update
                guidance accordingly. Before using GenAI, especially to process
                personal data, you must contact the legal team at legal@bcg.com
                to understand the local regulatory regime , even if the personal
                data is publicly available or has been hashed or de-identified.
              </li>
            </ul>
            <TitleTypography variant="h8">Exception Handling</TitleTypography>
            <TextTypography>
              For latest guidance on Generative AI please reach out to
              RAI_GenerativeAI@BCG.com. In case of any questions or doubts on
              whether your use of ChatGPT/Generative AI violates this policy,
              please reach out to RAI_GenerativeAI@BCG.com first before
              proceeding. Exceptions to this Standard must be approved by the
              Responsible AI Group RAI_GenerativeAI@BCG.com in conjunction with
              ISRM Strategy, Governance, Risk and Compliance team (ISRM S&GRC)
              (ISRM-StrategyandGovernance@bcg.com).
            </TextTypography>
          </ContentStack>
        </Box>
        <Typography variant="body1" fontSize="1.6rem">
          By clicking ”Acknowledge and Continue” you are confirming that you
          read, understood and agree with our Terms and Conditions.
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={20}
        >
          {/*
          <AppButton
            variant="outlined"
            color="secondary"
            size="large"
            label="Decline"
            onClick={() => {
              //window.close();
            }}
          />
          */}
          <AppButton
            variant="contained"
            color="primary"
            size="large"
            label="Acknowledge and Continue"
            onClick={() => {
              localStorage.setItem("acknowledgement", Date.now().toString());
              setIsOpen(false);
            }}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
}
