import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "./assets/react-datepicker.scss";
import "./assets/AppDatePicker.scss";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export interface AppDatePickerProps {
  id: string;
  label?: string;
  value: Date;
  minDate: Date;
  maxDate: Date;
  disabled?: boolean;
  inline?: boolean;
  onChange?(value: Date): void;
}

const StyledDatePicker = styled(DatePicker)<ReactDatePickerProps>(() => ({
  datepicker: {
    outline: "none",
    webkitAppearance: "none",
    "&:hover": {
      borderColor: "#1D4659",
      fontSize: "14px",
    },
    "&:focus": {
      borderColor: "#1D4659",
    },
  },
}));

export default function AppDatePicker(props: AppDatePickerProps) {
  const { id, label, value, disabled, minDate, maxDate, inline, onChange } =
    props;

  //const classes = useStyles();

  const handleChange = (date: Date) => {
    if (onChange) onChange(date);
  };

  return (
    <Stack direction="column" alignItems="left" spacing={8}>
      {label && <Box>{label}</Box>}
      <Box sx={{ width: "fit-content", marginRight: 52 }}>
        <StyledDatePicker
          id={id}
          selected={value}
          onChange={(date: Date) => handleChange(date)}
          placeholderText="MM/DD/YYYY"
          allowSameDay={false}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat="MM/dd/yyyy"
          inline={inline}
        />
      </Box>
    </Stack>
  );
}
