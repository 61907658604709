import { useState } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Theme } from "types/database";
import AppButton from "components/materials/actions/AppButton";
import ArrowRightIcon from "components/icons/ArrowRightIcon";
import ExpandIcon from "components/icons/ExpandIcon";
import CollapseIcon from "components/icons/CollapseIcon";
import Collapse from "@mui/material/Collapse";
import QuotesIcon from "components/icons/QuotesIcon";
import TrashIcon from "components/icons/TrashIcon";
import EditIcon from "components/icons/EditIcon";

export interface ThemeBoxProps {
  theme: Theme;
  onEdit?(): void;
  onDelete?(): void;
  onShowMentions?(): void;
}

interface ActionBoxProps extends BoxProps {
  hover: number;
}
const ActionBox = styled(Box)<ActionBoxProps>(({ hover }) => ({
  opacity: hover ? 1 : 0,
  transition: "opacity 0.2s",
}));

const TitleTypography = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 700,
  color: "#232326",
}));

interface SummaryTypoProps extends TypographyProps {
  visible: number;
}
const SummaryTypography = styled(Typography)<SummaryTypoProps>(
  ({ visible }) => ({
    paddingTop: 10,
    paddingBottom: 4,
    whiteSpace: "pre-wrap",
    opacity: visible ? 1 : 0,
    transition: "opacity 0.3s",
  })
);

export default function ThemeBox(props: ThemeBoxProps) {
  //States
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isSummaryVisible, setIsSummaryVisible] = useState<boolean>(false);
  //Props
  const { theme, onShowMentions, onDelete, onEdit } = props;
  //Functions
  const toggleSummary = () => {
    setIsSummaryVisible(!isSummaryVisible);
  };

  const renderActions = () => {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={20}>
          <AppButton
            variant="link"
            color="grey-green"
            size="medium"
            label="Summary"
            endIcon={isSummaryVisible ? <CollapseIcon /> : <ExpandIcon />}
            onClick={toggleSummary}
            disabled={!theme.summaryText}
          />
          {!theme.generated && (
            <ActionBox hover={+isHover}>
              <Stack direction="row" spacing={10}>
                <AppButton
                  variant="link"
                  color="grey-red"
                  size="medium"
                  startIcon={<TrashIcon />}
                  onClick={onDelete}
                />
                <AppButton
                  variant="link"
                  color="grey-green"
                  size="medium"
                  startIcon={<EditIcon />}
                  onClick={onEdit}
                />
              </Stack>
            </ActionBox>
          )}
        </Stack>
        <AppButton
          variant="link"
          color="grey-green"
          size="medium"
          label={`(${theme.interviews_count})`}
          startIcon={<QuotesIcon />}
          endIcon={<ArrowRightIcon />}
          onClick={onShowMentions}
          disabled={theme.interviews_count === 0}
        />
      </Stack>
    );
  };

  return (
    <Stack
      spacing={5}
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseOut={() => {
        setIsHover(false);
      }}
      sx={{
        padding: "9px 24px 9px 16px",
        backgroundColor: isHover ? "#fff" : "transparent",
        transition: "background-color 0.2s",
      }}
    >
      <Stack spacing={0}>
        <TitleTypography variant="body1">{theme.name}</TitleTypography>
        <Collapse in={isSummaryVisible}>
          <SummaryTypography variant="body1" visible={+isSummaryVisible}>
            {theme.summaryText}
          </SummaryTypography>
        </Collapse>
      </Stack>
      {renderActions()}
    </Stack>
  );
}
