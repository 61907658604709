import { apiClient } from "../utils/axios";

export function useHealth() {
  const get = async (): Promise<any> => {
    console.log("API getHealth");
    try {
      let route: string = `/health`;
      let response = await (await apiClient(null, false)).get(route);
      return response;
    } catch (error: any) {
      throw new Error(error.response.statusText);
    }
  };

  return {
    get: get,
  };
}
