import { useContext } from "react";
import { useApiInterviews } from "api/requests/interviews";
import { ToasterContext, Toaster } from "contexts/ToasterContext";
import { Interview, Task, TaskStatus } from "types/database";
import { InterviewPayload } from "types/payloads";
import { RawCorpus } from "types/serializers";
import moment from "moment";

export const formatInterviews = (interviews: any[]): Interview[] => {
  const formattedInterviews: Interview[] = interviews.map((interview: any) => {
    return formatInterview(interview);
  });
  return formattedInterviews;
};

export const formatInterview = (interview: any): Interview => {
  const formattedInterview: Interview = {
    ...interview,
    createdAt: interview.created_at ? moment(interview.created_at) : null,
    interviewDate: interview.interview_date
      ? moment(interview.interview_date)
      : null,
    summaryText: interview.summary_text,
    summaryBulleted: interview.summary_bulleted,
    sourceFile: interview.source_file,
    sourceFileType: interview.source_file_type,
    rawCorpus: interview.raw_corpus,
    loadTask: interview.load_task ? formatTask(interview.load_task) : null,
  };
  return formattedInterview;
};

export const formatTask = (task: any): Task => {
  const formattedTask: Task = {
    id: task.id,
    celeryId: task.celery_id,
    createdAt: task.created_at ? moment(task.created_at) : null,
    updatedAt: task.updated_at ? moment(task.updated_at) : null,
    endedAt: task.ended_at ? moment(task.ended_at) : null,
    status: task.status,
  };
  return formattedTask;
};

const formatRawCorpus = (rawCorpus: any): RawCorpus => {
  const formattedRawCorpus: RawCorpus = {
    ...rawCorpus,
    date: rawCorpus.date ? moment(rawCorpus.date) : null,
  };
  return formattedRawCorpus;
};

const formatPayload = (payload: InterviewPayload): InterviewPayload => {
  const formattedPayload: InterviewPayload = {
    ...payload,
    interview_date: payload.interview_date
      ? moment(payload.interview_date).format("YYYY-MM-DD")
      : null,
  };
  !formattedPayload.source_id && delete formattedPayload.source_id;
  !formattedPayload.interview_date && delete formattedPayload.interview_date;
  !formattedPayload.quality && delete formattedPayload.quality;

  return formattedPayload;
};

export function useLoaderInterviews() {
  //Hooks
  const apiInterviews = useApiInterviews();
  const { showToaster } = useContext(ToasterContext);

  const loadInterview = async (id: number): Promise<Interview> => {
    return apiInterviews
      .getInterview(id)
      .then((interview: any) => {
        return formatInterview(interview);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Interview fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadInterviews = async (
    caseId: number,
    deleted?: boolean,
    ids?: number[],
    taskStatus?: TaskStatus,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<Interview[]> => {
    return apiInterviews
      .getInterviews(
        caseId,
        deleted,
        ids,
        taskStatus,
        orderBy,
        orderDir,
        limit,
        offset
      )
      .then((interviews: any[]) => {
        return formatInterviews(interviews);
      })
      .catch((error: Error) => {
        console.log(error);
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Interviews fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadInterviewsCount = async (
    caseId: number,
    deleted?: boolean,
    ids?: number[],
    taskStatus?: TaskStatus
  ): Promise<number> => {
    return apiInterviews
      .getInterviewsCount(caseId, deleted, ids, taskStatus)
      .then((count: number) => {
        return count;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Interviews Count fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const uploadInterview = async (
    caseId: number,
    file: File,
    fileType: string
  ): Promise<Interview> => {
    return apiInterviews
      .uploadInterview(caseId, file, fileType)
      .then((created: Interview) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message:
            "Interview uploaded successfully. Sensei is now processing which may take a few minutes.",
        };
        showToaster(toaster);
        return formatInterview(created);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Interview upload",
        };
        showToaster(toaster);
        return null;
      });
  };

  const updateInterview = async (
    id: number,
    payload: InterviewPayload
  ): Promise<Interview> => {
    return apiInterviews
      .updateInterview(id, formatPayload(payload))
      .then((updated: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Interview has been saved successfully",
        };
        showToaster(toaster);
        return formatInterview(updated);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Interview saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const softDeleteInterview = async (id: number): Promise<boolean> => {
    return apiInterviews
      .softDeleteInterview(id)
      .then((result: boolean) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Interview has been successfully deleted ",
        };
        showToaster(toaster);
        return result;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Interview deletion",
        };
        showToaster(toaster);
        return null;
      });
  };

  const hardDeleteInterview = async (id: number): Promise<boolean> => {
    return apiInterviews
      .hardDeleteInterview(id)
      .then((result: boolean) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Interview has been successfully deleted ",
        };
        showToaster(toaster);
        return result;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Interview deletion",
        };
        showToaster(toaster);
        return null;
      });
  };

  const excludeInterview = async (id: number): Promise<Interview> => {
    return apiInterviews
      .excludeInterview(id)
      .then((updated: Interview) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Interview has been excluded successfully",
        };
        showToaster(toaster);
        return formatInterview(updated);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Interview saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const includeInterview = async (id: number): Promise<Interview> => {
    return apiInterviews
      .includeInterview(id)
      .then((updated: Interview) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Interview has been included successfully",
        };
        showToaster(toaster);
        return formatInterview(updated);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Interview saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadRawCorpus = async (id: number): Promise<RawCorpus> => {
    return apiInterviews
      .getRawCorpus(id)
      .then((rawCorpus: any) => {
        return formatRawCorpus(rawCorpus);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Raw Corpus fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const updateTags = async (
    interviewId: number,
    tagIds: number[]
  ): Promise<Interview> => {
    return apiInterviews
      .updateTags(interviewId, tagIds)
      .then((updated: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Interview has been saved successfully",
        };
        showToaster(toaster);
        return formatInterview(updated);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Interview saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadFileTypes = async (): Promise<string[]> => {
    return apiInterviews
      .getFileTypes()
      .then((fileTypes: string[]) => {
        return fileTypes;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during File Types fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  return {
    loadInterview: loadInterview,
    loadInterviews: loadInterviews,
    loadInterviewsCount: loadInterviewsCount,
    uploadInterview: uploadInterview,
    updateInterview: updateInterview,
    softDeleteInterview: softDeleteInterview,
    hardDeleteInterview: hardDeleteInterview,
    excludeInterview: excludeInterview,
    includeInterview: includeInterview,
    loadRawCorpus: loadRawCorpus,
    updateTags: updateTags,
    loadFileTypes: loadFileTypes,
  };
}
