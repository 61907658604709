import React from "react";
import { styled } from "@mui/material/styles";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "components/icons/RemoveIcon";
import ApproveIcon from "components/icons/ApproveIcon";
import BadIcon from "components/icons/BadIcon";
import Slide, { SlideProps } from "@mui/material/Slide";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Box, BoxProps, Stack } from "@mui/material";

export interface AppSnackBarProps {
  variant: "success" | "failure" | "info";
  title: string;
  message?: JSX.Element | string;
  open?: boolean;
  onClose?(): void;
}

const StyledSnackbar = styled(Snackbar)<SnackbarProps>(({ theme }) => ({
  "&.MuiSnackbar-root": {
    top: 20,
    "& .MuiSnackbarContent-root": {
      backgroundColor: "#fff",
      padding: "0.8rem",
      borderRadius: "8px",
    },
    "& .MuiSnackbarContent-message": {
      padding: "0rem",
      width: "45rem",
    },
    "& .MuiSnackbarContent-action": {
      marginRight: "0rem",
    },
  },
}));
const StyledTypoTitle = styled(Typography)<TypographyProps & { color: string }>(
  ({ color }) => ({
    color: color,
    fontSize: "1.1rem",
    lineHeight: "125%",
    marginBottom: 4,
  })
);
const StyledTypoBody = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: "#373737",
  fontSize: "1.4rem",
}));
const StyledIconBox = styled(Box)<
  BoxProps & { iconVariant: "success" | "failure" | "info" }
>(({ iconVariant }) => ({
  backgroundColor: iconVariant === "success" ? "#E0FAED" : "#FFEAE1",
  padding: "8px 10px 6px",
  marginRight: "5px",
  borderRadius: "8px",
  display: iconVariant ? "block" : "none",
}));

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

export default function AppSnackBar(props: AppSnackBarProps) {
  const { variant, title, message, open, onClose } = props;

  const handleClose = () => {
    if (onClose) onClose();
  };

  let color: string = "#373737";
  if (variant === "success") {
    color = "#2CDC87";
  } else if (variant === "failure") {
    color = "#FF7236";
  }

  return (
    <StyledSnackbar
      TransitionComponent={SlideTransition}
      sx={{ zIndex: 4000 }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      message={
        <Stack direction="row" spacing={3} alignItems="flex-start">
          <StyledIconBox iconVariant={variant}>
            {variant === "success" && <ApproveIcon htmlColor={color} />}
            {variant === "failure" && <BadIcon htmlColor={color} />}
          </StyledIconBox>
          <Box>
            <StyledTypoTitle color={color}>{title}</StyledTypoTitle>
            <StyledTypoBody>{message}</StyledTypoBody>
          </Box>
        </Stack>
      }
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" onClick={handleClose}>
            <RemoveIcon htmlColor="#D9D9D9" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
}
