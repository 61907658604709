import { AppLayout } from "components/materials/containers/AppLayout";
import { useTranslation } from "react-i18next";

export default function AccessCheck() {
  const { t } = useTranslation();
  return (
    <AppLayout>
      <h2>{t("fallbacks.lbl-checking-your-access")}</h2>
      <p>
        {t("fallbacks.lbl-application-is-checking-your-access")}

        <br />
        {t("fallbacks.lbl-please-make-sure-you-are-connected")}
      </p>
    </AppLayout>
  );
}
