import { useContext } from "react";
import Stack from "@mui/material/Stack";
import AppAvatar from "components/materials/content/AppAvatar";
import { SynthesisContext } from "contexts/SynthesisContext";

export default function Header() {
  //Hooks
  const { currentCase } = useContext(SynthesisContext);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={0}
      sx={{
        width: "100vw",
        height: "5rem",
        padding: "0rem 2rem",
      }}
    >
      <div>{`< ${currentCase?.name}`}</div>
      <AppAvatar name="John Doe" variant="dark" />
    </Stack>
  );
}
