import { useContext, useEffect, useCallback, useRef } from "react";
import { SynthesisContext } from "contexts/SynthesisContext";
import { subscribe, unsubscribe } from "contexts/appEvents";
import { AppEventName } from "contexts/appEvents";
import { Box } from "@mui/material";
import QueriesPanel from "./queries/QueriesPanel";
import InterviewsPanel from "./interviews/InterviewsPanel";
import ThemesPanel from "./themes/ThemesPanel";
import InformationPanel from "./information/InformationPanel";
import UploadPanel from "./upload/UploadPanel";
import { Interview } from "types/database";
import { formatInterview } from "api/loaders/interviews";
import { useParams } from "react-router";
import Disclaimer from "./disclaimer/Disclaimer";
import { useLoaderCases } from "api/loaders/cases";
import { Case } from "types/database";
import { useNavigate } from "react-router-dom";
import Navigation from "modules/navigation/Navigation";

export default function Synthesis() {
  //Hooks
  const { openThemesPanel, updateCurrentCase, currentCase } =
    useContext(SynthesisContext);
  const { caseid } = useParams();
  const { loadCase } = useLoaderCases();
  const navigate = useNavigate();

  //refs
  const dataFetchedRef = useRef(false);

  //Call Case Request
  const callCase = useCallback(
    async (caseId: number) => {
      const result: Case = await loadCase(caseId);
      if (result) {
        updateCurrentCase(result);
      } else {
        navigate(`/404`);
      }
    },
    [loadCase, updateCurrentCase, navigate]
  );

  //Fetch Case on Page Load
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    callCase(parseInt(caseid));
  }, [callCase, caseid]);

  //Listen for Interview Metadata changes
  useEffect(() => {
    const handleUpdateInterview = (event: any) => {
      console.log(
        "Catch ON_INTERVIEW_READY - Synthesis - ",
        event.detail.interview
      );
      if (event.detail.interview) {
        const itw: Interview = formatInterview(event.detail.interview);
        if (itw.case.id === parseInt(caseid)) {
          openThemesPanel();
        }
      }
    };
    subscribe(AppEventName.ON_INTERVIEW_READY, handleUpdateInterview);
    return () => {
      unsubscribe(AppEventName.ON_INTERVIEW_READY, handleUpdateInterview);
    };
  }, [openThemesPanel, caseid]);

  if (!currentCase) {
    return null;
  }

  return (
    <>
      {" "}
      <Navigation />
      <Disclaimer />
      <InformationPanel />
      <UploadPanel />
      <Box
        sx={{
          position: "relative",
          height: "calc(100vh - 5rem)",
          marginLeft: "5rem",
          borderTop: "1px solid #E4E4E9",
        }}
      >
        <InterviewsPanel />
        <ThemesPanel />
        <QueriesPanel />
      </Box>
    </>
  );
}
