import { apiClient } from "../utils/axios";
import { handleError } from "../utils/axios";
import { timeout } from "../utils/helpers";
//import { useOktaAuth } from "@okta/okta-react";
import { AppContext } from "contexts/AppContext";
import { NotificationsContext } from "contexts/NotificationsContext";
import { useContext } from "react";
import { Interview, Source, Tag, TaskStatus } from "types/database";
import { InterviewPayload } from "types/payloads";

export function useApiInterviews() {
  const { mockDataActivated } = useContext(AppContext);
  const { broadcast } = useContext(NotificationsContext);
  //const { oktaAuth } = useOktaAuth();
  //const accessToken: string = oktaAuth.getAccessToken();
  const accessToken: string = null;

  let sources: any = require(`api/mockdata/sources.json`);
  let tags: any = require(`api/mockdata/tags.json`);
  let interviews: any = require(`api/mockdata/interviews.json`);

  const getInterview = async (id: number): Promise<any> => {
    console.log(`API getInterview ${id}`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      await timeout(Math.random() * 500);
      const interviews: any[] = require(`api/mockdata/interviews.json`);
      const interview: Interview = interviews.find(
        (interview: Interview) => interview.id === id
      );
      return interview;
    }
    try {
      let route: string = `/interview/${id}`;
      const response = await (await apiClient(accessToken)).get(route);
      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getInterviews = async (
    caseId: number,
    deleted: boolean,
    ids?: number[],
    taskStatus?: TaskStatus,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<any[]> => {
    console.log(`API getInterviews`, mockDataActivated ? "MOCK" : "API");
    try {
      let results: any[] = [];
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        results = require(`api/mockdata/interviews.json`);
      } else {
        let route: string = `/interview?case_id=${caseId}`;
        if (ids && ids.length > 0) {
          ids.forEach((id: number) => {
            route += `&id=${id}`;
          });
        }
        if (deleted) route += `&deleted=${deleted}`;
        if (taskStatus) route += `&task_status=${taskStatus}`;
        if (orderBy)
          route += `&ordering=${orderDir === "desc" ? "-" : ""}${orderBy}`;
        if (limit) route += `&limit=${limit}`;
        if (offset) route += `&offset=${offset}`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("results" in response.data) {
          results = response.data.results;
        } else {
          results = response.data;
        }
      }
      return results;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getInterviewsCount = async (
    caseId: number,
    deleted: boolean,
    ids?: number[],
    taskStatus?: TaskStatus
  ): Promise<number> => {
    console.log(`API getInterviewsCount`, mockDataActivated ? "MOCK" : "API");
    try {
      let count: number = 0;
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        const results: any[] = require(`api/mockdata/interviews.json`);
        count = results.length;
      } else {
        let route: string = `/interview?case_id=${caseId}&limit=1`;
        if (ids && ids.length > 0) {
          ids.forEach((id: number) => {
            route += `&id=${id}`;
          });
        }
        if (deleted) route += `&deleted=${deleted}`;
        if (taskStatus) route += `&task_status=${taskStatus}`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("count" in response.data) {
          count = response.data.count;
        } else {
          count = response.data.length;
        }
      }
      return count;
    } catch (error: any) {
      handleError(error);
    }
  };

  const uploadInterview = async (
    caseId: number,
    file: File,
    fileType: string
  ): Promise<any> => {
    console.log(`API uploadInterview`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      await timeout(1000);
      const createdInterview: any = require(`api/mockdata/new-interview.json`);
      interviews.unshift(createdInterview);
      return createdInterview;
    }
    try {
      let formData = new FormData();
      formData.append("case_id", caseId.toString());
      formData.append("file", file);
      formData.append("source_file_type", fileType);
      const route: string = `/interview`;
      const response = await (
        await apiClient(accessToken)
      ).post(route, formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const updateInterview = async (
    id: number,
    payload: InterviewPayload
  ): Promise<any> => {
    console.log(
      `API updateInterview`,
      mockDataActivated ? "MOCK" : "API",
      payload
    );
    if (mockDataActivated) {
      await timeout(500);
      const index: number = interviews.findIndex(
        (interview: Interview) => interview.id === id
      );
      const source: Source = sources.find(
        (src: Source) => src.id === payload.source_id
      );
      const updatedInterview: any = {
        ...interviews[index],
        ...payload,
        source: source,
      };
      interviews[index] = updatedInterview;

      await timeout(500);
      const message: any = {
        state: "interview-updated",
        interview: { ...updatedInterview, interviews_count: 1 },
      };
      broadcast(JSON.stringify(message));

      return updatedInterview;
    }
    try {
      const route: string = `/interview/${id}`;
      const input: any = {
        ...payload,
      };
      const response = await (
        await apiClient(accessToken, true)
      ).patch(route, input);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const softDeleteInterview = async (id: number): Promise<any> => {
    console.log(`API softDeleteInterview`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const interviews: Interview[] = require(`api/mockdata/interviews.json`);
      const index: number = interviews.findIndex(
        (interview: Interview) => interview.id === id
      );
      const updatedInterview: any = {
        ...interviews[index],
        deleted: true,
      };
      interviews[index] = updatedInterview;
      return updatedInterview;
    }
    try {
      const route: string = `/interview/${id}`;
      const input: any = {
        deleted: true,
      };
      const response = await (
        await apiClient(accessToken, true)
      ).patch(route, input);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const hardDeleteInterview = async (id: number): Promise<boolean> => {
    console.log(`API hardDeleteInterview`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const index: number = interviews.findIndex(
        (interview: Interview) => interview.id === id
      );
      interviews = interviews.splice(index, 1);
      return true;
    }
    try {
      const route: string = `/interview/${id}`;
      await (await apiClient(accessToken)).delete(route);
      return true;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const excludeInterview = async (id: number): Promise<any> => {
    console.log(`API excludeInterview`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const interviews: Interview[] = require(`api/mockdata/interviews.json`);
      const index: number = interviews.findIndex(
        (interview: Interview) => interview.id === id
      );
      const updatedInterview: any = {
        ...interviews[index],
        exclude: true,
      };
      interviews[index] = updatedInterview;
      return updatedInterview;
    }
    try {
      const route: string = `/interview/${id}`;
      const input: any = {
        exclude: true,
      };
      const response = await (
        await apiClient(accessToken, true)
      ).patch(route, input);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const includeInterview = async (id: number): Promise<any> => {
    console.log(`API includeInterview`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const interviews: Interview[] = require(`api/mockdata/interviews.json`);
      const index: number = interviews.findIndex(
        (interview: Interview) => interview.id === id
      );
      const updatedInterview: any = {
        ...interviews[index],
        exclude: false,
      };
      interviews[index] = updatedInterview;
      return updatedInterview;
    }
    try {
      const route: string = `/interview/${id}`;
      const input: any = {
        exclude: false,
      };
      const response = await (
        await apiClient(accessToken, true)
      ).patch(route, input);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getRawCorpus = async (id: number): Promise<any> => {
    console.log(`API getRawCorpus ${id}`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      await timeout(Math.random() * 500);
      const rawCorpus: any = require(`api/mockdata/raw-corpus.json`);
      return rawCorpus;
    }
    try {
      let route: string = `/interview/${id}/raw_corpus`;
      const response = await (await apiClient(accessToken)).get(route);
      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  };

  const updateTags = async (
    interviewId: number,
    tagIds: number[]
  ): Promise<any> => {
    console.log(
      `API updateInterview`,
      mockDataActivated ? "MOCK" : "API",
      interviewId,
      tagIds
    );
    if (mockDataActivated) {
      await timeout(500);
      const index: number = interviews.findIndex(
        (interview: Interview) => interview.id === interviewId
      );
      const tagsList: Tag[] = tags.filter(
        (tag: Tag) => tagIds.indexOf(tag.id) > -1
      );
      const updatedInterview: any = {
        ...interviews[index],
        tags: tagsList,
      };
      interviews[index] = updatedInterview;

      await timeout(500);
      const message: any = {
        state: "interview-updated",
        interview: { ...updatedInterview },
      };
      broadcast(JSON.stringify(message));

      return updatedInterview;
    }
    try {
      const route: string = `/interview/${interviewId}/tags`;
      const input: any = tagIds;
      const response = await (
        await apiClient(accessToken, true)
      ).put(route, input);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getFileTypes = async (): Promise<string[]> => {
    console.log(`API getFileTypes`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      await timeout(Math.random() * 500);
      const fileTypes: any = require(`api/mockdata/source_file_types.json`);
      return fileTypes;
    }
    try {
      let route: string = `/interview/parser/source_file_types`;
      const response = await (await apiClient(accessToken)).get(route);
      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  };

  return {
    getInterview,
    getInterviews,
    getInterviewsCount,
    uploadInterview,
    updateInterview,
    softDeleteInterview,
    hardDeleteInterview,
    excludeInterview,
    includeInterview,
    getRawCorpus,
    updateTags,
    getFileTypes,
  };
}
