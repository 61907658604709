export type EnvVariables = {
  ENV: "local" | "dev" | "staging";
  API_URL: string;
  NOTIF_URI: string;
};

export const env = (): EnvVariables => {
  const hostname: string = window.location.hostname;
  if (hostname.indexOf(".gamma-platform.") > 0) {
    return {
      ENV: "dev",
      API_URL: "https://api.genai-synthesis.gamma-platform.com",
      NOTIF_URI: "https://api.genai-synthesis.gamma-platform.com",
    };
  } else if (hostname.indexOf(".infra.x.bcg.") > 0) {
    return {
      ENV: "dev",
      API_URL: "https://api.genai-synthesis.infra.x.bcg.com",
      NOTIF_URI: "https://api.genai-synthesis.infra.x.bcg.com",
    };
  } else {
    return {
      ENV: "local",
      API_URL: "https://api.localhost",
      NOTIF_URI: "http://localhost:8000",
    };
  }
};
