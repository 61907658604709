import { styled } from "@mui/material/styles";
import AppPopover from "components/materials/communication/AppPopover";
import { Box, Stack, StackProps } from "@mui/material";
import SortIcon from "components/icons/SortIcon";
import Typography, { TypographyProps } from "@mui/material/Typography";
import CheckIcon from "components/icons/CheckIcon";
import { SortOption } from "contexts/SortingFilteringContext";

export interface AppSortPopoverProps {
  id: string;
  options: SortOption[];
  selected: SortOption;
  onSelect?(option: SortOption): void;
}

const TitleTypography = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 700,
  fontSize: "1.1rem",
  color: "#fff",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
}));

const ChoiceTypography = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 400,
  fontSize: "1.4rem",
  color: "#fff",
}));

const ChoiceStack = styled(Stack)<StackProps>(({ theme }) => ({
  cursor: "pointer",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 8,
  paddingBottom: 8,
  "&:hover": {
    backgroundColor: "#59595d",
  },
}));

export default function AppSortPopover(props: AppSortPopoverProps) {
  const { id, options, selected, onSelect } = props;

  return (
    <AppPopover
      id={id}
      closeOnClick
      label={
        <Stack direction="row" spacing={4}>
          <SortIcon width="1.4rem" htmlColor="#00A887" />
          <Box sx={{ fontSize: "1.2rem", color: "#00A887" }}>Sort</Box>
        </Stack>
      }
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Stack
        sx={{ background: "#232326", color: "#fff", padding: "16px 0px 16px" }}
      >
        <Stack
          direction="row"
          spacing={10}
          paddingBottom={8}
          paddingLeft={10}
          paddingRight={10}
        >
          <CheckIcon htmlColor="transparent" />
          <TitleTypography>sort by</TitleTypography>
        </Stack>
        {options &&
          options.map((option: SortOption, index: number) => {
            return (
              <ChoiceStack
                key={index}
                direction="row"
                spacing={10}
                onClick={() => {
                  onSelect(option);
                }}
              >
                <CheckIcon
                  htmlColor={option === selected ? "#fff" : "transparent"}
                />
                <ChoiceTypography>{option}</ChoiceTypography>
              </ChoiceStack>
            );
          })}
      </Stack>
    </AppPopover>
  );
}
