import { useState, useCallback, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import AppPopover from "components/materials/communication/AppPopover";
import { Box, Stack } from "@mui/material";
import FilterIcon from "components/icons/FilterIcon";
import Typography, { TypographyProps } from "@mui/material/Typography";
import AppInputText from "components/materials/forms/AppInputText";
import AppInputDatePicker from "components/materials/forms/AppInputDatePicker";
import moment, { Moment } from "moment";

import {
  FilterOption,
  ModeFilterOption,
  InterviewsFilterOption,
} from "contexts/SortingFilteringContext";
import AppSelect, { SelectItem } from "components/materials/forms/AppSelect";

export interface AppFilterPopoverProps {
  id: string;
  filterOptions: FilterOption[];
  selectedFilter: FilterOption;
  onSelectFilter?(option: FilterOption): void;
  selectedMode: ModeFilterOption;
  onSelectMode?(option: ModeFilterOption): void;
  inputValue: string;
  onChangeInputValue?(value: string): void;
  dateValue: Moment;
  onChangeDateValue?(value: Moment): void;
  onClose?(): void;
}

const TitleTypography = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 700,
  fontSize: "1.1rem",
  color: "#fff",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
}));

export default function AppFilterPopover(props: AppFilterPopoverProps) {
  const {
    id,
    filterOptions,
    selectedFilter,
    onSelectFilter,
    selectedMode,
    onSelectMode,
    inputValue,
    onChangeInputValue,
    dateValue,
    onChangeDateValue,
    onClose,
  } = props;
  //States
  const [filterItems, setFilterItems] = useState<SelectItem[]>(null);
  const [modeItems, setModeItems] = useState<SelectItem[]>(null);
  const dataFetchedRef = useRef(false);

  //Populate Filters dropdownlist
  const populateFilters = useCallback(() => {
    const fItems: SelectItem[] = [];
    filterOptions.forEach((option: FilterOption) => {
      fItems.push({
        value: option,
        label: option,
      });
    });
    setFilterItems(fItems);

    const modeOptions: ModeFilterOption[] = Object.values(ModeFilterOption);
    const mItems: SelectItem[] = [];
    modeOptions.forEach((option: ModeFilterOption) => {
      mItems.push({
        value: option,
        label: option,
      });
    });
    setModeItems(mItems);
  }, [filterOptions]);

  //Fetch Sources & Tags on Page Load
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    populateFilters();
  }, [populateFilters]);

  return (
    <AppPopover
      id={id}
      label={
        <Stack direction="row" spacing={4}>
          <FilterIcon width="1.4rem" htmlColor="#00A887" />
          <Box sx={{ fontSize: "1.2rem", color: "#00A887" }}>Filter</Box>
        </Stack>
      }
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      onValidate={onClose}
      onExit={onClose}
    >
      <Stack
        sx={{
          background: "#232326",
          color: "#fff",
          padding: "16px 0px 16px",
          width: 240,
        }}
      >
        <Stack paddingLeft={10} paddingRight={10} spacing={12}>
          <TitleTypography>Filter</TitleTypography>
          <AppSelect
            fullWidth
            items={filterItems}
            value={selectedFilter}
            onChange={(newValue: string | number) => {
              const option: FilterOption = filterOptions.find(
                (opt: FilterOption) => {
                  return opt.toString() === newValue.toString();
                }
              );
              onSelectFilter(option);
              onChangeInputValue("");
              onChangeDateValue(null);
            }}
          />
          <TitleTypography>That</TitleTypography>
          <AppSelect
            fullWidth
            items={modeItems}
            value={selectedMode}
            onChange={(newValue: string | number) => {
              const modeOptions: ModeFilterOption[] =
                Object.values(ModeFilterOption);
              const option: ModeFilterOption = modeOptions.find(
                (opt: ModeFilterOption) => {
                  return opt.toString() === newValue.toString();
                }
              );
              onSelectMode(option);
              if (option === ModeFilterOption.IsEmpty) {
                onChangeInputValue("");
                onChangeDateValue(null);
              }
            }}
          />
          {selectedFilter !== InterviewsFilterOption.InterviewDate && (
            <AppInputText
              placeholder=""
              maxLength={100}
              value={inputValue}
              onChange={(newValue: string) => {
                onChangeInputValue(newValue);
              }}
              fullWidth
              disabled={selectedMode === ModeFilterOption.IsEmpty}
            />
          )}
          {selectedFilter === InterviewsFilterOption.InterviewDate && (
            <AppInputDatePicker
              id="input-date-picker-filter"
              placeholder="YYYY-MM-DD"
              minDate={new Date("2022-01-01T00:00:00")}
              maxDate={new Date("2030-12-31T00:00:00")}
              value={dateValue ? dateValue.toDate() : null}
              onChange={(date: Date) => {
                onChangeDateValue(moment(date));
              }}
              disabled={selectedMode === ModeFilterOption.IsEmpty}
            />
          )}
        </Stack>
      </Stack>
    </AppPopover>
  );
}
