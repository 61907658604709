import {
  useState,
  useCallback,
  useEffect,
  useRef,
  useReducer,
  useContext,
} from "react";
import { useParams } from "react-router";
import { Box, Stack, StackProps } from "@mui/material";
import { Theme, Interview } from "types/database";
import Typography from "@mui/material/Typography";
import ThemeBox from "./components/ThemeBox";
import { useLoaderThemes } from "api/loaders/themes";
import AppButton from "components/materials/actions/AppButton";
import PlusIcon from "components/icons/PlusIcon";
import RefreshIcon from "components/icons/RefreshIcon";
import CopyIcon from "components/icons/CopyIcon";
import AppInputText from "components/materials/forms/AppInputText";
import CrossIcon from "components/icons/CrossIcon";
import CheckIcon from "components/icons/CheckIcon";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import Dialog from "@mui/material/Dialog";
import { subscribe, unsubscribe } from "contexts/appEvents";
import { AppEventName } from "contexts/appEvents";
import { ToasterContext, Toaster } from "contexts/ToasterContext";
import { SynthesisContext } from "contexts/SynthesisContext";
import AppSortPopover from "components/synthesis/AppSortPopover";
import {
  ThemesSortOption,
  SortingFilteringContext,
  SortOption,
} from "contexts/SortingFilteringContext";
import { formatInterview } from "api/loaders/interviews";
import WarningIcon from "components/icons/WarningIcon";
import { styled } from "@mui/material/styles";

interface ThemesProps {
  onSelectTheme?(theme: Theme): void;
}

export default function Themes(props: ThemesProps) {
  //Props
  const { onSelectTheme } = props;
  //Hooks
  const {
    loadThemes,
    createTheme,
    updateTheme,
    deleteTheme,
    regenerateThemes,
  } = useLoaderThemes();
  const { caseid } = useParams();
  const { showToaster } = useContext(ToasterContext);
  const { themesSorting, changeThemesSorting } = useContext(
    SortingFilteringContext
  );
  const { isThemesRegenerating } = useContext(SynthesisContext);

  //States
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const [isCustomThemeEditing, setIsCustomThemeEditing] =
    useState<boolean>(false);
  const [isRegerationOpen, setIsRegerationOpen] = useState<boolean>(false);
  const [customThemeEditingId, setCustomThemeEditingId] =
    useState<number>(null);
  const [inputCustomTheme, setInputCustomTheme] = useState<string>("");
  const [generatedThemes, setGeneratedThemes] = useState<Theme[]>([]);
  const [customThemes, setCustomThemes] = useState<Theme[]>([]);
  const dataFetchedRef = useRef(false);
  const wrapperEl = useRef(null);
  const listEl = useRef(null);

  //Force Reducer
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  //Change Sorting
  const sortThemes = useCallback(
    (themes: Theme[], sorting: ThemesSortOption): Theme[] => {
      if (!themes) {
        return null;
      }
      let sortedThemes: Theme[] = themes;
      switch (sorting) {
        case ThemesSortOption.Frequency:
          sortedThemes = themes.sort((a, b) => {
            if (a.interviews_count > b.interviews_count) return -1;
            if (a.interviews_count < b.interviews_count) return 1;
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 1;
          });
          break;
        case ThemesSortOption.Alphabetical:
          sortedThemes = themes.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 1;
          });
          break;
        default:
          break;
      }
      return sortedThemes;
    },
    []
  );

  //Call Generated Themes Request
  const callGeneratedThemes = useCallback(
    async (caseId: number) => {
      const themes: Theme[] = await loadThemes(caseId, true);
      /*const filteredThemes: Theme[] = themes.filter((theme: Theme) => {
          return theme.interviews_count > 0;
        });*/
      setGeneratedThemes(sortThemes(themes, themesSorting));
    },
    [loadThemes, sortThemes, themesSorting]
  );

  //Call Custom Themes Request
  const callCustomThemes = useCallback(
    async (caseId: number) => {
      const themes: Theme[] = await loadThemes(caseId, false);
      setCustomThemes(sortThemes(themes, themesSorting));
    },
    [loadThemes, sortThemes, themesSorting]
  );

  //Call Custom Theme Creation Request
  const callCreateCustomTheme = async (caseId: number, name: string) => {
    const createdTheme: Theme = await createTheme(caseId, name);
    createdTheme.interviews_count = 0;
    customThemes.unshift({ ...createdTheme });
    forceUpdate();
  };
  //Call Custom Theme Update Request
  const callUpdateCustomTheme = async (id: number, name: string) => {
    const updatedTheme: Theme = await updateTheme(id, name);
    const index: number = customThemes.findIndex(
      (theme: Theme) => theme.id === id
    );
    if (index > -1) {
      customThemes[index] = { ...updatedTheme };
      forceUpdate();
    }
    setCustomThemeEditingId(null);
  };
  //Call Custom Theme Delete Request
  const callDeleteCustomTheme = async (id: number) => {
    const result: boolean = await deleteTheme(id);
    if (result) {
      const index: number = customThemes.findIndex(
        (theme: Theme) => theme.id === id
      );
      if (index > -1) {
        customThemes.splice(index, 1);
        forceUpdate();
      }
    }
    setCustomThemeEditingId(null);
    setIsModalDeleteOpen(false);
  };

  //Handle Add new Custom Theme
  const handleValidateCustomTheme = () => {
    if (customThemeEditingId) {
      callUpdateCustomTheme(customThemeEditingId, inputCustomTheme);
    } else {
      callCreateCustomTheme(parseInt(caseid), inputCustomTheme);
    }
    setInputCustomTheme("");
    setIsCustomThemeEditing(false);
  };

  //Handle Delete Custom Theme
  const handleDeleteCustomTheme = () => {
    callDeleteCustomTheme(customThemeEditingId);
  };

  //copyToClipboard
  const copyToClipboard = async () => {
    if (!navigator.clipboard) {
      const toaster: Toaster = {
        title: "Copy to clipboard",
        message: "Your browser doesn't have support for native clipboard",
        variant: "failure",
      };
      showToaster(toaster);
    } else {
      let copyValue: string = "";
      copyValue += "******************\n";
      copyValue += "Custom Takeaways:\n";
      copyValue += "******************\n";
      customThemes.forEach((theme: Theme) => {
        copyValue += `${theme.name}\n`;
        copyValue += `${theme.summaryText}\n`;
        copyValue += `------------------\n`;
      });
      if (customThemes.length === 0) {
        copyValue += `No Custom Takeaways\n`;
      }
      copyValue += "********************\n";
      copyValue += "Generated Takeaways:\n";
      copyValue += "********************\n";
      generatedThemes.forEach((theme: Theme) => {
        copyValue += `${theme.name}\n`;
        copyValue += `${theme.summaryText}\n`;
        copyValue += `------------------\n`;
      });
      await navigator.clipboard.writeText(copyValue);
      const toaster: Toaster = {
        title: "Copy to clipboard",
        message: "All takeaways were copied to your clipboard",
        variant: "success",
      };
      showToaster(toaster);
    }
  };

  //Fetch Themes Generated on Page Load
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    callGeneratedThemes(parseInt(caseid));
    callCustomThemes(parseInt(caseid));
  }, [callGeneratedThemes, callCustomThemes, caseid]);

  //Recalculate ref offset on window resize
  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
    return () => {
      window.removeEventListener("resize", forceUpdate);
    };
  });

  //Listen for Event on Theme ON_THEMES_CHANGED
  //(Caught by Notifications)
  useEffect(() => {
    const onThemeReady = (event: any) => {
      console.log("ON_THEMES_CHANGED - ", event.detail.case_id);
      if (
        event.detail.case_id &&
        parseInt(event.detail.case_id) === parseInt(caseid)
      ) {
        callGeneratedThemes(parseInt(event.detail.case_id));
        callCustomThemes(parseInt(event.detail.case_id));
      }
    };
    subscribe(AppEventName.ON_THEMES_CHANGED, onThemeReady);
    return () => {
      unsubscribe(AppEventName.ON_THEMES_CHANGED, onThemeReady);
    };
  }, [customThemes, callGeneratedThemes, callCustomThemes, caseid]);

  //Listen for Event on Theme ON_INTERVIEW_READY
  //(Caught by Notifications)
  useEffect(() => {
    const onInterviewReady = (event: any) => {
      const itw: Interview = formatInterview(event.detail.interview);
      if (itw.case.id === parseInt(caseid)) {
        callGeneratedThemes(parseInt(caseid));
        callCustomThemes(parseInt(caseid));
      }
    };
    subscribe(AppEventName.ON_INTERVIEW_READY, onInterviewReady);
    return () => {
      unsubscribe(AppEventName.ON_INTERVIEW_READY, onInterviewReady);
    };
  }, [callGeneratedThemes, callCustomThemes, caseid]);

  //Listen for Event on Theme ON_THEME_DELETED
  //(Caught by Notifications)
  useEffect(() => {
    const onThemeDeleted = (event: any) => {
      console.log("ON_THEME_DELETED - ", event.detail);
      if (event.detail.case_id === parseInt(caseid)) {
        //Raise toaster error to User
        if (event.detail.reason === "no corpus") {
          const toaster: Toaster = {
            variant: "failure",
            title: "Error",
            message: `No citations matching the Custom Takeaway was found`,
          };
          showToaster(toaster);
        }

        //Remove custom theme from the list
        const index: number = customThemes.findIndex(
          (item: Theme) => item.id === event.detail.theme_id
        );
        if (index > -1) {
          customThemes.splice(index, 1);
          forceUpdate();
        }
      }
    };
    subscribe(AppEventName.ON_THEME_DELETED, onThemeDeleted);
    return () => {
      unsubscribe(AppEventName.ON_THEME_DELETED, onThemeDeleted);
    };
  }, [
    callGeneratedThemes,
    callCustomThemes,
    caseid,
    showToaster,
    customThemes,
  ]);

  const renderHeader = () => {
    return (
      <Box marginLeft={16} paddingTop={23}>
        <Typography variant="h6">Takeaways</Typography>
        <Box
          component="div"
          width={60}
          height={2}
          marginTop={4}
          sx={{ backgroundColor: "#00E0B5" }}
        />
      </Box>
    );
  };

  const renderActions = () => {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={10}
          marginTop={12}
          marginBottom={14}
          marginLeft={16}
          marginRight={16}
        >
          <AppButton
            variant="link"
            color="primary"
            size="medium"
            label="Custom takeaway"
            startIcon={<PlusIcon />}
            onClick={() => {
              setIsCustomThemeEditing(true);
              setInputCustomTheme("");
              setCustomThemeEditingId(null);
            }}
          />
          <Stack direction="row" spacing={24}>
            <AppButton
              variant="link"
              color="primary"
              size="medium"
              label="Copy"
              startIcon={<CopyIcon />}
              onClick={copyToClipboard}
            />
            <AppSortPopover
              id="sort-themes-popover"
              selected={themesSorting}
              options={Object.values(ThemesSortOption)}
              onSelect={(option: SortOption) => {
                changeThemesSorting(option as ThemesSortOption);
                setGeneratedThemes(
                  sortThemes(generatedThemes, option as ThemesSortOption)
                );
                setCustomThemes(
                  sortThemes(customThemes, option as ThemesSortOption)
                );
              }}
            />
          </Stack>
        </Stack>
        <Box
          component="div"
          width="calc(100% - 32px)"
          height="1px"
          marginLeft={16}
          marginTop={0}
          marginBottom={15}
          sx={{ backgroundColor: "#E4E4E9" }}
        />
      </>
    );
  };

  const renderCustomThemeForm = () => {
    return (
      <Collapse in={isCustomThemeEditing} timeout={500}>
        <Fade in={isCustomThemeEditing} timeout={500}>
          <Stack
            direction="row"
            marginLeft={16}
            marginRight={16}
            paddingBottom={10}
            spacing={10}
            alignItems="center"
            justifyContent="space-between"
          >
            <AppInputText
              placeholder=""
              maxLength={100}
              value={inputCustomTheme}
              onChange={(newValue: string) => {
                setInputCustomTheme(newValue);
              }}
              fullWidth
            />
            <AppButton
              variant="circle"
              color="transparent"
              size="medium"
              icon={<CheckIcon />}
              disabled={!inputCustomTheme}
              onClick={() => {
                handleValidateCustomTheme();
              }}
            />
            <AppButton
              variant="circle"
              color="transparent"
              size="medium"
              icon={<CrossIcon />}
              onClick={() => {
                setInputCustomTheme("");
                setIsCustomThemeEditing(false);
              }}
            />
          </Stack>
        </Fade>
      </Collapse>
    );
  };

  const renderCustomThemes = () => {
    return (
      <>
        <Typography variant="h10" marginLeft={16}>
          Custom Takeaways
        </Typography>
        <Box paddingTop={5} paddingBottom={15}>
          {renderCustomThemeForm()}
          {!isCustomThemeEditing &&
            customThemes &&
            customThemes.length === 0 && (
              <Typography variant="body1" color="#A5A5A9" marginLeft={16}>
                No custom takeaways created yet
              </Typography>
            )}
          <Stack>
            {customThemes &&
              customThemes.map((theme: Theme, index: number) => {
                return (
                  <ThemeBox
                    key={index}
                    theme={theme}
                    onEdit={() => {
                      setCustomThemeEditingId(theme.id);
                      setInputCustomTheme(theme.name);
                      setIsCustomThemeEditing(true);
                    }}
                    onDelete={() => {
                      setCustomThemeEditingId(theme.id);
                      setIsModalDeleteOpen(true);
                    }}
                    onShowMentions={() => {
                      onSelectTheme(theme);
                    }}
                  />
                );
              })}
          </Stack>
        </Box>
        <Box
          component="div"
          width="calc(100% - 32px)"
          height="1px"
          marginLeft={16}
          marginTop={0}
          marginBottom={13}
          sx={{ backgroundColor: "#E4E4E9" }}
        />
      </>
    );
  };

  const renderGeneratedThemes = () => {
    return (
      <Box paddingBottom={20}>
        <Stack direction="row" spacing={5}>
          <Typography variant="h10" marginLeft={16} paddingTop={2}>
            ai generated
          </Typography>
          {true && (
            <Box
              sx={{
                paddingTop: 2,
                animation: isThemesRegenerating
                  ? `rotation 1.4s infinite linear`
                  : `none`,
                visibility: isRegerationOpen ? `hidden` : `block`,
              }}
            >
              <RefreshIcon
                htmlColor="#4F5767"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsRegerationOpen(true);
                }}
              />
            </Box>
          )}
        </Stack>

        {renderRegenerateThemes()}
        <Stack>
          {generatedThemes &&
            generatedThemes.map((theme: Theme, index: number) => {
              return (
                <ThemeBox
                  key={index}
                  theme={theme}
                  onShowMentions={() => {
                    onSelectTheme(theme);
                  }}
                />
              );
            })}
        </Stack>
      </Box>
    );
  };

  const renderRegenerateThemes = () => {
    const ContentStack = styled(Stack)<StackProps>(() => ({
      ul: {
        marginLeft: 0,
        paddingLeft: 14,
      },
      li: {
        fontSize: "1.2rem",
        lineHeight: "1.6rem",
        color: "#67676B",
      },
    }));

    return (
      <Collapse in={isRegerationOpen} timeout={500}>
        <Fade in={isRegerationOpen} timeout={500}>
          <Stack
            direction="row"
            spacing={10}
            alignItems="flex-start"
            padding={16}
            marginTop={4}
            sx={{ backgroundColor: "#FFF8F0" }}
          >
            <WarningIcon />
            <ContentStack>
              <Typography variant="body1">
                <strong>All users will be impacted by this change.</strong>
              </Typography>
              <ul>
                <li>
                  Once new themes are generated, the existing ones will be lost
                  and cannot be recovered.
                </li>
                <li>This action takes several minutes to complete.</li>
              </ul>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={16}
              >
                <AppButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  label="Cancel"
                  onClick={() => {
                    setIsRegerationOpen(false);
                  }}
                />
                <AppButton
                  variant="contained"
                  color="primary"
                  size="small"
                  label="Regenerate"
                  startIcon={<RefreshIcon />}
                  onClick={() => {
                    regenerateThemes(parseInt(caseid));
                    setIsRegerationOpen(false);
                  }}
                />
              </Stack>
            </ContentStack>
          </Stack>
        </Fade>
      </Collapse>
    );
  };

  const renderDeleteThemeDialog = () => {
    return (
      <Dialog
        open={isModalDeleteOpen}
        onClose={() => {
          setCustomThemeEditingId(null);
          setIsModalDeleteOpen(false);
        }}
        aria-labelledby="modal-delete-theme"
        aria-describedby="modal-delete-theme"
        closeAfterTransition
      >
        <Stack
          sx={{
            padding: "24px 40px",
          }}
          spacing={24}
        >
          <Typography variant="h3" fontSize="2rem">
            Delete Takeaway?
          </Typography>
          <Typography variant="body1" fontSize="1.6rem">
            Are you sure you want to delete this takeaways?
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={40}
          >
            <AppButton
              variant="link"
              color="primary"
              size="medium"
              label="No, cancel"
              onClick={() => {
                setCustomThemeEditingId(null);
                setIsModalDeleteOpen(false);
              }}
            />
            <AppButton
              variant="contained"
              color="primary"
              size="medium"
              label="Yes, Delete Takeaway"
              onClick={() => {
                handleDeleteCustomTheme();
              }}
            />
          </Stack>
        </Stack>
      </Dialog>
    );
  };

  return (
    <>
      {renderDeleteThemeDialog()}
      <Box ref={wrapperEl} sx={{ height: "100%" }}>
        {renderHeader()}
        {renderActions()}
        <Box
          ref={listEl}
          sx={{
            maxHeight: `${
              listEl.current && wrapperEl.current
                ? wrapperEl.current.offsetHeight - listEl.current.offsetTop
                : 0
            }px`,
            overflowY: "auto",
          }}
        >
          {renderCustomThemes()}
          {renderGeneratedThemes()}
        </Box>
      </Box>
    </>
  );
}
