import { useRef, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import loader from "assets/images/loader.png";
import Fade from "@mui/material/Fade";
import { Interview } from "types/database";
import { RawCorpus, TegusStep } from "types/serializers";
import Typography from "@mui/material/Typography";

export interface PasteProps {
  loading: boolean;
  interview: Interview;
  rawCorpus: RawCorpus;
  message: string;
  onFileSelected(file: File): void;
}

export default function Paste(props: PasteProps) {
  //Props
  const { loading, rawCorpus, message, onFileSelected } = props;
  const drop = useRef(null);

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("handleDragOver");
  };

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      console.log("handleDrop");

      const { files } = e.dataTransfer;

      if (files && files.length) {
        onFileSelected(files[0]);
      }
    },
    [onFileSelected]
  );

  useEffect(() => {
    const dropCurrent: any = drop.current;
    dropCurrent.addEventListener("dragover", handleDragOver);
    dropCurrent.addEventListener("drop", handleDrop);
    return () => {
      dropCurrent.removeEventListener("dragover", handleDragOver);
      dropCurrent.removeEventListener("drop", handleDrop);
    };
  }, [handleDrop]);

  return (
    <Box
      ref={drop}
      sx={{
        margin: "28px 32px 24px 72px",
        height: "calc(100% - 52px)",
        overflow: "auto",
        background: "#fff",
        width: "100%",
        position: "relative",
        border: "1px solid #E4E4E9",
        borderRadius: "4px",
      }}
    >
      {loading && (
        <Fade
          in={loading}
          timeout={{
            enter: 600,
            exit: 600,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              zIndex: 100,
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Box sx={{ animation: "rotation 1.4s infinite linear" }}>
              <img src={loader} alt="loader" />
            </Box>
          </Box>
        </Fade>
      )}
      <Box
        sx={{
          padding: 12,
          lineHeight: "2.8rem",
        }}
      >
        {!loading &&
          rawCorpus &&
          rawCorpus.conversation.map((step: TegusStep, index: number) => {
            return (
              <Typography key={index} variant="body2" gutterBottom>
                {step.speaker}: {step.text}
              </Typography>
            );
          })}
        {!loading && !rawCorpus && ``}
        {message}
      </Box>
    </Box>
  );
}
