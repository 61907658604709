/* Todo : Update colors based on App Design */
export const themeCommonSettings = {
  spacing: (factor: number) => `${0.1 * factor}rem`,
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#232326",
          fontFamily: "BCGHendersonSans",
          fontSize: "1.4rem",
          fontWeight: 400,
          lineHeight: "1.4rem",
          letterSpacing: "inherit",
          textAlign: "left",
          padding: 0,
          margin: 0,
        },
        body1: {
          color: "#232326",
          fontFamily: "BCGHendersonSans",
          fontSize: "1.2rem",
          fontWeight: 400,
          lineHeight: "1.9rem",
          letterSpacing: "inherit",
          textAlign: "left",
          padding: 0,
          margin: 0,
        },
        body2: {
          color: "#232326",
          fontFamily: "BCGHendersonSans",
          fontSize: "1.4rem",
          fontWeight: 400,
          lineHeight: "2.3rem",
          letterSpacing: "inherit",
          textAlign: "left",
          padding: 0,
          margin: 0,
          "&.MuiTypography-gutterBottom": {
            margin: "0rem 0rem 3.2rem 0rem",
          },
        },
        body3: {
          color: "#ff0000",
        },
        h1: {
          color: "#232326",
          fontFamily: "BCGHendersonMod",
          fontSize: "3.2rem",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "3.2rem",
          lletterSpacing: 0,
          textAlign: "left",
          "&.MuiTypography-gutterBottom": {
            padding: "0rem 0rem 1.6rem 0rem",
            margin: "3.8rem 0rem 3.2rem 0rem",
            borderBottom: "1px solid #9A9A9A",
          },
        },
        h2: {
          color: "#232326",
          fontFamily: "BCGHendersonMod",
          fontSize: "3.2rem",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "2.4rem",
          letterSpacing: 0,
          textAlign: "left",
          padding: 0,
          "&.MuiTypography-gutterBottom": {
            marginBottom: "0.8rem",
          },
          "&.underlined": {
            marginTop: 5,
          },
        },
        h3: {
          color: "#232326",
          fontFamily: "BCGHendersonMod",
          fontSize: "2.4rem",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "3.9rem",
          letterSpacing: 0,
          textAlign: "left",
          padding: 0,
          "&.MuiTypography-gutterBottom": {
            marginBottom: "1.6rem",
          },
        },
        h4: {
          color: "#000",
          fontFamily: "BCGHendersonMod",
          fontSize: "1.8rem",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "1.8rem",
          letterSpacing: 0,
          textAlign: "left",
          padding: 0,
          "&.MuiTypography-gutterBottom": {
            marginBottom: "0.8rem",
          },
        },
        h5: {
          color: "#000",
          fontFamily: "BCGHendersonMod",
          fontSize: "1.8rem",
          fontStyle: "bold",
          fontWeight: 700,
          lineHeight: "1.8rem",
          letterSpacing: 0,
          textAlign: "left",
          padding: 0,
          "&.MuiTypography-gutterBottom": {
            marginBottom: "1.6rem",
          },
        },
        h6: {
          color: "#232326",
          fontFamily: "BCGHendersonMod",
          fontSize: "1.6rem",
          fontStyle: "bold",
          fontWeight: 700,
          lineHeight: "2.6rem",
          letterSpacing: 0,
          textAlign: "left",
          padding: 0,
          "&.MuiTypography-gutterBottom": {
            marginBottom: "1.6rem",
          },
        },
        h8: {
          color: "#67676B",
          fontFamily: "BCGHendersonSans",
          fontSize: "1.2rem",
          fontWeight: 700,
          lineHeight: "1.9rem",
          letterSpacing: "0.1em",
          textAlign: "left",
          padding: 0,
          textTransform: "uppercase",
        },
        h10: {
          color: "#232326",
          fontFamily: "BCGHendersonSans",
          fontSize: "1.05rem",
          fontWeight: 700,
          lineHeight: "1.6rem",
          letterSpacing: "0.1em",
          textAlign: "left",
          padding: 0,
          textTransform: "uppercase",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#9A9A9A",
        },
        light: {
          borderColor: "#D9D9D9",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: "inherit",
          height: "inherit",
          fontSize: "1.6rem",
        },
      },
    },
  },
};
