import { useContext } from "react";
import { useApiThemes } from "api/requests/themes";
import { ToasterContext, Toaster } from "contexts/ToasterContext";
import { Theme, Case } from "types/database";
import moment from "moment";
import { formatInterviews } from "./interviews";
import { formatCase } from "./cases";

const formatThemes = (themes: any[]): Theme[] => {
  const formattedThemes: Theme[] = themes.map((theme: any) => {
    return formatTheme(theme);
  });
  return formattedThemes;
};

export const formatTheme = (theme: any): Theme => {
  const formattedTheme: Theme = {
    ...theme,
    caseId: theme.case_id,
    createdAt: theme.created_at ? moment(theme.created_at) : null,
    themeDate: theme.theme_date ? moment(theme.theme_date) : null,
    summaryText: theme.summary_text,
    summaryBulleted: theme.summary_bulleted,
    interviews: theme.interviews ? formatInterviews(theme.interviews) : [],
  };
  return formattedTheme;
};

export function useLoaderThemes() {
  //Hooks
  const apiThemes = useApiThemes();
  const { showToaster } = useContext(ToasterContext);

  const loadTheme = async (id: number): Promise<Theme> => {
    return apiThemes
      .getTheme(id)
      .then((theme: any) => {
        return formatTheme(theme);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Takeaways fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadThemes = async (
    caseId: number,
    generated?: boolean,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<Theme[]> => {
    return apiThemes
      .getThemes(caseId, generated, orderBy, orderDir, limit, offset)
      .then((themes: any[]) => {
        const formattedThemes: Theme[] = formatThemes(themes);
        return formattedThemes;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Takeaways fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadThemesCount = async (
    caseId: number,
    generated: boolean
  ): Promise<number> => {
    return apiThemes
      .getThemesCount(caseId, generated)
      .then((count: number) => {
        return count;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Takeaways Count fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const createTheme = async (caseId: number, name: string): Promise<Theme> => {
    return apiThemes
      .createTheme(caseId, name)
      .then((created: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Takeaway has been saved successfully",
        };
        showToaster(toaster);
        return formatTheme(created);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Takeaway saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const updateTheme = async (id: number, name: string): Promise<Theme> => {
    return apiThemes
      .updateTheme(id, name)
      .then((updated: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Takeaway has been saved successfully",
        };
        showToaster(toaster);
        return formatTheme(updated);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Takeaway saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const deleteTheme = async (id: number): Promise<boolean> => {
    return apiThemes
      .deleteTheme(id)
      .then((result: boolean) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Takeaway has been successfully deleted ",
        };
        showToaster(toaster);
        return result;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Takeaway deletion",
        };
        showToaster(toaster);
        return null;
      });
  };

  const excludeTheme = async (id: number): Promise<Theme> => {
    return apiThemes
      .excludeTheme(id)
      .then((updated: Theme) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Takeaway has been excluded successfully",
        };
        showToaster(toaster);
        return formatTheme(updated);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Takeaway saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const includeTheme = async (id: number): Promise<Theme> => {
    return apiThemes
      .includeTheme(id)
      .then((updated: Theme) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Takeaway has been included successfully",
        };
        showToaster(toaster);
        return formatTheme(updated);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Takeaway saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const regenerateThemes = async (caseId: number): Promise<Case> => {
    return apiThemes
      .regenerateThemes(caseId)
      .then((c: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message:
            "Request sent successfully. Sensei is now re-generating takeways which may take a few minutes.",
        };
        showToaster(toaster);
        return formatCase(c);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Request sending",
        };
        showToaster(toaster);
        return null;
      });
  };

  return {
    loadTheme: loadTheme,
    loadThemes: loadThemes,
    loadThemesCount: loadThemesCount,
    createTheme,
    updateTheme,
    deleteTheme,
    excludeTheme: excludeTheme,
    includeTheme: includeTheme,
    regenerateThemes: regenerateThemes,
  };
}
