import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
//import "./assets/fonts/BCG Henderson Mod/BCG Henderson Mod-Regular.otf";
import "./assets/fonts/BCG Henderson Sans/BCGHenSansBold.ttf";
import "./assets/fonts/BCG Henderson Sans/BCGHenSansRegular.ttf";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
