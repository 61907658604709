import { Box } from "@mui/material";
import AppInputText from "components/materials/forms/AppInputText";
import AppDatePicker from "components/materials/forms/AppDatePicker/AppDatePicker";
import AppPopover from "components/materials/communication/AppPopover";
import moment from "moment";
import DateIcon from "components/icons/DateIcon";

export interface AppInputDatePickerProps {
  id: string;
  placeholder: string;
  minDate: Date;
  maxDate: Date;
  label?: string;
  value: Date;
  disabled?: boolean;
  onChange(value: Date): void;
}

export default function AppInputDatePicker(props: AppInputDatePickerProps) {
  //Props
  const {
    id,
    label,
    placeholder,
    minDate,
    maxDate,
    value,
    disabled,
    onChange,
  } = props;

  return (
    <AppPopover
      id={`${id}-popover`}
      label={
        <AppInputText
          fullWidth
          label={label}
          placeholder={placeholder}
          maxLength={10}
          value={value ? moment(value).format("MM / DD / YYYY") : ""}
          endIcon={<DateIcon />}
          onChange={() => {}}
          sx={{ cursor: "pointer" }}
          disabled={disabled}
        />
      }
      variant="text"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      buttonSx={{
        cursor: "default",
        width: "100%",
      }}
      disabled={disabled}
    >
      <Box sx={{ padding: 10, width: 268 }}>
        <AppDatePicker
          id={`${id}-picker`}
          value={value}
          onChange={(date: Date) => {
            onChange(date);
          }}
          inline
          minDate={minDate}
          maxDate={maxDate}
        />
      </Box>
    </AppPopover>
  );
}
