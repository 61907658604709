import { useContext, useState, useCallback, useEffect, useRef } from "react";
import { Box, BoxProps, Grid, GridProps, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import SendIcon from "components/icons/SendIcon";
import AppButton from "components/materials/actions/AppButton";
import ExpandIcon from "components/icons/ExpandIcon";
import AppTransparentInputText from "components/materials/forms/AppTransparentInputText";
import AppAvatar from "components/materials/content/AppAvatar";
import bcgxAvatar from "assets/images/bcgx-avatar.svg";
import { useLoaderChat } from "api/loaders/chat";
import { SynthesisContext } from "contexts/SynthesisContext";
import { ConversationContext } from "contexts/ConversationContext";
import { Chat, SuggestedQuery } from "types/database";
import { useParams } from "react-router";
import Fade from "@mui/material/Fade";
import moment from "moment";
import { timeout } from "api/utils/helpers";
import { ThreeDots } from "react-loader-spinner";
import CrossIcon from "components/icons/CrossIcon";
import { subscribe, unsubscribe } from "contexts/appEvents";
import { AppEventName } from "contexts/appEvents";
import { ToasterContext, Toaster } from "contexts/ToasterContext";

const UserMsgGridItem = styled(Grid)<GridProps>(() => ({
  display: "flex",
  justifyContent: "flex-end",
  "& .MuiBox-root": {
    color: "#fff",
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    background: "#232326",
    borderRadius: "24px",
    padding: "8px 24px",
    maxWidth: "40%",
    width: "fit-content",
    marginRight: 24,
  },
}));

const LLMMsgGridItem = styled(Grid)<GridProps>(() => ({
  display: "flex",
  justifyContent: "flex-start",
  "& div": {
    color: "#232326",
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    background: "#F7F7F8",
    borderRadius: "24px",
    padding: "8px 24px",
    maxWidth: "40%",
    width: "fit-content",
    marginLeft: 24,
  },
}));

interface WrapperBoxProps extends BoxProps {
  size?: "large" | "medium" | "small" | "tiny";
}

const WrapperBox = styled(Box)<WrapperBoxProps>(({ size }) => {
  let left: string = "0%";
  let width: string = "100%";
  switch (size) {
    case "medium":
      left = "20%";
      width = "80%";
      break;
    case "small":
      left = "40%";
      width = "60%";
      break;
    case "tiny":
      left = "50%";
      width = "50%";
      break;
  }

  return {
    position: "absolute",
    zIndex: 6,
    top: 0,
    left: left,
    width: width,
    height: "100%",
    background: "#fff",
    padding: "0px 0px 32px 24px",
    transition: "width 0.5s, left 0.5s",
    borderLeft: "1px solid #E4E4E9",
  };
});

const SuggestedQueryBox = styled(Box)<BoxProps>(() => {
  return {
    border: "1px solid #A5A5A9",
    padding: "7px 16px",
    textAlign: "center",
    fontSize: "1.2rem",
    color: "#232326",
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
    borderRadius: "44px",
    cursor: "pointer",
    marginBottom: 8,
    "&:hover": {
      border: "1px solid #00E0B5",
    },
  };
});

export default function QueriesPanel() {
  //Hooks
  const { isInterviewsPanelOpen, isThemesPanelOpen } =
    useContext(SynthesisContext);
  const { activeConversationId } = useContext(ConversationContext);
  const {
    loadConversation,
    sendMessage,
    addAbortMessage,
    loadSuggestedQueries,
  } = useLoaderChat();
  const { caseid } = useParams();
  const { showToaster } = useContext(ToasterContext);
  //States
  const [messages, setMessages] = useState<Chat[]>(null);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSuggestedQueries, setIsLoadingSuggestedQueries] =
    useState<boolean>(false);
  const [isSuggestedQueriesVisible, setIsSuggestedQueriesVisible] =
    useState<boolean>(false);
  const [suggestedQueries, setSuggestedQueries] =
    useState<SuggestedQuery[]>(null);
  const dataFetchedRef = useRef(false);
  const wrapperEl = useRef(null);
  const listEl = useRef(null);
  let timeoutChat = useRef(null);
  //const [timeoutChat, setTimeoutChat] = useState<any>(null);

  //Call Suggested Queries Request
  const callSuggestedQueries = useCallback(async () => {
    setIsLoadingSuggestedQueries(true);
    const results: SuggestedQuery[] = await loadSuggestedQueries(
      parseInt(caseid)
    );
    setSuggestedQueries(results);
    if (results) {
      setIsSuggestedQueriesVisible(true);
      setIsLoadingSuggestedQueries(false);
      await timeout(100);
      listEl.current.scrollTo({
        top: listEl.current.scrollHeight,
        behavior: "smooth",
      });
    }
    setIsLoadingSuggestedQueries(false);
  }, [loadSuggestedQueries, caseid]);

  //Call Conversation Request
  const callConversation = useCallback(
    async (conversationId: string) => {
      setIsLoading(true);
      const results: Chat[] = await loadConversation(conversationId);
      setMessages(results);
      setIsLoading(false);
      await timeout(100);
      listEl.current.scrollTo({
        top: listEl.current.scrollHeight,
        behavior: "smooth",
      });
    },
    [loadConversation]
  );

  //Call Conversation Request
  const callSendMessage = useCallback(
    async (caseId: number, conversationId: string, message: string) => {
      const question: Chat = {
        id: Date.now(),
        createdAt: moment(),
        text: message,
        generated: false,
      };
      messages.push(question);
      setInputMessage("");
      setIsLoading(true);
      await timeout(10);
      listEl.current.scrollTo({
        top: listEl.current.scrollHeight,
        behavior: "smooth",
      });
      const messageId: number = Date.now();
      //listEl.current.scrollTop = listEl.current.scrollHeight;,
      clearTimeout(timeoutChat.current);
      timeoutChat.current = setTimeout(() => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "Timeout: no response received from OpenAI",
        };
        showToaster(toaster);
        addAbortMessage(messageId);
        setIsLoading(false);
        clearTimeout(timeoutChat.current);
      }, 40000);

      const response: any = await sendMessage(
        messageId,
        caseId,
        conversationId,
        message
      );
      if (response) {
        clearTimeout(timeoutChat.current);
        callConversation(conversationId);
      }
    },
    [
      sendMessage,
      callConversation,
      messages,
      addAbortMessage,
      showToaster,
      timeoutChat,
    ]
  );

  const handleSend = () => {
    callSendMessage(parseInt(caseid), activeConversationId, inputMessage);
  };

  const handleSuggestedQueryClick = (query: string) => {
    callSendMessage(parseInt(caseid), activeConversationId, query);
    setIsSuggestedQueriesVisible(false);
    setSuggestedQueries(null);
  };

  //Fetch Conversation on Page Load
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    callConversation(activeConversationId);
  }, [callConversation, activeConversationId]);

  //Listen for App Events
  useEffect(() => {
    const handleWebsocketDisconnet = (event: any) => {
      console.log("ON_WEBSOCKET_DISCONNECT - QueriesPanel");
      callConversation(activeConversationId);
    };

    subscribe(AppEventName.ON_WEBSOCKET_DISCONNECT, handleWebsocketDisconnet);

    return () => {
      unsubscribe(
        AppEventName.ON_WEBSOCKET_DISCONNECT,
        handleWebsocketDisconnet
      );
    };
  }, [callConversation, activeConversationId]);

  //define size of panel
  let size: "large" | "medium" | "small" | "tiny" = "large";
  if (isInterviewsPanelOpen && isThemesPanelOpen) {
    size = "small";
  } else if (!isInterviewsPanelOpen && !isThemesPanelOpen) {
    size = "large";
  } else {
    size = "medium";
  }

  const renderConversation = () => {
    return (
      <Box sx={{ paddingTop: 24, paddingRight: 24 }}>
        {messages &&
          messages.map((message: Chat, index: number) => {
            if (message.generated) {
              return (
                <Grid container key={index}>
                  <Fade in={true} timeout={500}>
                    <LLMMsgGridItem item xs={12}>
                      <Box
                        sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                      >
                        {message.text}
                      </Box>
                    </LLMMsgGridItem>
                  </Fade>
                  <LLMMsgGridItem item xs={12}>
                    <img src={bcgxAvatar} alt="BCG" />
                  </LLMMsgGridItem>
                </Grid>
              );
            } else {
              return (
                <Fade in={true} timeout={500} key={index}>
                  <Grid container>
                    <UserMsgGridItem item xs={12}>
                      <Box
                        sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                      >
                        {message.text}
                      </Box>
                    </UserMsgGridItem>
                    <UserMsgGridItem item xs={12}>
                      <AppAvatar name="John Doe" variant="dark" />
                    </UserMsgGridItem>
                  </Grid>
                </Fade>
              );
            }
          })}
      </Box>
    );
  };

  const renderLoading = () => {
    return (
      <Fade in={true} timeout={500}>
        <Grid container>
          <LLMMsgGridItem item xs={12}>
            <ThreeDots
              height="24"
              width="24"
              radius="9"
              color="#67676B"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </LLMMsgGridItem>

          <LLMMsgGridItem item xs={12}>
            <img src={bcgxAvatar} alt="BCG" />
          </LLMMsgGridItem>
        </Grid>
      </Fade>
    );
  };

  const renderInput = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        textAlign="center"
        sx={{
          padding: "10px 14px",
          fontSize: "1.6rem",
          background: "#F7F7F8",
          boxShadow: "0px 1px 1px rgba(35, 35, 38, 0.16)",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            lineHeight: "3.4rem",
            flexGrow: 1,
            marginRight: 20,
          }}
        >
          <AppTransparentInputText
            placeholder="Ask any question"
            maxLength={100}
            fullWidth
            value={inputMessage}
            onChange={(newValue: string) => {
              setInputMessage(newValue);
            }}
            onKeyPress={(event: React.KeyboardEvent) => {
              if (event.key === "Enter" && !isLoading && inputMessage?.trim()) {
                handleSend();
              }
            }}
          />
        </Box>
        <AppButton
          sx={{ marginTop: 2 }}
          variant="circle"
          color="transparent"
          size="medium"
          icon={<SendIcon />}
          onClick={handleSend}
          disabled={isLoading || !inputMessage?.trim()}
        />
      </Stack>
    );
  };

  const renderSuggestedQueries = () => {
    return (
      <Fade in={true} timeout={500}>
        <Box>
          {suggestedQueries &&
            suggestedQueries.map((query: SuggestedQuery, index: number) => {
              return (
                <SuggestedQueryBox
                  key={index}
                  onClick={() => {
                    handleSuggestedQueryClick(query.query);
                  }}
                >
                  {query.query}
                </SuggestedQueryBox>
              );
            })}
        </Box>
      </Fade>
    );
  };

  const renderSuggestedQueriesButton = () => {
    return (
      <Stack justifyContent="space-between" direction="row">
        <Box />
        {!isLoadingSuggestedQueries && !isSuggestedQueriesVisible && (
          <AppButton
            variant="link"
            color="primary"
            size="medium"
            label="Suggested queries"
            endIcon={<ExpandIcon />}
            onClick={() => {
              callSuggestedQueries();
            }}
            disabled={isLoading}
          />
        )}
        {(isLoadingSuggestedQueries || isSuggestedQueriesVisible) && (
          <AppButton
            variant="link"
            color="primary"
            size="medium"
            label="Hide"
            endIcon={<CrossIcon />}
            onClick={() => {
              setIsSuggestedQueriesVisible(false);
              setSuggestedQueries(null);
            }}
            disabled={isLoadingSuggestedQueries}
          />
        )}
      </Stack>
    );
  };

  return (
    <WrapperBox size={size} ref={wrapperEl}>
      <Stack sx={{ height: "100%" }} justifyContent="space-between">
        <Box
          ref={listEl}
          sx={{
            maxHeight: `${
              listEl.current && wrapperEl.current
                ? wrapperEl.current.offsetHeight - listEl.current.offsetTop
                : 0
            }px`,
            overflowY: "auto",
          }}
        >
          {renderConversation()}
          {messages && isLoading && renderLoading()}
          {!isLoadingSuggestedQueries &&
            isSuggestedQueriesVisible &&
            renderSuggestedQueries()}
        </Box>

        <Stack spacing={20} marginTop={20} marginRight={24}>
          {renderSuggestedQueriesButton()}
          {renderInput()}
        </Stack>
      </Stack>
    </WrapperBox>
  );
}
