import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Stack, Box, BoxProps } from "@mui/material";
import InfoIcon from "components/icons/InfoIcon";
import AppButton from "../actions/AppButton";
import RefreshIcon from "components/icons/RefreshIcon";
import { AppTooltip } from "../communication/AppTooltip";
import { SxProps } from "@mui/system";
import { Required } from "./Required";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "@mui/base/TextareaAutosize";

export interface AppTextareaAutosizeProps {
  placeholder: string;
  maxLength: number;
  label?: string;
  tooltip?: JSX.Element | string | number;
  disabled?: boolean;
  fullWidth?: boolean;
  sx?: SxProps;
  value?: string;
  resetEnabled?: boolean;
  resetValue?: string;
  endIcon?: JSX.Element;
  error?: boolean;
  textError?: string;
  required?: boolean;
  onChange(value: string): void;
}

interface CustomTextareaAutosizeProps extends TextareaAutosizeProps {
  width?: string;
}
const StyledTextareaAutosize = styled(
  TextareaAutosize
)<CustomTextareaAutosizeProps>(({ width }) => ({
  width: width,
  fontFamily: "BCGHendersonSans",
  fontSize: "1.4rem",
  lineHeight: "2.3rem",
  color: "#232326",
  border: "none",
  padding: 0,
  "&:disabled": {
    color: "rgba(0, 0, 0, 0.26)",
    opacity: 0.5,
    background: "#fff",
  },
}));

const StyledLabelBox = styled(Box)<BoxProps>(() => ({
  fontFamily: "BCGHendersonMod",
  fontSize: "1.4rem",
  fontWeight: 700,
  lineHeight: "1.4rem",
}));

const StyledBoxError = styled(Box)<BoxProps>(() => ({
  color: "#F14D19",
  fontSize: "1.1rem",
  lineHeight: "110%",
}));

export default function AppTextareaAutosize(props: AppTextareaAutosizeProps) {
  const {
    label,
    tooltip,
    disabled,
    fullWidth,
    sx,
    value,
    resetEnabled,
    resetValue,
    textError,
    required,
    onChange,
  } = props;

  const [inputResetValue, setInputResetValue] = useState<string>(undefined);

  let additionalStyles: any = { ...sx };
  let width: string = "24rem";
  if (additionalStyles.hasOwnProperty("width")) {
    width = additionalStyles["width"];
  }
  if (!fullWidth) {
    additionalStyles = { width: width, ...additionalStyles };
  } else {
    width = "100%";
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.stopPropagation();
    let output = event.target.value;
    onChange(output);
  };

  const handleReset = () => {
    onChange(inputResetValue);
  };

  if (
    label &&
    resetEnabled &&
    (resetValue || value !== undefined) &&
    inputResetValue === undefined
  ) {
    setInputResetValue(resetValue || value);
  }

  return (
    <Stack
      direction="column"
      alignItems="left"
      spacing={12}
      style={{ width: width }}
    >
      {label && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={6}
        >
          <Stack direction="row" alignItems="center" spacing={6}>
            <StyledLabelBox>
              {label} {required && <Required />}
            </StyledLabelBox>
            {tooltip && (
              <AppTooltip content={tooltip}>
                <InfoIcon sx={{ color: "#1D4659" }} />
              </AppTooltip>
            )}
          </Stack>
          {resetEnabled && (
            <AppButton
              variant="icon"
              disabled={disabled}
              onClick={handleReset}
              icon={<RefreshIcon />}
            />
          )}
        </Stack>
      )}
      <Stack spacing={4}>
        <Box
          sx={{
            padding: "8px 14px 7px",
            border: "1px solid #E4E4E9",
            borderRadius: "4px",
            backgroundColor: "#fff",
          }}
        >
          <StyledTextareaAutosize
            aria-label="empty textarea"
            placeholder="Empty"
            value={value}
            onChange={handleChange}
            disabled={disabled}
            width={width}
          />
        </Box>
        {textError && <StyledBoxError>{textError}</StyledBoxError>}
      </Stack>
    </Stack>
  );
}
