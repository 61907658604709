import { Stack, Typography, Box } from "@mui/material";
import { Interview, TaskStatus } from "types/database";
import loader from "assets/images/loader.png";
import TrashOutlinedIcon from "components/icons/TrashOutlinedIcon";
import AppButton from "components/materials/actions/AppButton";

export interface QueueItemProps {
  interview: Interview;
  onDelete(): void;
}

export default function QueueItem(props: QueueItemProps) {
  //Props
  const { interview, onDelete } = props;

  const imgSrc: any = require(`assets/images/file-${interview.sourceFileType}-icon.png`);

  return (
    <Stack
      sx={{
        background: "rgba(73, 40, 255, 0.05)",
        padding: "14px 16px",
        borderRadius: "4px",
      }}
    >
      <Stack direction="row" spacing={8} alignItems="top">
        <Box>
          <img src={imgSrc} alt={interview.sourceFileType} width={16} />
        </Box>
        <Typography variant="body1">
          {interview.title || "Untitled Interview"}
        </Typography>
      </Stack>
      {interview.loadTask.status === TaskStatus.RUNNING && (
        <Stack
          spacing={8}
          direction="row"
          marginTop={10}
          justifyContent="space-between"
        >
          <Stack spacing={8} direction="row">
            <Box>
              <Box sx={{ animation: "rotation 1.4s infinite linear" }}>
                <img src={loader} alt="loader" width={16} />
              </Box>
            </Box>
            <Typography variant="body1">Uploading</Typography>
          </Stack>
          <AppButton
            variant="link"
            color="grey-red"
            size="medium"
            label=""
            startIcon={<TrashOutlinedIcon />}
            onClick={onDelete}
          />
        </Stack>
      )}
      {interview.loadTask.status === TaskStatus.PENDING && (
        <Stack
          spacing={24}
          direction="row"
          marginTop={10}
          justifyContent="space-between"
        >
          <Typography variant="body1" marginLeft={24}>
            Queued
          </Typography>
          <AppButton
            variant="link"
            color="grey-red"
            size="medium"
            label=""
            startIcon={<TrashOutlinedIcon />}
            onClick={onDelete}
          />
        </Stack>
      )}
    </Stack>
  );
}
//
