import { useRef, useEffect, useCallback } from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Chunk, Interview } from "types/database";
import { RawCorpus, TegusStep } from "types/serializers";
import { timeout } from "api/utils/helpers";

export interface SummaryProps {
  interview: Interview;
  rawCorpus: RawCorpus;
  citation?: Chunk;
}

const InfoTypography = styled(Typography)<TypographyProps>(() => ({
  color: "#67676B",
}));

const ExcludedBox = styled(Box)<BoxProps>(() => ({
  color: "#E20909",
  fontSize: "1.1rem",
  fontWeight: 700,
  letterSpacing: 1.1,
  textTransform: "uppercase",
  background: "#FDF2F1",
  borderRadius: 4,
  padding: "3px 4px",
}));

const IncludeBox = styled(Box)<BoxProps>(() => ({
  color: "#21BF61",
  fontSize: "1.1rem",
  fontWeight: 700,
  letterSpacing: 1.1,
  textTransform: "uppercase",
  background: "#F2FBF6",
  borderRadius: 4,
  padding: "3px 4px",
}));

const SubtitleTypography = styled(Typography)<TypographyProps>(() => ({
  color: "#232326",
  fontSize: "1.1rem",
  fontWeight: 700,
  letterSpacing: 1.1,
  textTransform: "uppercase",
}));

export default function Summary(props: SummaryProps) {
  //Props
  const { interview, rawCorpus, citation } = props;
  const wrapperEl = useRef(null);
  const highlightedCitationEl = useRef(null);
  const scrollTopRef = useRef(false);

  const call = useCallback(async (): Promise<any> => {
    await timeout(100);
    wrapperEl.current.scrollTop = highlightedCitationEl.current?.offsetTop - 20;
  }, []);

  useEffect(() => {
    if (scrollTopRef.current) return;
    scrollTopRef.current = true;
    call();
  }, [call]);

  return (
    <Box
      sx={{
        padding: "28px 32px 24px 72px",
        height: "100%",
        overflow: "auto",
        background: "#fff",
      }}
      ref={wrapperEl}
    >
      <Box sx={{ marginBottom: 36 }}>
        {interview?.exclude && (
          <ExcludedBox component="span">Excluded from analysis</ExcludedBox>
        )}
        {!interview?.exclude && (
          <IncludeBox component="span">Include in analysis</IncludeBox>
        )}
      </Box>
      <SubtitleTypography sx={{ marginBottom: 24 }}>Summary</SubtitleTypography>
      <Typography
        variant="body2"
        gutterBottom
        style={{ whiteSpace: "pre-wrap" }}
      >
        {interview?.summaryText}
      </Typography>
      <SubtitleTypography sx={{ marginBottom: 16 }}>
        Interview
      </SubtitleTypography>
      <Box>
        <Typography variant="h3" sx={{ marginBottom: 10 }}>
          {interview?.title}
        </Typography>
        <InfoTypography variant="body1" sx={{ marginBottom: 8 }}>
          <strong>Interviewed on: </strong>
          {interview?.interviewDate &&
            interview?.interviewDate.format("MMMM D, YYYY")}
        </InfoTypography>
        <InfoTypography variant="body1" sx={{ marginBottom: 22 }}>
          <strong>Uploaded: </strong>
          {interview?.createdAt && interview?.createdAt.format("MMMM D, YYYY")}
        </InfoTypography>
        {rawCorpus &&
          rawCorpus.conversation.map((step: TegusStep, index: number) => {
            return (
              <Box key={index}>
                {citation && citation.index === index && (
                  <Box ref={highlightedCitationEl} />
                )}

                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    background:
                      citation && citation.index === index
                        ? "#c8caf6"
                        : "transparent",
                  }}
                >
                  {step.speaker}: {step.text}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
