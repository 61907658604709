import {
  useContext,
  useState,
  useRef,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { Theme, Chunk } from "types/database";
import { Box, Stack } from "@mui/material";
import AppButton from "components/materials/actions/AppButton";
import ArrowBackIcon from "components/icons/ArrowBackIcon";
import Typography from "@mui/material/Typography";
import { Interview } from "types/database";
import { SynthesisContext } from "contexts/SynthesisContext";
import { useLoaderChunks } from "api/loaders/chunks";
import { useLoaderInterviews } from "api/loaders/interviews";
import InterviewIcon from "components/icons/InterviewIcon";
import { useParams } from "react-router";
import MentionBox from "./components/MentionBox";
import Fade from "@mui/material/Fade";
import { timeout } from "api/utils/helpers";
import { AppApiError } from "api/utils/types";
import { publish, AppEventName } from "contexts/appEvents";

export interface MentionsProps {
  theme: Theme;
  onBack?(): void;
}

export default function Mentions(props: MentionsProps) {
  //Props
  const { theme, onBack } = props;
  //Hooks
  const { caseid } = useParams();
  const { loadChunks } = useLoaderChunks();
  const { loadInterviews } = useLoaderInterviews();
  const { showInterviewInformation } = useContext(SynthesisContext);
  //States
  const [interviews, setInterviews] = useState<Interview[]>(null);
  const [citations, setCitations] = useState<Chunk[]>(null);
  const wrapperEl = useRef(null);
  const listEl = useRef(null);
  const dataFetchedRef = useRef(false);

  //Force Reducer
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  //Call Interviews Request
  const callInterviews = useCallback(
    async (caseId: number, ids: number[], chunks: Chunk[]) => {
      const interviews: Interview[] = await loadInterviews(caseId, false, ids);
      setInterviews(interviews);
      await timeout(1);
      setCitations(chunks);
    },
    [loadInterviews]
  );

  //Call Chunks Request
  const callChunks = useCallback(
    async (themeId: number) => {
      const result: Chunk[] | AppApiError = await loadChunks(themeId);
      if (result instanceof Array) {
        const ids: number[] = [];
        //parse to get interviews
        const chunks: Chunk[] = result as Chunk[];
        chunks.forEach((chunk: Chunk) => {
          if (ids.indexOf(chunk.interviewId) < 0) {
            ids.push(chunk.interviewId);
          }
        });
        callInterviews(parseInt(caseid), ids, chunks);
      } else if (result === AppApiError.ThemeNotFound) {
        publish(AppEventName.ON_THEMES_CHANGED, { case_id: parseInt(caseid) });
        onBack();
      }
    },
    [loadChunks, callInterviews, caseid, onBack]
  );

  //Fetch Chunks on Page Load
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    callChunks(theme.id);
  }, [callChunks, theme]);

  const renderBack = () => {
    return (
      <Fade in={true} timeout={400}>
        <Box sx={{ marginLeft: 16 }}>
          <AppButton
            variant="link"
            color="primary"
            size="medium"
            label="Back"
            startIcon={<ArrowBackIcon />}
            onClick={onBack}
          />
        </Box>
      </Fade>
    );
  };

  const renderTitle = () => {
    return (
      <Fade in={!!interviews} timeout={400}>
        <Box
          sx={{
            paddingTop: 10,
            marginLeft: 16,
            marginRight: 20,
            paddingBottom: 5,
          }}
        >
          <Typography variant="h6">{theme.name}</Typography>
        </Box>
      </Fade>
    );
  };

  const renderInterviewsList = () => {
    return (
      <Fade in={!!interviews} timeout={400}>
        <Stack
          sx={{
            paddingTop: 10,
            marginLeft: 16,
            marginRight: 20,
            paddingBottom: 24,
          }}
          spacing={8}
        >
          <Typography variant="body1" color="#67676B">
            <strong>Based on these interviews:</strong>
          </Typography>
          {interviews &&
            interviews.map((interview: Interview, index: number) => {
              return (
                <AppButton
                  key={index}
                  variant="link"
                  color="grey-green"
                  size="medium"
                  label={interview.title}
                  startIcon={<InterviewIcon />}
                  onClick={() => {
                    showInterviewInformation(interview);
                  }}
                />
              );
            })}
        </Stack>
      </Fade>
    );
  };

  const renderCitations = () => {
    return (
      <Fade in={!!citations} timeout={400}>
        <Stack sx={{ marginLeft: 16 }} spacing={18}>
          <Typography variant="h10" color="#232326">
            Citations
          </Typography>
          {citations &&
            citations.map((citation: Chunk, index: number) => {
              const interview: Interview =
                interviews &&
                interviews.find(
                  (itw: Interview) => itw.id === citation.interviewId
                );
              return (
                <MentionBox
                  key={index}
                  interview={interview}
                  citation={citation}
                  onClick={() => {
                    showInterviewInformation(interview, citation);
                  }}
                />
              );
            })}
        </Stack>
      </Fade>
    );
  };

  //Recalculate ref offset on window resize
  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
    return () => {
      window.removeEventListener("resize", forceUpdate);
    };
  });

  return (
    <Box ref={wrapperEl} sx={{ height: "100%", paddingTop: 28 }}>
      {renderBack()}
      {renderTitle()}
      <Box
        ref={listEl}
        sx={{
          maxHeight: `${
            listEl.current && wrapperEl.current
              ? wrapperEl.current.offsetHeight - listEl.current.offsetTop
              : 0
          }px`,
          overflowY: "auto",
        }}
      >
        {renderInterviewsList()}
        {renderCitations()}
      </Box>
    </Box>
  );
}
