import { styled } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { Box, BoxProps, Chip, ChipProps, Stack } from "@mui/material";
import CrossIcon from "components/icons/CrossIcon";

export interface AppChipProps {
  label: JSX.Element | string | number;
  status?: "default" | "active" | "dark";
  sx?: SxProps;
  onDelete?(): void;
}

const StyledLabelBox = styled(Box)<BoxProps>(() => ({
  width: "100%",
  overflow: "visible",
}));

interface CustomChipProps extends ChipProps {
  status: "default" | "active" | "dark";
}

const StyledChip = styled(Chip)<CustomChipProps>(({ status }) => ({
  fontFamily: "BCGHendersonSans",
  fontSize: "1.1rem",
  fontWeight: 400,
  color: status === "dark" ? "#fff" : "#373737",
  backgroundColor:
    status === "default"
      ? "#F2F2F2"
      : status === "active"
      ? "#E0FAED"
      : "#232326",
  borderRadius: "24px",
  padding: "7px 10px 6px",
  height: "inherit",
  letterSpacing: "0px",
  "& .MuiChip-label": {
    padding: "0px",
    lineHeight: "100%",
    overflow: "visible",
    width: "100%",
  },
}));

export default function AppChip(props: AppChipProps) {
  const { label, status, sx, onDelete } = props;
  const JSXLabel: JSX.Element = (
    <Stack direction="row" spacing={5}>
      <StyledLabelBox>{label}</StyledLabelBox>
      <CrossIcon
        htmlColor="#fff"
        width="0.9rem"
        onClick={onDelete}
        sx={{ cursor: "pointer" }}
      />
    </Stack>
  );
  return <StyledChip label={JSXLabel} status={status || "default"} sx={sx} />;
}
