import { createContext, useState, FC, PropsWithChildren as Props } from "react";
import { Interview, Chunk, Case } from "types/database";

//////////////////////////////
//CONTEXT FOR SYNTHESIS
//////////////////////////////

type ContextState = {
  currentCase: Case;
  updateCurrentCase: (c: Case) => void;
  isThemesRegenerating: boolean;
  updateIsThemesRegenerating: (b: boolean) => void;
  isInterviewsPanelOpen: boolean;
  toggleInterviewsPanel: () => void;
  isThemesPanelOpen: boolean;
  toggleThemesPanel: () => void;
  openThemesPanel: () => void;
  closeThemesPanel: () => void;
  isInterviewInformationVisible: boolean;
  showInterviewInformation: (interview: Interview, citation?: Chunk) => void;
  hideInterviewInformation: () => void;
  interviewInformation: Interview;
  updateInterviewInformation: (interview: Interview) => void;
  highlightedCitation: Chunk;
  isUploadPanelVisible: boolean;
  showUploadPanel: () => void;
  hideUploadPanel: () => void;
};

const defaultValues: ContextState = {
  currentCase: null,
  updateCurrentCase: () => {},
  isThemesRegenerating: false,
  updateIsThemesRegenerating: () => {},
  isInterviewsPanelOpen: true,
  toggleInterviewsPanel: () => {},
  isThemesPanelOpen: false,
  toggleThemesPanel: () => {},
  openThemesPanel: () => {},
  closeThemesPanel: () => {},
  isInterviewInformationVisible: false,
  showInterviewInformation: () => {},
  hideInterviewInformation: () => {},
  interviewInformation: null,
  updateInterviewInformation: () => {},
  highlightedCitation: null,
  isUploadPanelVisible: false,
  showUploadPanel: () => {},
  hideUploadPanel: () => {},
};

export const SynthesisContext = createContext<ContextState>(defaultValues);

export const SynthesisProvider: FC<Props> = ({ children }) => {
  //States
  const [currentCase, setCurrentCase] = useState<Case>(
    defaultValues.currentCase
  );
  const [isThemesRegenerating, setIsThemesRegenerating] = useState<boolean>(
    defaultValues.isThemesRegenerating
  );
  const [isInterviewsPanelOpen, setIsInterviewsPanelOpen] = useState<boolean>(
    defaultValues.isInterviewsPanelOpen
  );
  const [isThemesPanelOpen, setIsThemesPanelOpen] = useState<boolean>(
    defaultValues.isThemesPanelOpen
  );
  const [isInterviewInformationVisible, setIsInterviewInformationVisible] =
    useState<boolean>(defaultValues.isInterviewInformationVisible);
  const [interviewInformation, setInterviewInformation] = useState<Interview>(
    defaultValues.interviewInformation
  );
  const [highlightedCitation, setHighlightedCitation] = useState<Chunk>(
    defaultValues.highlightedCitation
  );
  const [isUploadPanelVisible, setIsUploadPanelVisible] = useState<boolean>(
    defaultValues.isUploadPanelVisible
  );

  //Update Functions
  const updateCurrentCase = (c: Case) => {
    setCurrentCase(c);
  };
  const updateIsThemesRegenerating = (b: boolean) => {
    setIsThemesRegenerating(b);
  };
  const toggleInterviewsPanel = () => {
    if (isInterviewsPanelOpen) {
      setIsThemesPanelOpen(false);
    }
    setIsInterviewsPanelOpen(!isInterviewsPanelOpen);
  };
  const toggleThemesPanel = () => {
    isThemesPanelOpen ? closeThemesPanel() : openThemesPanel();
  };
  const openThemesPanel = () => {
    setIsInterviewsPanelOpen(true);
    setIsThemesPanelOpen(true);
  };
  const closeThemesPanel = () => {
    setIsThemesPanelOpen(false);
  };
  const showInterviewInformation = (interview: Interview, citation?: Chunk) => {
    setInterviewInformation(interview);
    setHighlightedCitation(citation);
    setIsInterviewInformationVisible(true);
  };
  const hideInterviewInformation = () => {
    setInterviewInformation(null);
    setHighlightedCitation(null);
    setIsInterviewInformationVisible(false);
  };
  const updateInterviewInformation = (interview: Interview) => {
    setInterviewInformation(interview);
  };
  const showUploadPanel = () => {
    setIsUploadPanelVisible(true);
  };
  const hideUploadPanel = () => {
    setIsUploadPanelVisible(false);
  };

  return (
    <SynthesisContext.Provider
      value={{
        currentCase,
        updateCurrentCase,
        isThemesRegenerating,
        updateIsThemesRegenerating,
        isInterviewsPanelOpen,
        toggleInterviewsPanel,
        isThemesPanelOpen,
        toggleThemesPanel,
        openThemesPanel,
        closeThemesPanel,
        isInterviewInformationVisible,
        showInterviewInformation,
        hideInterviewInformation,
        interviewInformation,
        updateInterviewInformation,
        highlightedCitation,
        isUploadPanelVisible,
        showUploadPanel,
        hideUploadPanel,
      }}
    >
      {children}
    </SynthesisContext.Provider>
  );
};
