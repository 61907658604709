import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";

const CounterBox = styled(Box)<BoxProps>(() => ({
  color: "#fff",
  background: "#232326",
  borderRadius: 4,
  padding: "3px 4px",
}));

export default function DsTypography() {
  return (
    <>
      <Box
        sx={{
          fontFamily: "BCGHendersonSans",
          fontSize: "1.4rem",
          fontWeight: 400,
        }}
      >
        DESIGN SYSTEM - Typography
      </Box>
      <Stack>
        <Typography variant="h6">
          h6 - <CounterBox component="span">10</CounterBox> Suggested Themes
        </Typography>
        <Typography variant="h8">h8 - Theme</Typography>
      </Stack>
    </>
  );
}
