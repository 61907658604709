import { useContext, useState } from "react";
import { Box, Stack } from "@mui/material";
import AppButton from "components/materials/actions/AppButton";
import { NotificationsContext } from "contexts/NotificationsContext";
import AppInputText from "components/materials/forms/AppInputText";
import { env } from "utils/envVariables";
import AppInputNumber from "components/materials/forms/AppInputNumber";

export default function NotifsTest() {
  //Hooks
  const { broadcast, isConnected } = useContext(NotificationsContext);
  //States
  const [message, setMessage] = useState<string>("");
  const [customThemeId, setCustomThemeId] = useState<number>();

  return (
    <Box
      sx={{
        position: "relative",
        marginLeft: "5rem",
        padding: 20,
      }}
    >
      <p>
        <strong>Notif Service URI</strong>: {env().NOTIF_URI}
      </p>
      <p>
        <strong>Connected</strong>:{" "}
        {isConnected !== undefined && isConnected.toString()}
      </p>
      <Stack direction="row" spacing={10} alignItems="center">
        <AppInputText
          placeholder="Set your message here"
          maxLength={100}
          value={message}
          onChange={(newValue: string) => {
            setMessage(newValue);
          }}
        />
        <AppButton
          variant="contained"
          color="primary"
          size="large"
          label="Send Message"
          onClick={() => {
            broadcast(message);
          }}
          disabled={!isConnected || !message}
        />
      </Stack>
      <Stack marginTop={10}>
        <AppButton
          variant="outlined"
          color="secondary"
          size="medium"
          label="Send Themes Changed"
          onClick={() => {
            const message: any = {
              state: "themes-changed",
              case_id: 1,
            };
            broadcast(JSON.stringify(message));
          }}
          disabled={!isConnected}
        />
      </Stack>
      <Stack direction="row" spacing={10} alignItems="center" marginTop={10}>
        <AppInputNumber
          placeholder="Set your Custom Theme ID"
          maxLength={100}
          value={customThemeId}
          onChange={(newValue: number) => {
            setCustomThemeId(newValue);
          }}
        />
        <AppButton
          variant="contained"
          color="primary"
          size="large"
          label="Send Message No Corpus on Custom Theme"
          onClick={() => {
            broadcast(
              JSON.stringify({
                state: "themes-deleted",
                case_id: 1,
                theme_id: customThemeId,
                reason: "no corpus",
              })
            );
          }}
          disabled={!isConnected || !customThemeId}
        />
      </Stack>
    </Box>
  );
}
