import { useContext } from "react";
import { useApiSources } from "api/requests/sources";
import { ToasterContext, Toaster } from "contexts/ToasterContext";
import { Source } from "types/database";

const formatSources = (sources: any[]): Source[] => {
  const formattedSources: Source[] = sources.map((source: any) => {
    return formatSource(source);
  });
  return formattedSources;
};

const formatSource = (source: any): Source => {
  const formattedSource: Source = {
    ...source,
  };
  return formattedSource;
};

export function useLoaderSources() {
  //Hooks
  const apiSources = useApiSources();
  const { showToaster } = useContext(ToasterContext);

  const loadSource = async (id: number): Promise<Source> => {
    return apiSources
      .getSource(id)
      .then((source: any) => {
        return formatSource(source);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Source fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadSources = async (
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<Source[]> => {
    return apiSources
      .getSources(orderBy, orderDir, limit, offset)
      .then((sources: any[]) => {
        return formatSources(sources);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Sources fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadSourcesCount = async (): Promise<number> => {
    return apiSources
      .getSourcesCount()
      .then((count: number) => {
        return count;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Sources Count fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const createSource = async (name: string): Promise<Source> => {
    return apiSources
      .createSource(name)
      .then((created: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Source has been saved successfully",
        };
        showToaster(toaster);
        return formatSource(created);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Source saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const updateSource = async (id: number, name: string): Promise<Source> => {
    return apiSources
      .updateSource(id, name)
      .then((updated: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Source has been saved successfully",
        };
        showToaster(toaster);
        return formatSource(updated);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Source saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const deleteSource = async (id: number): Promise<boolean> => {
    return apiSources
      .deleteSource(id)
      .then((result: boolean) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Source has been successfully deleted ",
        };
        showToaster(toaster);
        return result;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Source deletion",
        };
        showToaster(toaster);
        return null;
      });
  };

  return {
    loadSource: loadSource,
    loadSources: loadSources,
    loadSourcesCount: loadSourcesCount,
    createSource: createSource,
    updateSource: updateSource,
    deleteSource: deleteSource,
  };
}
