import { apiClient } from "../utils/axios";
import { handleError } from "../utils/axios";
import { timeout } from "../utils/helpers";
//import { useOktaAuth } from "@okta/okta-react";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import { Case } from "types/database";

export function useApiCases() {
  const { mockDataActivated } = useContext(AppContext);
  //const { oktaAuth } = useOktaAuth();
  //const accessToken: string = oktaAuth.getAccessToken();
  const accessToken: string = null;

  let cases: any = require(`api/mockdata/cases.json`);

  const getCase = async (id: number): Promise<any> => {
    console.log(`API getCase ${id}`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      await timeout(Math.random() * 500);
      const c: Case = cases.find((c: Case) => c.id === id);
      return c;
    }
    try {
      let route: string = `/case/${id}`;
      const response = await (await apiClient(accessToken)).get(route);
      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getCases = async (
    caseId: number,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<any[]> => {
    console.log(`API getCases`, mockDataActivated ? "MOCK" : "API");
    try {
      let results: any[] = [];
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        results = cases;
      } else {
        let route: string = `/case?case_id=${caseId}`;
        if (orderBy)
          route += `&ordering=${orderDir === "desc" ? "-" : ""}${orderBy}`;
        if (limit) route += `&limit=${limit}`;
        if (offset) route += `&offset=${offset}`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("results" in response.data) {
          results = response.data.results;
        } else {
          results = response.data;
        }
      }
      return results;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getCasesCount = async (): Promise<number> => {
    console.log(`API getCasesCount`, mockDataActivated ? "MOCK" : "API");
    try {
      let count: number = 0;
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        const results: any[] = cases;
        count = results.length;
      } else {
        let route: string = `/case`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("count" in response.data) {
          count = response.data.count;
        } else {
          count = response.data.length;
        }
      }
      return count;
    } catch (error: any) {
      handleError(error);
    }
  };

  const createCase = async (name: string): Promise<any> => {
    console.log(`API createCase`, mockDataActivated ? "MOCK" : "API", name);
    if (mockDataActivated) {
      await timeout(Math.random() * 500);
      let createdCase: Case = require(`api/mockdata/new-case.json`);
      createdCase.id = Date.now();
      createdCase.name = name;
      cases.push(createdCase);
      return createdCase;
    }
    try {
      let formData = new FormData();
      formData.append("name", name);
      const route: string = `/case`;
      const response = await (
        await apiClient(accessToken)
      ).post(route, formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const updateCase = async (id: number, name: string): Promise<any> => {
    console.log(`API updateCase`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const caseIndex: number = cases.findIndex((c: Case) => c.id === id);
      const updatedCase: any = {
        ...cases[caseIndex],
        name: name,
      };
      cases[caseIndex] = updatedCase;
      return updatedCase;
    }
    try {
      const route: string = `/case/${id}`;
      const input: any = {
        name: name,
      };
      let formData = new FormData();
      for (const property in input) {
        formData.append(property, input[property]);
      }
      const response = await (
        await apiClient(accessToken, true, true)
      ).patch(route, formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const deleteCase = async (id: number): Promise<boolean> => {
    console.log(`API deleteCase`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const index: number = cases.findIndex((c: Case) => c.id === id);
      cases = cases.splice(index, 1);
      return true;
    }
    try {
      const route: string = `/case/${id}`;
      await (await apiClient(accessToken)).delete(route);
      return true;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return {
    getCase,
    getCases,
    getCasesCount,
    createCase,
    updateCase,
    deleteCase,
  };
}
