import { useContext, useState } from "react";
import { SynthesisContext } from "contexts/SynthesisContext";
import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppButton from "components/materials/actions/AppButton";
import CrossIcon from "components/icons/CrossIcon";
import Themes from "./Themes";
import { Theme } from "types/database";
import Mentions from "./Mentions";

interface WrapperBoxProps extends BoxProps {
  hide?: boolean;
}
const WrapperBox = styled(Box)<WrapperBoxProps>(({ hide }) => ({
  position: "absolute",
  top: 0,
  left: "20%",
  width: "20%",
  height: "100%",
  background: "#F7F7F8",
  paddingLeft: 0,
  paddingTop: 23,
  opacity: hide ? 0 : 1,
  transition: "opacity 0.5s",
  zIndex: 1,
  borderLeft: "1px solid #E4E4E9",
}));

const CloseBtBox = styled(Box)<BoxProps>(() => ({
  position: "absolute",
  top: 6,
  right: 7,
  zIndex: 10,
}));

interface SectionBoxProps extends BoxProps {
  visible?: boolean;
}
const ThemesBox = styled(Box)<SectionBoxProps>(({ visible }) => ({
  position: "absolute",
  top: 0,
  zIndex: visible ? 5 : 4,
  width: "100%",
  height: "100%",
  opacity: visible ? 1 : 0,
  transition: "opacity 0.5s",
}));
const MentionsBox = styled(Box)<SectionBoxProps>(({ visible }) => ({
  position: "absolute",
  zIndex: visible ? 5 : 4,
  top: 0,
  width: "100%",
  height: "100%",
  opacity: visible ? 1 : 0,
  transition: "opacity 0.5s",
}));

export default function ThemesPanel() {
  //Hooks
  const { isThemesPanelOpen, closeThemesPanel } = useContext(SynthesisContext);
  //States
  const [selectedTheme, setSelectedTheme] = useState<Theme>(null);
  const [isThemesVisible, setIsThemesVisible] = useState<boolean>(true);
  const [isMentionsVisible, setIsMentionsVisible] = useState<boolean>(false);

  return (
    <WrapperBox hide={!isThemesPanelOpen}>
      <CloseBtBox>
        <AppButton
          variant="circle"
          color="transparent"
          size="medium"
          icon={<CrossIcon />}
          onClick={() => {
            closeThemesPanel();
            setIsMentionsVisible(false);
            setIsThemesVisible(true);
          }}
        />
      </CloseBtBox>
      <ThemesBox visible={isThemesVisible}>
        <Themes
          onSelectTheme={(theme: Theme) => {
            setSelectedTheme(theme);
            setIsMentionsVisible(true);
            setIsThemesVisible(false);
          }}
        />
      </ThemesBox>
      {selectedTheme && (
        <MentionsBox visible={isMentionsVisible}>
          <Mentions
            theme={selectedTheme}
            onBack={() => {
              setIsMentionsVisible(false);
              setIsThemesVisible(true);
              setSelectedTheme(null);
            }}
          />
        </MentionsBox>
      )}
    </WrapperBox>
  );
}
