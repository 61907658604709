import { AppLayout } from "components/materials/containers/AppLayout";

export default function Error404() {
  return (
    <AppLayout>
      <h2>Error</h2>
      <p>The content you requested was not found or doesn't exist</p>
    </AppLayout>
  );
}
