import { apiClient } from "../utils/axios";
import { handleError } from "../utils/axios";
import { timeout } from "../utils/helpers";
//import { useOktaAuth } from "@okta/okta-react";
import { AppContext } from "contexts/AppContext";
import { NotificationsContext } from "contexts/NotificationsContext";
import { useContext } from "react";
import { Theme, Case } from "types/database";

export function useApiThemes() {
  const { mockDataActivated } = useContext(AppContext);
  const { broadcast } = useContext(NotificationsContext);
  //const { oktaAuth } = useOktaAuth();
  //const accessToken: string = oktaAuth.getAccessToken();
  const accessToken: string = null;

  let themes: any = require(`api/mockdata/themes.json`);

  const getTheme = async (id: number): Promise<any> => {
    console.log(`API getTheme ${id}`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      await timeout(Math.random() * 500);
      const theme: Theme = themes.find((theme: Theme) => theme.id === id);
      return theme;
    }
    try {
      let route: string = `/theme/${id}`;
      const response = await (await apiClient(accessToken)).get(route);
      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getThemes = async (
    caseId: number,
    generated?: boolean,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<any[]> => {
    console.log(`API getThemes`, mockDataActivated ? "MOCK" : "API");
    try {
      let results: any[] = [];
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        if (generated !== undefined) {
          results = themes.filter(
            (theme: any) => theme.generated === generated
          );
        } else {
          results = themes;
        }
      } else {
        let route: string = `/theme?case_id=${caseId}`;
        if (generated !== undefined) route += `&generated=${generated}`;
        if (orderBy)
          route += `&ordering=${orderDir === "desc" ? "-" : ""}${orderBy}`;
        if (limit) route += `&limit=${limit}`;
        if (offset) route += `&offset=${offset}`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("results" in response.data) {
          results = response.data.results;
        } else {
          results = response.data;
        }
      }
      return results;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getThemesCount = async (
    caseId: number,
    generated?: boolean
  ): Promise<number> => {
    console.log(`API getThemesCount`, mockDataActivated ? "MOCK" : "API");
    try {
      let count: number = 0;
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        const results: any[] = themes;
        count = results.length;
      } else {
        let route: string = `/theme?case_id=${caseId}&limit=1`;
        if (generated !== undefined) route += `&generated=${generated}`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("count" in response.data) {
          count = response.data.count;
        } else {
          count = response.data.length;
        }
      }
      return count;
    } catch (error: any) {
      handleError(error);
    }
  };

  const createTheme = async (caseId: number, name: string): Promise<any> => {
    console.log(`API createTheme`, mockDataActivated ? "MOCK" : "API", name);
    if (mockDataActivated) {
      const newTheme: any = {
        ...themes[0],
        id: Date.now(),
        generated: false,
        name: name,
      };
      themes.unshift(newTheme);

      await timeout(2000);
      const message: any = {
        state: "theme-ready",
        theme: { ...newTheme, interviews_count: 1 },
      };
      broadcast(JSON.stringify(message));

      return newTheme;
    }
    try {
      const route: string = `/theme/case/${caseId}`;
      const input: any = name;
      const response = await (await apiClient(accessToken)).post(route, input);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const updateTheme = async (id: number, name: string): Promise<any> => {
    console.log(`API updateTheme`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const themeIndex: number = themes.findIndex(
        (theme: Theme) => theme.id === id
      );
      const updatedTheme: any = {
        ...themes[themeIndex],
        name: name,
      };
      themes[themeIndex] = updatedTheme;
      return updatedTheme;
    }
    try {
      const route: string = `/theme/${id}`;
      const input: any = {
        name: name,
      };
      let formData = new FormData();
      for (const property in input) {
        formData.append(property, input[property]);
      }
      const response = await (
        await apiClient(accessToken, true, true)
      ).patch(route, formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const deleteTheme = async (id: number): Promise<boolean> => {
    console.log(`API deleteTheme`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const index: number = themes.findIndex((theme: Theme) => theme.id === id);
      themes = themes.splice(index, 1);
      return true;
    }
    try {
      const route: string = `/theme/${id}`;
      await (await apiClient(accessToken)).delete(route);
      return true;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const excludeTheme = async (id: number): Promise<any> => {
    console.log(`API excludeTheme`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const themes: Theme[] = require(`api/mockdata/themes.json`);
      const index: number = themes.findIndex((theme: Theme) => theme.id === id);
      const updatedTheme: any = {
        ...themes[index],
        exclude: true,
      };
      themes[index] = updatedTheme;
      return updatedTheme;
    }
    try {
      const route: string = `/theme/${id}`;
      const input: any = {
        exclude: true,
      };
      let formData = new FormData();
      for (const property in input) {
        formData.append(property, input[property]);
      }
      const response = await (
        await apiClient(accessToken, true, true)
      ).patch(route, formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const includeTheme = async (id: number): Promise<any> => {
    console.log(`API includeTheme`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const themes: Theme[] = require(`api/mockdata/themes.json`);
      const index: number = themes.findIndex((theme: Theme) => theme.id === id);
      const updatedTheme: any = {
        ...themes[index],
        exclude: false,
      };
      themes[index] = updatedTheme;
      return updatedTheme;
    }
    try {
      const route: string = `/theme/${id}`;
      const input: any = {
        exclude: false,
      };
      let formData = new FormData();
      for (const property in input) {
        formData.append(property, input[property]);
      }
      const response = await (
        await apiClient(accessToken, true, true)
      ).patch(route, formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const regenerateThemes = async (caseId: number): Promise<any> => {
    console.log(`API regenerateThemes`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      await timeout(2000);
      const message: any = {
        state: "themes-changed",
        theme: { case_id: caseId },
      };
      broadcast(JSON.stringify(message));
      let cases: any = require(`api/mockdata/cases.json`);
      const c: Case = cases.find((c: Case) => c.id === caseId);
      return c;
    }
    try {
      const route: string = `/case/${caseId}/regenerate_themes`;
      const response = await (await apiClient(accessToken)).post(route);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return {
    getTheme,
    getThemes,
    getThemesCount,
    createTheme,
    updateTheme,
    deleteTheme,
    excludeTheme,
    includeTheme,
    regenerateThemes,
  };
}
