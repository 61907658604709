import { useState, useCallback, useEffect, useRef, useReducer } from "react";

import Stack, { StackProps } from "@mui/material/Stack";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useLoaderSources } from "api/loaders/sources";
import { Source } from "types/database";
import { styled } from "@mui/material/styles";
import AppButton from "components/materials/actions/AppButton";
import TrashIcon from "components/icons/TrashIcon";
import EditIcon from "components/icons/EditIcon";
import PlusIcon from "components/icons/PlusIcon";
import CrossIcon from "components/icons/CrossIcon";
import CheckIcon from "components/icons/CheckIcon";
import Collapse from "@mui/material/Collapse";
import AppInputText from "components/materials/forms/AppInputText";

export interface SourcesMgmtProps {
  onChange(): void;
  onClose(): void;
}

interface RowStackProps extends StackProps {
  index: number;
}
const RowStack = styled(Stack)<RowStackProps>(({ index }) => ({
  padding: 8,
  background: index % 2 === 0 ? "#F7F7F8" : "transparent",
  borderTop: "1px solid #E4E4E9",
}));
const WrapperRowsStack = styled(Stack)<StackProps>(() => ({
  minWidth: 400,
  borderBottom: "1px solid #E4E4E9",
  borderLeft: "1px solid #E4E4E9",
  borderRight: "1px solid #E4E4E9",
}));

export default function SourcesMgmt(props: SourcesMgmtProps) {
  //Props
  const { onChange, onClose } = props;
  //Hooks
  const { loadSources, createSource, updateSource, deleteSource } =
    useLoaderSources();
  //States
  const [sources, setSources] = useState<Source[]>(null);
  const [isSourceEditing, setIsSourceEditing] = useState<boolean>(false);
  const [sourceEditingId, setSourceEditingId] = useState<number>(null);
  const [sourceDeletingId, setSourceDeletingId] = useState<number>(null);
  const [inputSource, setInputSource] = useState<string>("");
  const dataFetchedRef = useRef(false);

  //Force Reducer
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  //Handle Validate Source Form
  const handleValidateSource = () => {
    if (sourceEditingId) {
      callUpdateSource(sourceEditingId, inputSource);
    } else {
      callCreateSource(inputSource);
    }
    setInputSource("");
    setIsSourceEditing(false);
  };

  //Handle Deletion Source
  const handleDeleteSource = () => {
    if (sourceDeletingId) {
      callDeleteSource(sourceDeletingId);
    }
  };

  //Call Get Sources Request
  const callSources = useCallback(async () => {
    const results: Source[] = await loadSources();
    setSources(results);
  }, [loadSources]);

  //Call Custom Source Creation Request
  const callCreateSource = async (name: string) => {
    let createdSource: Source = await createSource(name);
    sources.unshift({ ...createdSource });
    forceUpdate();
    onChange();
  };
  //Call Custom Source Update Request
  const callUpdateSource = async (id: number, name: string) => {
    const updatedSource: Source = await updateSource(id, name);
    const index: number = sources.findIndex(
      (source: Source) => source.id === id
    );
    if (index > -1) {
      sources[index] = { ...updatedSource };
      forceUpdate();
    }
    setSourceEditingId(null);
    onChange();
  };
  //Call Custom Source Delete Request
  const callDeleteSource = async (id: number) => {
    const result: boolean = await deleteSource(id);
    if (result) {
      const index: number = sources.findIndex(
        (source: Source) => source.id === id
      );
      console.log(index);
      if (index > -1) {
        sources.splice(index, 1);
        forceUpdate();
      }
    }
    setSourceDeletingId(null);
    onChange();
  };

  //Fetch Sources & Tags on Page Load
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    callSources();
  }, [callSources]);

  const renderSourceForm = () => {
    return (
      <Stack
        direction="row"
        spacing={10}
        alignItems="center"
        justifyContent="space-between"
      >
        <AppInputText
          placeholder=""
          maxLength={100}
          value={inputSource}
          onChange={(newValue: string) => {
            setInputSource(newValue);
          }}
          fullWidth
        />
        <AppButton
          variant="circle"
          color="transparent"
          size="medium"
          icon={<CheckIcon />}
          disabled={!inputSource}
          onClick={() => {
            handleValidateSource();
          }}
        />
        <AppButton
          variant="circle"
          color="transparent"
          size="medium"
          icon={<CrossIcon />}
          onClick={() => {
            setInputSource("");
            setSourceEditingId(null);
            setIsSourceEditing(false);
          }}
        />
      </Stack>
    );
  };

  return (
    <>
      <Box sx={{ position: "absolute", top: 16, right: 16 }}>
        <AppButton
          variant="circle"
          color="transparent"
          size="medium"
          icon={<CrossIcon />}
          onClick={() => {
            setIsSourceEditing(false);
            setSourceDeletingId(null);
            setSourceEditingId(null);
            onClose();
          }}
        />
      </Box>
      <Stack
        sx={{
          margin: "24px 40px 40px",
        }}
        spacing={20}
        position="relative"
      >
        <Typography variant="h3" fontSize="2rem">
          Manage Sources
        </Typography>
        {!isSourceEditing && (
          <AppButton
            variant="outlined"
            color="secondary"
            size="medium"
            label="Source"
            startIcon={<PlusIcon />}
            onClick={() => {
              setIsSourceEditing(true);
            }}
          />
        )}
        {isSourceEditing && renderSourceForm()}
        <WrapperRowsStack>
          {sources &&
            sources.map((source: Source, index: number) => {
              return (
                <Box key={index}>
                  <RowStack
                    index={index}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>{source.name}</Typography>
                    <Stack direction="row" spacing={20}>
                      <AppButton
                        variant="link"
                        color="grey-red"
                        size="medium"
                        startIcon={<TrashIcon />}
                        onClick={() => {
                          setSourceDeletingId(source.id);
                        }}
                      />
                      <AppButton
                        variant="link"
                        color="grey-green"
                        size="medium"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          setSourceEditingId(source.id);
                          setInputSource(source.name);
                          setIsSourceEditing(true);
                        }}
                      />
                    </Stack>
                  </RowStack>
                  <Collapse in={sourceDeletingId === source.id} timeout={500}>
                    <Stack
                      spacing={16}
                      sx={{
                        backgroundColor: "#FFF8F0",
                        padding: 16,
                        textAlign: "right",
                      }}
                    >
                      <Typography>
                        Are you sure you want to remove it? This action can not
                        be undone.
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={24}
                        justifyContent="flex-end"
                      >
                        <AppButton
                          variant="contained"
                          color="red"
                          size="small"
                          label="Proceed to Delete"
                          startIcon={<TrashIcon />}
                          onClick={() => {
                            handleDeleteSource();
                          }}
                        />
                        <AppButton
                          variant="contained"
                          color="secondary"
                          size="small"
                          label="Cancel"
                          onClick={() => {
                            setSourceDeletingId(null);
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Collapse>
                </Box>
              );
            })}
        </WrapperRowsStack>
      </Stack>
    </>
  );
}
