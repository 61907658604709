import { useState, useCallback, useEffect, useRef, useReducer } from "react";

import Stack, { StackProps } from "@mui/material/Stack";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useLoaderTags } from "api/loaders/tags";
import { Tag } from "types/database";
import { styled } from "@mui/material/styles";
import AppButton from "components/materials/actions/AppButton";
import TrashIcon from "components/icons/TrashIcon";
import EditIcon from "components/icons/EditIcon";
import PlusIcon from "components/icons/PlusIcon";
import CrossIcon from "components/icons/CrossIcon";
import CheckIcon from "components/icons/CheckIcon";
import Collapse from "@mui/material/Collapse";
import AppInputText from "components/materials/forms/AppInputText";

export interface TagsMgmtProps {
  onChange(): void;
  onClose(): void;
}

interface RowStackProps extends StackProps {
  index: number;
}
const RowStack = styled(Stack)<RowStackProps>(({ index }) => ({
  padding: 8,
  background: index % 2 === 0 ? "#F7F7F8" : "transparent",
  borderTop: "1px solid #E4E4E9",
}));
const WrapperRowsStack = styled(Stack)<StackProps>(() => ({
  minWidth: 400,
  borderBottom: "1px solid #E4E4E9",
  borderLeft: "1px solid #E4E4E9",
  borderRight: "1px solid #E4E4E9",
}));

export default function TagsMgmt(props: TagsMgmtProps) {
  //Props
  const { onChange, onClose } = props;
  //Hooks
  const { loadTags, createTag, updateTag, deleteTag } = useLoaderTags();
  //States
  const [tags, setTags] = useState<Tag[]>(null);
  const [isTagEditing, setIsTagEditing] = useState<boolean>(false);
  const [tagEditingId, setTagEditingId] = useState<number>(null);
  const [tagDeletingId, setTagDeletingId] = useState<number>(null);
  const [inputTag, setInputTag] = useState<string>("");
  const dataFetchedRef = useRef(false);

  //Force Reducer
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  //Handle Validate Tag Form
  const handleValidateTag = () => {
    if (tagEditingId) {
      callUpdateTag(tagEditingId, inputTag);
    } else {
      callCreateTag(inputTag);
    }
    setInputTag("");
    setIsTagEditing(false);
  };

  //Handle Deletion Tag
  const handleDeleteTag = () => {
    if (tagDeletingId) {
      callDeleteTag(tagDeletingId);
    }
  };

  //Call Get Tags Request
  const callTags = useCallback(async () => {
    const results: Tag[] = await loadTags();
    setTags(results);
  }, [loadTags]);

  //Call Custom Tag Creation Request
  const callCreateTag = async (name: string) => {
    let createdTag: Tag = await createTag(name);
    tags.unshift({ ...createdTag });
    forceUpdate();
    onChange();
  };
  //Call Custom Tag Update Request
  const callUpdateTag = async (id: number, name: string) => {
    const updatedTag: Tag = await updateTag(id, name);
    const index: number = tags.findIndex((tag: Tag) => tag.id === id);
    if (index > -1) {
      tags[index] = { ...updatedTag };
      forceUpdate();
    }
    setTagEditingId(null);
    onChange();
  };
  //Call Custom Tag Delete Request
  const callDeleteTag = async (id: number) => {
    const result: boolean = await deleteTag(id);
    if (result) {
      const index: number = tags.findIndex((tag: Tag) => tag.id === id);
      console.log(index);
      if (index > -1) {
        tags.splice(index, 1);
        forceUpdate();
      }
    }
    setTagDeletingId(null);
    onChange();
  };

  //Fetch Tags & Tags on Page Load
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    callTags();
  }, [callTags]);

  const renderTagForm = () => {
    return (
      <Stack
        direction="row"
        spacing={10}
        alignItems="center"
        justifyContent="space-between"
      >
        <AppInputText
          placeholder=""
          maxLength={100}
          value={inputTag}
          onChange={(newValue: string) => {
            setInputTag(newValue);
          }}
          fullWidth
        />
        <AppButton
          variant="circle"
          color="transparent"
          size="medium"
          icon={<CheckIcon />}
          disabled={!inputTag}
          onClick={() => {
            handleValidateTag();
          }}
        />
        <AppButton
          variant="circle"
          color="transparent"
          size="medium"
          icon={<CrossIcon />}
          onClick={() => {
            setInputTag("");
            setTagEditingId(null);
            setIsTagEditing(false);
          }}
        />
      </Stack>
    );
  };

  return (
    <>
      <Box sx={{ position: "absolute", top: 16, right: 16 }}>
        <AppButton
          variant="circle"
          color="transparent"
          size="medium"
          icon={<CrossIcon />}
          onClick={() => {
            setIsTagEditing(false);
            setTagDeletingId(null);
            setTagEditingId(null);
            onClose();
          }}
        />
      </Box>
      <Stack
        sx={{
          margin: "24px 40px 40px",
        }}
        spacing={20}
        position="relative"
      >
        <Typography variant="h3" fontSize="2rem">
          Manage Tags
        </Typography>
        {!isTagEditing && (
          <AppButton
            variant="outlined"
            color="secondary"
            size="medium"
            label="Tag"
            startIcon={<PlusIcon />}
            onClick={() => {
              setIsTagEditing(true);
            }}
          />
        )}
        {isTagEditing && renderTagForm()}
        <WrapperRowsStack>
          {tags &&
            tags.map((tag: Tag, index: number) => {
              return (
                <Box key={index}>
                  <RowStack
                    index={index}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>{tag.name}</Typography>
                    <Stack direction="row" spacing={20}>
                      <AppButton
                        variant="link"
                        color="grey-red"
                        size="medium"
                        startIcon={<TrashIcon />}
                        onClick={() => {
                          setTagDeletingId(tag.id);
                        }}
                      />
                      <AppButton
                        variant="link"
                        color="grey-green"
                        size="medium"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          setTagEditingId(tag.id);
                          setInputTag(tag.name);
                          setIsTagEditing(true);
                        }}
                      />
                    </Stack>
                  </RowStack>
                  <Collapse in={tagDeletingId === tag.id} timeout={500}>
                    <Stack
                      spacing={16}
                      sx={{
                        backgroundColor: "#FFF8F0",
                        padding: 16,
                        textAlign: "right",
                      }}
                    >
                      <Typography>
                        Are you sure you want to remove it? This action can not
                        be undone.
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={24}
                        justifyContent="flex-end"
                      >
                        <AppButton
                          variant="contained"
                          color="red"
                          size="small"
                          label="Proceed to Delete"
                          startIcon={<TrashIcon />}
                          onClick={() => {
                            handleDeleteTag();
                          }}
                        />
                        <AppButton
                          variant="contained"
                          color="secondary"
                          size="small"
                          label="Cancel"
                          onClick={() => {
                            setTagDeletingId(null);
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Collapse>
                </Box>
              );
            })}
        </WrapperRowsStack>
      </Stack>
    </>
  );
}
