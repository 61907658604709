import { createContext, useState, FC } from "react";
import { PropsWithChildren as Props } from "react";
import { useHealth } from "api/requests/health";
import { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { env } from "utils/envVariables";
import { MOCKDATA_ACTIVATED } from "utils/appSettings";

export type AppContextState = {
  themeMode: string;
  changeThemeMode: (value: string) => void;
  mockDataActivated: boolean;
  toggleMockData: () => void;
  apiHealthy: boolean;
};

const contextDefaultValues: AppContextState = {
  themeMode: "light",
  changeThemeMode: () => {},
  mockDataActivated: MOCKDATA_ACTIVATED,
  toggleMockData: () => {},
  apiHealthy: true,
};

export const AppContext = createContext<AppContextState>(contextDefaultValues);

export const AppProvider: FC<Props> = ({ children }) => {
  //Hooks
  const health = useHealth();
  const navigate = useNavigate();
  const location = useLocation();

  //States
  const [themeMode, setThemeMode] = useState<string>(
    contextDefaultValues.themeMode
  );
  const [mockDataActivated, setMockDataActivated] = useState<boolean>(
    contextDefaultValues.mockDataActivated
  );
  const [apiHealthy, setApiHealthy] = useState<boolean>(
    contextDefaultValues.apiHealthy
  );
  const [isHealthChecked, setIsHealthChecked] = useState<boolean>(false);
  const [isHealthChecking, setIsHealthChecking] = useState<boolean>(false);

  //Update Functions
  const changeThemeMode = (value: string) => {
    setThemeMode(value);
  };
  const toggleMockData = () => {
    if (mockDataActivated) {
      sessionStorage.removeItem("mockDataActivated");
    } else {
      sessionStorage.setItem("mockDataActivated", "true");
    }
    setMockDataActivated(!mockDataActivated);
  };
  const checkApiHealth = useCallback(() => {
    setIsHealthChecked(false);
    setIsHealthChecking(true);
    navigate("/check");
    health
      .get()
      .then((info) => {
        setApiHealthy(true);
        setIsHealthChecked(true);
        setIsHealthChecking(false);
        if (location.pathname.includes("/unauthorized")) {
          navigate("/");
        } else {
          navigate(location.pathname);
        }
      })
      .catch((err) => {
        setApiHealthy(false);
        setIsHealthChecked(true);
        setIsHealthChecking(false);
        navigate("/unauthorized");
      });
  }, [health, navigate, setIsHealthChecked, location.pathname]);

  //useEffect for Access Control
  useEffect(() => {
    if (
      env().ENV !== "local" &&
      !apiHealthy &&
      !isHealthChecked &&
      !isHealthChecking
    ) {
      checkApiHealth();
    }
  }, [checkApiHealth, apiHealthy, isHealthChecked, isHealthChecking]);

  return (
    <AppContext.Provider
      value={{
        themeMode,
        changeThemeMode,
        mockDataActivated,
        toggleMockData,
        apiHealthy,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
