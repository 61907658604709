import { apiClient } from "../utils/axios";
import { handleError } from "../utils/axios";
import { timeout } from "../utils/helpers";
//import { useOktaAuth } from "@okta/okta-react";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import { Source } from "types/database";

export function useApiSources() {
  const { mockDataActivated } = useContext(AppContext);
  //const { oktaAuth } = useOktaAuth();
  //const accessToken: string = oktaAuth.getAccessToken();
  const accessToken: string = null;

  let sources: any = require(`api/mockdata/sources.json`);

  const getSource = async (id: number): Promise<any> => {
    console.log(`API getSource ${id}`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      await timeout(Math.random() * 500);
      const sources: Source[] = require(`api/mockdata/sources.json`);
      const source: Source = sources.find((source: Source) => source.id === id);
      return source;
    }
    try {
      let route: string = `/source/${id}`;
      const response = await (await apiClient(accessToken)).get(route);
      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getSources = async (
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<any[]> => {
    console.log(`API getSources`, mockDataActivated ? "MOCK" : "API");
    try {
      let results: any[] = [];
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        results = require(`api/mockdata/sources.json`);
      } else {
        let route: string = `/source`;
        if (orderBy)
          route += `&ordering=${orderDir === "desc" ? "-" : ""}${orderBy}`;
        if (limit) route += `&limit=${limit}`;
        if (offset) route += `&offset=${offset}`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("results" in response.data) {
          results = response.data.results;
        } else {
          results = response.data;
        }
      }
      return results;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getSourcesCount = async (): Promise<number> => {
    console.log(`API getSourcesCount`, mockDataActivated ? "MOCK" : "API");
    try {
      let count: number = 0;
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        const results: any[] = require(`api/mockdata/sources.json`);
        count = results.length;
      } else {
        const route: string = `/source?limit=1`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("count" in response.data) {
          count = response.data.count;
        } else {
          count = response.data.length;
        }
      }
      return count;
    } catch (error: any) {
      handleError(error);
    }
  };

  const createSource = async (name: string): Promise<any> => {
    console.log(`API createSource`, mockDataActivated ? "MOCK" : "API", name);
    if (mockDataActivated) {
      const newSource: any = {
        id: Date.now(),
        name: name,
      };
      sources.unshift(newSource);
      return newSource;
    }
    try {
      let formData = new FormData();
      formData.append("name", name);
      const route: string = `/source`;
      const response = await (
        await apiClient(accessToken)
      ).post(route, formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const updateSource = async (id: number, name: string): Promise<any> => {
    console.log(`API updateSource`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const sourceIndex: number = sources.findIndex(
        (source: Source) => source.id === id
      );
      const updatedSource: any = {
        ...sources[sourceIndex],
        name: name,
      };
      sources[sourceIndex] = updatedSource;
      return updatedSource;
    }
    try {
      const route: string = `/source/${id}`;
      const input: any = {
        name: name,
      };
      let formData = new FormData();
      for (const property in input) {
        formData.append(property, input[property]);
      }
      const response = await (
        await apiClient(accessToken, true, true)
      ).patch(route, formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const deleteSource = async (id: number): Promise<boolean> => {
    console.log(`API deleteSource`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const index: number = sources.findIndex(
        (source: Source) => source.id === id
      );
      sources = sources.splice(index, 1);
      return true;
    }
    try {
      const route: string = `/source/${id}`;
      await (await apiClient(accessToken)).delete(route);
      return true;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return {
    getSource,
    getSources,
    getSourcesCount,
    createSource,
    updateSource,
    deleteSource,
  };
}
