import { apiClient } from "../utils/axios";
import { handleError } from "../utils/axios";
import { timeout } from "../utils/helpers";
//import { useOktaAuth } from "@okta/okta-react";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import { Tag } from "types/database";

export function useApiTags() {
  const { mockDataActivated } = useContext(AppContext);
  //const { oktaAuth } = useOktaAuth();
  //const accessToken: string = oktaAuth.getAccessToken();
  const accessToken: string = null;

  let tags: any = require(`api/mockdata/tags.json`);

  const getTag = async (id: number): Promise<any> => {
    console.log(`API getTag ${id}`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      await timeout(Math.random() * 500);
      const tags: Tag[] = require(`api/mockdata/tags.json`);
      const tag: Tag = tags.find((tag: Tag) => tag.id === id);
      return tag;
    }
    try {
      let route: string = `/tag/${id}`;
      const response = await (await apiClient(accessToken)).get(route);
      return response.data;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getTags = async (
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<any[]> => {
    console.log(`API getTags`, mockDataActivated ? "MOCK" : "API");
    try {
      let results: any[] = [];
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        results = require(`api/mockdata/tags.json`);
      } else {
        let route: string = `/tag`;
        if (orderBy)
          route += `&ordering=${orderDir === "desc" ? "-" : ""}${orderBy}`;
        if (limit) route += `&limit=${limit}`;
        if (offset) route += `&offset=${offset}`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("results" in response.data) {
          results = response.data.results;
        } else {
          results = response.data;
        }
      }
      return results;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getTagsCount = async (): Promise<number> => {
    console.log(`API getTagsCount`, mockDataActivated ? "MOCK" : "API");
    try {
      let count: number = 0;
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        const results: any[] = require(`api/mockdata/tags.json`);
        count = results.length;
      } else {
        const route: string = `/tag?limit=1`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("count" in response.data) {
          count = response.data.count;
        } else {
          count = response.data.length;
        }
      }
      return count;
    } catch (error: any) {
      handleError(error);
    }
  };

  const createTag = async (name: string): Promise<any> => {
    console.log(`API createTag`, mockDataActivated ? "MOCK" : "API", name);
    if (mockDataActivated) {
      const newTag: any = {
        id: Date.now(),
        name: name,
      };
      tags.unshift(newTag);
      return newTag;
    }
    try {
      const route: string = `/tag`;
      const input: any = {
        name: name,
      };
      const response = await (await apiClient(accessToken)).post(route, input);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const updateTag = async (id: number, name: string): Promise<any> => {
    console.log(`API updateTag`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const tagIndex: number = tags.findIndex((tag: Tag) => tag.id === id);
      const updatedTag: any = {
        ...tags[tagIndex],
        name: name,
      };
      tags[tagIndex] = updatedTag;
      return updatedTag;
    }
    try {
      const route: string = `/tag/${id}`;
      const input: any = {
        name: name,
      };
      const response = await (await apiClient(accessToken)).patch(route, input);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const deleteTag = async (id: number): Promise<boolean> => {
    console.log(`API deleteTag`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      const index: number = tags.findIndex((tag: Tag) => tag.id === id);
      tags = tags.splice(index, 1);
      return true;
    }
    try {
      const route: string = `/tag/${id}`;
      await (await apiClient(accessToken)).delete(route);
      return true;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return {
    getTag,
    getTags,
    getTagsCount,
    createTag,
    updateTag,
    deleteTag,
  };
}
