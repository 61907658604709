import axios, { AxiosInstance } from "axios";
import { env } from "utils/envVariables";

export enum ApiError {
  NotFound = "err-not-found",
  ServerError = "err-server-error",
  PermissionDenied = "err-permission-denied",
}

export const handleError = (error: any): void => {
  throw new Error(error);
};

export const apiClient = async (
  accessToken?: string,
  isProtected: boolean = true,
  isMultipart: boolean = false
): Promise<AxiosInstance> => {
  let headers: any = {};
  if (isMultipart) {
    headers["Content-type"] = "multipart/form-data";
  } else {
    headers["Content-type"] = "application/json";
  }
  if (isProtected) {
    headers["Authorization"] = "Bearer " + accessToken;
  }
  const axiosInstance: AxiosInstance = await axios.create({
    baseURL: env().API_URL,
    headers: headers,
  });

  return axiosInstance;
};

export const notifClient = async (): Promise<AxiosInstance> => {
  let headers: any = { "Content-type": "application/json" };
  const axiosInstance: AxiosInstance = await axios.create({
    baseURL: env().NOTIF_URI,
    headers: headers,
  });

  return axiosInstance;
};
