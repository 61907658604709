import { FunctionComponent } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Box, { BoxProps } from "@mui/material/Box";
import { SxProps } from "@mui/system";

export interface AppTooltipProps {
  content: JSX.Element | string | number;
  sx?: SxProps;
  children: JSX.Element | string | number;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    top: "5px",
    fontSize: "1.4rem !important",
    fontWeight: "400 !important",
    color: "#000",
    backgroundColor: "#fff",
    borderRadius: "4px !important",
    padding: "8px !important",
    letterSpacing: "0px !important",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff !important",
    "&:before": {
      border: "1px solid #E6E8ED",
      boxShadow: "2px 0px 2px rgba(0, 0, 0, 0.25)",
    },
  },
});

const CustomBox = styled(Box)<BoxProps>(({ theme, sx }) => ({
  cursor: "pointer",
  width: "fit-content !important",
  lineHeight: "90%",
}));

export const AppTooltip: FunctionComponent<AppTooltipProps> = (props) => {
  const { content, sx, children } = props;
  return (
    <CustomTooltip arrow placement="top" title={content}>
      <CustomBox sx={sx}>{children}</CustomBox>
    </CustomTooltip>
  );
};
