import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Box, BoxProps } from "@mui/material";

const StyledBoxRequired = styled(Box)<BoxProps>(() => ({
  display: "inline-block",
  color: "#F14D19",
}));

export const Required = () => {
  return <StyledBoxRequired component="span">*</StyledBoxRequired>;
};

export const RequiredKey = () => {
  const { t } = useTranslation();
  return (
    <Box marginTop={24}>
      <Required /> {t("components.forms.required.lbl-mandatory-field")}
    </Box>
  );
};
