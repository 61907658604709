import { apiClient } from "../utils/axios";
import { handleError } from "../utils/axios";
import { timeout } from "../utils/helpers";
//import { useOktaAuth } from "@okta/okta-react";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";

export function useApiChat() {
  const { mockDataActivated } = useContext(AppContext);
  //const { oktaAuth } = useOktaAuth();
  //const accessToken: string = oktaAuth.getAccessToken();
  const accessToken: string = null;

  let chat: any = require(`api/mockdata/chat.json`);
  let suggested: any = require(`api/mockdata/suggested.json`);

  const getConversation = async (
    conversationId: string,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<any[]> => {
    console.log(`API getConversation`, mockDataActivated ? "MOCK" : "API");
    try {
      let results: any[] = [];
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        results = chat;
      } else {
        let route: string = `/chat/${conversationId}`;
        if (orderBy)
          route += `&ordering=${orderDir === "desc" ? "-" : ""}${orderBy}`;
        if (limit) route += `&limit=${limit}`;
        if (offset) route += `&offset=${offset}`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("results" in response.data) {
          results = response.data.results;
        } else {
          results = response.data;
        }
      }
      return results;
    } catch (error: any) {
      handleError(error);
    }
  };

  const getSuggestedQueries = async (
    caseId: number,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<any[]> => {
    console.log(`API getSuggestedQueries`, mockDataActivated ? "MOCK" : "API");
    try {
      let results: any[] = [];
      if (mockDataActivated) {
        await timeout(Math.random() * 500);
        results = suggested;
      } else {
        let route: string = `/suggested/case/${caseId}`;
        if (orderBy)
          route += `&ordering=${orderDir === "desc" ? "-" : ""}${orderBy}`;
        if (limit) route += `&limit=${limit}`;
        if (offset) route += `&offset=${offset}`;
        const response = await (await apiClient(accessToken)).get(route);
        if ("results" in response.data) {
          results = response.data.results;
        } else {
          results = response.data;
        }
      }
      return results;
    } catch (error: any) {
      handleError(error);
    }
  };

  const sendMessage = async (
    caseId: number,
    conversationId: string,
    text: string
  ): Promise<any> => {
    console.log(
      `API sendMessage`,
      mockDataActivated ? "MOCK" : "API",
      conversationId,
      text
    );
    if (mockDataActivated) {
      await timeout(3000);
      const message: any = {
        id: Date.now(),
        createdAt: new Date().toISOString(),
        text: text,
        generated: false,
      };
      chat.push(message);
      const answer: any = {
        id: Date.now(),
        createdAt: new Date().toISOString(),
        text: "this is mock response from LLM",
        generated: true,
      };
      chat.push(answer);
      return "this is mock response from LLM";
    }
    try {
      const route: string = `/chat`;
      const input: any = {
        text: text,
        conversation_id: conversationId,
        case_id: caseId,
      };
      const response = await (await apiClient(accessToken)).post(route, input);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const deleteConversation = async (
    conversationId: string
  ): Promise<boolean> => {
    console.log(`API deleteSource`, mockDataActivated ? "MOCK" : "API");
    if (mockDataActivated) {
      chat = [];
      return true;
    }
    try {
      const route: string = `/chat/${conversationId}`;
      await (await apiClient(accessToken)).delete(route);
      return true;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return {
    getConversation,
    getSuggestedQueries,
    sendMessage,
    deleteConversation,
  };
}
