import { createContext, useState, FC } from "react";
import { PropsWithChildren as Props } from "react";

export interface Toaster {
  title: string;
  message: JSX.Element | string;
  variant?: "success" | "failure" | "info";
}

export type ToasterContextState = {
  toasterShown: boolean;
  toasterData: Toaster | null;
  showToaster: (data: Toaster) => void;
  hideToaster: () => void;
};

const contextDefaultValues: ToasterContextState = {
  toasterShown: false,
  toasterData: null,
  showToaster: () => {},
  hideToaster: () => {},
};

export const ToasterContext =
  createContext<ToasterContextState>(contextDefaultValues);

export const ToasterProvider: FC<Props> = ({ children }) => {
  const [toasterShown, setToasterShown] = useState<boolean>(
    contextDefaultValues.toasterShown
  );
  const [toasterData, setToasterData] = useState<Toaster | null>(
    contextDefaultValues.toasterData
  );
  const showToaster = (data: Toaster) => {
    hideToaster();
    setToasterData(data);
    setToasterShown(true);
  };
  const hideToaster = () => {
    setToasterShown(false);
  };

  return (
    <ToasterContext.Provider
      value={{
        toasterShown,
        toasterData,
        showToaster,
        hideToaster,
      }}
    >
      {children}
    </ToasterContext.Provider>
  );
};

export default ToasterProvider;
