import { useContext } from "react";
import { useApiCases } from "api/requests/cases";
import { ToasterContext, Toaster } from "contexts/ToasterContext";
import { Case } from "types/database";
import moment from "moment";

const formatCases = (cases: any[]): Case[] => {
  const formattedCases: Case[] = cases.map((c: any) => {
    return formatCase(c);
  });
  return formattedCases;
};

export const formatCase = (c: any): Case => {
  const formattedCase: Case = {
    ...c,
    createdAt: c.created_at ? moment(c.created_at) : null,
  };
  return formattedCase;
};

export function useLoaderCases() {
  //Hooks
  const apiCases = useApiCases();
  const { showToaster } = useContext(ToasterContext);

  const loadCase = async (id: number): Promise<Case> => {
    return apiCases
      .getCase(id)
      .then((c: any) => {
        return c ? formatCase(c) : c;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Case fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadCases = async (
    caseId: number,
    orderBy?: string,
    orderDir?: "asc" | "desc",
    limit?: number,
    offset?: number
  ): Promise<Case[]> => {
    return apiCases
      .getCases(caseId, orderBy, orderDir, limit, offset)
      .then((cases: any[]) => {
        const formattedCases: Case[] = formatCases(cases);
        return formattedCases;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Cases fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const loadCasesCount = async (): Promise<number> => {
    return apiCases
      .getCasesCount()
      .then((count: number) => {
        return count;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Cases Count fetching",
        };
        showToaster(toaster);
        return null;
      });
  };

  const createCase = async (name: string): Promise<Case> => {
    return apiCases
      .createCase(name)
      .then((created: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Case has been saved successfully",
        };
        showToaster(toaster);
        return formatCase(created);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Case saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const updateCase = async (id: number, name: string): Promise<Case> => {
    return apiCases
      .updateCase(id, name)
      .then((updated: any) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Case has been saved successfully",
        };
        showToaster(toaster);
        return formatCase(updated);
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Case saving",
        };
        showToaster(toaster);
        return null;
      });
  };

  const deleteCase = async (id: number): Promise<boolean> => {
    return apiCases
      .deleteCase(id)
      .then((result: boolean) => {
        const toaster: Toaster = {
          variant: "success",
          title: "Success",
          message: "The Case has been successfully deleted ",
        };
        showToaster(toaster);
        return result;
      })
      .catch((error: Error) => {
        const toaster: Toaster = {
          variant: "failure",
          title: "Error",
          message: "An error occured during Case deletion",
        };
        showToaster(toaster);
        return null;
      });
  };

  return {
    loadCase: loadCase,
    loadCases: loadCases,
    loadCasesCount: loadCasesCount,
    createCase,
    updateCase,
    deleteCase,
  };
}
